import { CircularProgress } from '@mui/material';
import { SendButton, HiddenButton } from '@styles/ChatSendButtonStyle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React, { useState, useMemo, forwardRef, useImperativeHandle, PropsWithChildren, useRef } from 'react';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import useDebounce from '@common/hooks/useDebounce';
import { SendMessage } from '@assets/Icons';
import Styles from '@styles/ChatSendButton.module.css';
import PrivateStyles from '@styles/ThemeShift.module.css';
import { isMobile } from '@common/deviceTypeHelper';

import { useErrandContext } from '@contexts/ErrandContext';
import { MORPH_TYPES_THAT_NEED_MODERATE_SEND_BUTTON_ADJUSTMENT, MORPH_TYPES_THAT_NEED_SLIGHT_SEND_BUTTON_ADJUSTMENT, MorphType } from '@common/MorphType';
import { useRootContext } from '@contexts/RootContext';
import { useUserContext } from '@contexts/user';
import { useMorphContext } from '@contexts/MorphContext';
import { welcomeMorphTypes } from '@common/welcomeMorphTypes';
import { useAppsMenuState } from './AppsMenu/hooks/useAppsMenuState';
import useWindowDimensions from '@common/hooks/useWindowDimensions';

// const SendGif = process.env.REACT_APP_MORGAN_CDN + '/Images/send_arrow.gif';
// const DisableMessageIcon = process.env.REACT_APP_MORGAN_CDN + '/Images/DisableMessage.png';
const SendMessageIcon = process.env.REACT_APP_MORGAN_CDN + '/Images/SendMessage.png';


const ChatSendButton: React.FC<PropsWithChildren<any>> = forwardRef((props, ref: any) => {
  const [showAnimation, setShowAnimation] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [imageError, setImageError] = useState(false);
  const { appsMenuState } = useAppsMenuState();
  const htmlRef = useRef(null);
  const errandContext = useErrandContext();
  const rootContext = useRootContext();
  const morphContext = useMorphContext();
  const { isUser } = useUserContext();
  const { innerWidth } = useWindowDimensions();
  // Adding functions to this component's ref
  useImperativeHandle(
    ref,
    () => {
      return {
        startAnimation() {
          if (!inputValue && !showAnimation) {
            setShowAnimation(true);
          }
        },
        update(newInputValue) {
          if (!inputValue && !showAnimation) {
            setShowAnimation(true);
          }
          setInputValue(newInputValue);
        },
        handleSubmit: props.handleSubmit,
        focus: () => {
          htmlRef?.current?.focus();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [inputValue, showAnimation, props.handleSubmit]
  );

  const revertShowAnimation = () => {
    if (showAnimation) {
      setShowAnimation(false);
    }
  };

  // setShowAnimation to false after the gif plays for 1150ms;
  useDebounce(revertShowAnimation, 1150, [showAnimation]);

  // These morphTypes will have the consent appear immediately without any user interaction
  const isWelcomeMorphType = useMemo(() => 
  welcomeMorphTypes.includes(errandContext.morphType), [errandContext.morphType]);

  const blueThemeMorphTypes = errandContext.morphType === MorphType.PrivateChat ||
    errandContext.morphType === MorphType.Edit

  return (
    <div className={[Styles.chatSendButtonContainer,
      ...(MORPH_TYPES_THAT_NEED_MODERATE_SEND_BUTTON_ADJUSTMENT.includes(errandContext.morphType) ? [Styles.moderateSendButtonAdjustment] : []),
      ...(MORPH_TYPES_THAT_NEED_SLIGHT_SEND_BUTTON_ADJUSTMENT.includes(errandContext.morphType) ? [Styles.slightSendButtonAdjustment] : []),
      errandContext.morphType === MorphType.PointsTable && Styles.pointsTableBackground,
      ...(props.fieldAttributeDescription === 'DROPDOWN' ||
      errandContext.morphType !== MorphType.UserPromptsMenu &&
      errandContext.morphType !== MorphType.VideoListMenu ? [Styles.maxHeight] : []),
      ...(errandContext.morphType === MorphType.Reply ? [Styles.replyChat] : []),
      ...(errandContext.morphType === MorphType.Edit ? [Styles.editChat] : []),
      ...(errandContext.morphType === MorphType.Wallet ? (isMobile() ? [Styles.walletChatMobile] : [Styles.walletChatDesktop]) : []),
      ...(blueThemeMorphTypes ? [Styles.privateChat] : []),
      ...(blueThemeMorphTypes ? [PrivateStyles.isPrivate] : []),
      ...(props.fieldAttributeDescription === 'DROPDOWN' && rootContext.allConsentsGiven ? [Styles.dropWidth] : []),
      ...(errandContext.morphType === MorphType.Attachment ? [Styles.filledOut, Styles.attachmentMorphRules] : []),
      ...(errandContext.morphType === MorphType.CalendarMonth || errandContext.morphType === MorphType.DOB ? ((innerWidth <= 450 && !morphContext.hideCalendarMonth) ? [Styles.calendarMobile] : [Styles.shiftDown]) : []),
      ...(errandContext.morphType === MorphType.Time ? ((isMobile() && !morphContext.hideTime) ? [Styles.timeMobile] : [Styles.shiftDown]) : []),
      ...(errandContext.morphType === MorphType.BorrowerSelector ||
        errandContext.morphType === MorphType.Payment ||
        errandContext.morphType === MorphType.Contacts ||
        errandContext.morphType === MorphType.Errand ||
        errandContext.morphType === MorphType.Recording || 
        errandContext.morphType === MorphType.FormOpen ||
        errandContext.morphType === MorphType.FormActiveChat ||
        errandContext.morphType === MorphType.FormInsertSignature ||
        errandContext.morphType === MorphType.FormNavigateInitials ||
        errandContext.morphType === MorphType.FormReadyToSend ||
        errandContext.morphType === MorphType.LoanProducts ||
        errandContext.morphType === MorphType.Attachment ||
        errandContext.morphType === MorphType.PhotoMain ||
        errandContext.morphType === MorphType.PhotoPlain ||
        errandContext.morphType === MorphType.NewPassword ||
        errandContext.morphType === MorphType.ShareCustomLink ||
        errandContext.morphType === MorphType.Media ||
        errandContext.morphType === MorphType.Time ? [Styles.shiftDown] : []),
      ...(!rootContext.allConsentsGiven && 
      (rootContext.clickedDisabledFeatures || 
      props.fieldAttributeDescription === 'DROPDOWN' ||
      props.fieldAttributeDescription === 'BOOLEAN' ||
      isWelcomeMorphType
      ) && !appsMenuState.isOpened && isUser && !appsMenuState.searchView ? [Styles.gray] : [Styles.transparent]),
      ...(errandContext.morphType === MorphType.SelectMultiple ? [Styles.selectMultiple] : [])
    ].join(' ')}>
      <SendButton
        className={[Styles.sendButton,
        ...(!rootContext.consentChecked && isUser ? [Styles.grayedOut] : []),
        ...(props.disabled ? [Styles.disabled] : []),
        ...((props.fieldAttributeDescription === 'DROPDOWN' ) ? [Styles.dropShift] : []),
        ...(errandContext.morphType === MorphType.CreditRepairWelcome ||
          errandContext.morphType === MorphType.RefinanceCalculatorWelcome ||
          errandContext.morphType === MorphType.SlotMachine ? [Styles.shiftWelcomeDown] : []),
        ...(blueThemeMorphTypes ? [PrivateStyles.isPrivate] : []),
        ...(errandContext.morphType === MorphType.Wallet ? [Styles.walletSendButton] : []),
        ...(errandContext.morphType === MorphType.DownloadAppBanner ? [Styles.shiftMoreDown] : [])
        ].join(' ')}
        ref={htmlRef}
        onClick={props.handleSubmit}
        onMouseDown={(e) => {e.preventDefault()}}
      >
        {/* forced the morgan icon to load quickly with the component */}
        {/* <link rel='preload' as='image' href={DisableMessageIcon} />
        {
          imageError ? <SendRoundedIcon className='sendRounded' /> : (
            <img
              src={props.disabled && !inputValue ? DisableMessageIcon : showAnimation ? SendGif : SendMessageIcon}
              alt="Send Button"
              style={{width: props.disabled && !inputValue ? '106%' : '100%', height: props.disabled && !inputValue ? '106%' : '100%'}}
              onError={() => setImageError(true)}
            />
          )
        }
        {props.workflow && props.userActions.length > 0 && (
          <CircularProgress
            size={50}
            variant='determinate'
            value={
              (props.userActions.filter((ua) => ua.status === 'resolved').length /
                props.userActions.length) *
              100
            }
            sx={{
              position: 'absolute',
            }}
          />
        )} */}
        {/* <img
          src={SendMessageIcon}
          alt="Send Button"
          style={{width: props.disabled ? '106%' : '100%', height: props.disabled ? '106%' : '100%'}}
          onError={() => setImageError(true)}
        /> */}
        <div className={[
         ...(errandContext.morphType === MorphType.Attachment ? [Styles.sendArrowContainerAttachmentMorphRules] : [Styles.sendArrowContainer]),
         ...errandContext.morphType === MorphType.Edit ? [Styles.centerIcons] : [],
         ...errandContext.morphType === MorphType.Wallet ? [Styles.walletArrowContainer] : [],
         ...errandContext.morphType === MorphType.Time && isMobile() && !morphContext.hideTime ? [Styles.timeArrowContainer] : [],
        ].join(' ')}>
          <SendMessage className={[
            ...(errandContext.morphType === MorphType.Attachment ? [Styles.arrowAttachmentMorphRules] : [Styles.arrow]),
            ...(blueThemeMorphTypes ? [PrivateStyles.isPrivate] : []),
            ...(blueThemeMorphTypes ? [Styles.privateArrow] : []),
            ...errandContext.morphType === MorphType.Wallet ? [Styles.walletArrow] : [],
            ...errandContext.morphType === MorphType.Time && isMobile() && !morphContext.hideTime ? [Styles.timeArrow] : [],
          ].join(' ')} />
        </div>
      </SendButton>

      {/* Hidden icon until user tabs over to it. For enhanced keyboard navigation */}
      {/* {!props.operatorData && (
        <HiddenButton onClick={() => props.startOfErrands?.current?.focus()}>
          <KeyboardArrowRightIcon />
        </HiddenButton>
      )} */}
    </div>
  );
});

export default ChatSendButton;
