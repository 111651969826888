import React, { useMemo } from 'react';
import { Box } from '@mui/material';

import { TwoUserProfile } from '@assets/Icons';
import Allign from '@styles/Allign';

import Bubble from './Bubble';
import IconBackground from './Icon/Background';
import IconForeground from './Icon/Foreground';
import Rope from './Rope';

import { useMessageSingleContext } from '@contexts/message';

const ReplyDialog = () => {
  const { isTranslated, messageAlignByCurrentUser, messageMessageStatus, messageId, messageReplyToOriginalText } =
    useMessageSingleContext();

  const { alignIcon, alignRope } = useMemo(() => {
    const alignIcon = messageAlignByCurrentUser
      ? { left: 0, transform: 'translate(-40%, -40%)' }
      : { right: 0, transform: 'translate(40%, -40%)' };

    const alignRope = messageAlignByCurrentUser ? { right: '15px' } : { left: '15px' };

    return { alignIcon, alignRope };
  }, [messageAlignByCurrentUser]);

  if (!messageReplyToOriginalText || messageMessageStatus === 'deleted') return null;

  return (
    <Allign
      data-message-id={messageId}
      sx={{
        position: 'relative',
        minWidth: isTranslated ? '220px' : '85px',
        justifyContent: messageAlignByCurrentUser ? 'flex-end' : 'flex-start',
        paddingBottom: '15px',
        marginTop: '16px',
      }}
    >
      <Box sx={{ position: 'relative', maxWidth: 'min(90%, calc(100vw - 50px))' }}>
        <IconBackground sx={alignIcon} />
        <Rope sx={alignRope} />
        <Bubble />
        <IconForeground sx={alignIcon}>
          <TwoUserProfile />
        </IconForeground>
      </Box>
    </Allign>
  );
};

export default ReplyDialog;
