import React from 'react';
import styles from '@styles/MessageBodyShimmeringSkeleton.module.css';
import { useMessageBodyObserverContext } from '@contexts/messageBodyObserver';

const MessageBodyShimmeringSkeleton = () => {
  const { skeletonLineCount } = useMessageBodyObserverContext();

  return (
    <div className={styles.container}>
      {Array.from({ length: skeletonLineCount }).map((_, index) => (
        <div key={index} className={styles.line} />
      ))}
    </div>
  );
};

export default MessageBodyShimmeringSkeleton;
