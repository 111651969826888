import eventBus from "@common/eventBus";
import { useErrandContext } from "@contexts/ErrandContext";
import React, { useEffect } from "react";
import ChatInputFieldStyles from '@styles/ChatInputField.module.css';

export const CharacterCount = (props) => {
    const { isDesktop } = props;
    const { errandId } = useErrandContext();
    const [value, setValue] = React.useState('');

    useEffect(() => {
        const handleTypeUpdate = (eventData) => {
            if (!eventData.detail) return;

            const { chat_id, value } = eventData.detail;
            if (chat_id !== errandId) return;

            setValue(value);
        }

        eventBus.on('footer_immidiate_user_value_updated', handleTypeUpdate);

        return () => {
            eventBus.remove('footer_immidiate_user_value_updated', handleTypeUpdate);
        }
    }, [])

    return (<div className={[
        ChatInputFieldStyles.charCountWrapper,
        ...(isDesktop ? [ChatInputFieldStyles.charDesktop] : [ChatInputFieldStyles.charMobile]),
        ...(value.length === 0 ? [ChatInputFieldStyles.noChar] : []),
        ...(value.length === 48 ? [ChatInputFieldStyles.reachedLimit] : [])
    ].join(' ')}>
        <div className={ChatInputFieldStyles.charCount}>
            {value.length}/48
        </div>
    </div>)
}