import { useTranslation } from 'react-i18next';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import IconButton from '@mui/material/IconButton';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { AttachFileIcon, InviteFriend, InviteFriendFilled } from '@assets/Icons';
import { useErrandContext } from '@contexts/ErrandContext';
import { Styles, FSMenu, FSMenuItem } from '@styles/FileSelectorStyles';
import { handleFile } from '@common/handleFile';
import { styled } from '@mui/system';
import ThinClientUtils from '@common/ThinClientUtils';
import { MorphType } from '@common/MorphType';
import MainPhotoSelector from './MainPhotoSelector';
import { useUserContext } from '@contexts/user';
import { useRootContext } from '@contexts/RootContext';

// New Attachment Icon Design (12/17/2024)
const ICONS = {
  AttachmentIcon: "AttachmentPlusDialogueIcon.png"
}
const getIconPath = (iconFileName: string) => `${process.env.REACT_APP_MORGAN_CDN}/Images/${iconFileName}`;

const CustomIcon = (props) => {
  return (
    <img src={props.path} alt={'Attachment Icon, click to open attachment menu'} />
  );
}

const CustomAttachmentIcon = <CustomIcon path={getIconPath(ICONS.AttachmentIcon)} />;

const FileSelector = (props) => {
  const errandContext = useErrandContext();
  const rootContext = useRootContext();
  const { isOperator } = useUserContext();

  const handleButtonClick = () => {
    if (!isOperator && (!rootContext.allConsentsGiven)){
      rootContext.handleShakingConsent();
      return;
    };
    //for the main photo selector we don't want the click to have any behavior
    if (errandContext.morphType === MorphType.PhotoMain || errandContext.morphType === MorphType.PhotoPlain) {
      return;
    }
    if (errandContext.morphType === MorphType.Attachment) {
      errandContext.setMorphType(MorphType.None)
    } else {
      errandContext.setIsMorphedFooterCloseButtonOnLeft(true);
      errandContext.setMorphType(MorphType.Attachment)
    }
  }

  return (
    <Styles onClick={handleButtonClick}>
      {errandContext.morphType === MorphType.PhotoMain ? (<MainPhotoSelector />) : (CustomAttachmentIcon)}
    </Styles>
  )
}

//I intend to delete everything below this line, except the export statement.
const FileSelectorOld: FC<PropsWithChildren<any>> = (props) => {
  const errandContext = useErrandContext();
  const rootContext = useRootContext();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isWarningActive, setIsWarningActive] = useState(false);
  const [isCameraAllowed, setIsCameraAllowed] = useState(false);

  const StyledTooltipButton = styled('div')({
    position: 'absolute',
    display: 'flex',
    height: '56px',
    width: '228px',
    right: '66px',
    bottom: '61px',
    backgroundColor: 'var(--shadow000)',
    zIndex: '9999',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    color: 'var(--gray000)',
  })

  const handleAnchorClick = (event) => {
    if (!rootContext.allConsentsGiven) return;

    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleAnchorClose = () => setAnchorEl(null);

  const handleOpenContactsAndCloseTooltip = () => {
    props.handleOpenContacts()
    errandContext.setShowAddFriendTooltip(false)
  }

  const handleCameraClick = (event) => {
    // check camera permissions
    // if granted, continue to camera
    // if denied, show permission reminder
    if (!isCameraAllowed) {
      event.preventDefault();
      // prompt user for camera permission
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: 'environment',
          },
        })
        .then(() => {
          setIsCameraAllowed(true);
          props.setShowPermissionReminder(false);
        })
        .catch((err) => {
          console.error(err);
          props.setIconToShow('cam');
          props.setShowPermissionReminder(true);
        });
    }
  };

  const handleFiles = (e) => {
    let files = handleFile(e, errandContext.errand?.action?.action?.fieldName);
    if (typeof files === 'string') {
      setIsWarningActive(t(files));
    } else {
      props.setSelectedFiles((prev) => {
        let uniqueFiles = [...prev, ...files].filter((value: File, index: number, array: File[]) => array.findIndex((v: File) => v === value) === index);
        return uniqueFiles;
      });
    }
  };

  const handleContactsClick = (e) => {
    if (localStorage.getItem('contactConsent')) {
      props.handleOpenContacts();
    } else {
      props.setShowContactsConsent(true)
    }
  }

  useEffect(() => {
    function preventDefault(e) {
      e.preventDefault();
    }
    // required to process files dropped on this conversation
    errandContext?.boundaryRef?.current?.addEventListener('drop', handleFiles);
    // both are required to prevent opening files in a new tab if the file is dropped elsewhere.
    window.addEventListener('drop', preventDefault);
    window.addEventListener('dragover', preventDefault);
    return () => {
      window.removeEventListener('drop', preventDefault);
      window.removeEventListener('dragover', preventDefault);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styles>
      <Snackbar
        open={isWarningActive ? true : false}
        onClose={() => setIsWarningActive(null)}
        message={isWarningActive}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
      {errandContext.showAddFriendTooltip && <StyledTooltipButton onClick={handleOpenContactsAndCloseTooltip} />}
      {!props.renderWithoutMenu ? (<IconButton
        className={`FSIconButton ${anchorEl || props.selectedFilesLength > 0 ? 'FSColor' : ''}`}
        aria-controls={anchorEl ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={anchorEl ? 'true' : undefined}
        onClick={(errandContext.morphType === MorphType.Contacts) ?
          props.handleCloseContacts
          : errandContext.showAddFriendTooltip ?
            handleOpenContactsAndCloseTooltip
            : handleAnchorClick}>

        {/* if the menu is open or if the contacts list is open, change the paperclip to the X icon */}
        {anchorEl || (errandContext.morphType === MorphType.Contacts) ?
          <HighlightOffOutlinedIcon />
          // if the user has selected files, change the paperclip to the number of selected files
          : props.selectedFiles?.length > 0 ?
            props.selectedFiles?.length
            // if the user has clicked on the top-left add friend button and the tooltip is open, change 
            // the paperclip to the add friend icon to open contacts list
            : errandContext.showAddFriendTooltip ?
              <InviteFriendFilled />
              // by default, just show the paperclip icon
              : <AttachFileIcon />
        }
      </IconButton>) : (<div style={{ flexDirection: 'row', display: 'flex', marginLeft: '4px', marginRight: '8px', gap: '8px' }}><FSMenuItem className='camera' dense={true} shouldHide={true}>
        <label onClick={handleAnchorClose}>
          <input
            type='file'
            accept='image/*'
            capture='environment'
            onClick={handleCameraClick}
            onChange={(e) => handleFiles(e)}
          />
          <CameraAltOutlinedIcon />
        </label>
      </FSMenuItem>
        <FSMenuItem dense={true}>
          <label onClick={handleAnchorClose}>
            <input type='file' accept='image/*' multiple onChange={(e) => handleFiles(e)} />
            <PhotoSizeSelectActualOutlinedIcon />
          </label>
        </FSMenuItem>
        <FSMenuItem dense={true}>
          <label onClick={handleAnchorClose}>
            <input
              type='file'
              accept='application/xml, .fnm, application/msword, application/pdf, application/vnd.ms-access, application/vnd.ms-excel, application/vnd.ms-excel.addin.macroEnabled.12, application/vnd.ms-excel.sheet.binary.macroEnabled.12, application/vnd.ms-excel.sheet.macroEnabled.12, application/vnd.ms-excel.template.macroEnabled.12, application/vnd.ms-powerpoint, application/vnd.ms-powerpoint.addin.macroEnabled.12, application/vnd.ms-powerpoint.presentation.macroEnabled.12, application/vnd.ms-powerpoint.slideshow.macroEnabled.12, application/vnd.ms-powerpoint.template.macroEnabled.12, application/vnd.ms-word.document.macroEnabled.12, application/vnd.ms-word.template.macroEnabled.12, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.template, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.spreadsheetml.template, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.wordprocessingml.template'
              multiple={true}
              onChange={(e) => handleFiles(e)}
            />
            <TextSnippetOutlinedIcon />
          </label>
        </FSMenuItem></div>)}
      {!props.renderWithoutMenu && <FSMenu
        anchorEl={anchorEl}
        open={anchorEl === null ? false : true}
        onClose={handleAnchorClose}
        keepMounted={true}
        elevation={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
        {
          ThinClientUtils.isThinClient() &&
          <FSMenuItem className='thinClient' dense={true}>
            <label onClick={handleAnchorClose}>
              <input onClick={handleContactsClick} />
              <InviteFriend />
            </label>
          </FSMenuItem>
        }
        <FSMenuItem className='mobile' dense={true}>
          <label onClick={handleAnchorClose}>
            <input
              type='file'
              accept='image/*'
              capture='environment'
              onClick={handleCameraClick}
              onChange={(e) => handleFiles(e)}
            />
            <CameraAltOutlinedIcon />
          </label>
        </FSMenuItem>
        <FSMenuItem dense={true}>
          <label onClick={handleAnchorClose}>
            <input type='file' accept='image/*' multiple onChange={(e) => handleFiles(e)} />
            <PhotoSizeSelectActualOutlinedIcon />
          </label>
        </FSMenuItem>
        <FSMenuItem dense={true}>
          <label onClick={handleAnchorClose}>
            <input
              type='file'
              accept='application/xml, .fnm, application/msword, application/pdf, application/vnd.ms-access, application/vnd.ms-excel, application/vnd.ms-excel.addin.macroEnabled.12, application/vnd.ms-excel.sheet.binary.macroEnabled.12, application/vnd.ms-excel.sheet.macroEnabled.12, application/vnd.ms-excel.template.macroEnabled.12, application/vnd.ms-powerpoint, application/vnd.ms-powerpoint.addin.macroEnabled.12, application/vnd.ms-powerpoint.presentation.macroEnabled.12, application/vnd.ms-powerpoint.slideshow.macroEnabled.12, application/vnd.ms-powerpoint.template.macroEnabled.12, application/vnd.ms-word.document.macroEnabled.12, application/vnd.ms-word.template.macroEnabled.12, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.template, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.spreadsheetml.template, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.wordprocessingml.template'
              multiple={true}
              onChange={(e) => handleFiles(e)}
            />
            <TextSnippetOutlinedIcon />
          </label>
        </FSMenuItem>
      </FSMenu>}
    </Styles>
  );
};

export default FileSelector;