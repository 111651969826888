import React, { memo } from 'react';

import MessageContentWrapper from './MessageContentWrapper';
import ErrorBoundary from '@components/ErrorBoundary';
import MessageContentSkeleton from './Skeletons/MessageContentSkeleton';
import { MessageBodyObserverProvider } from '@contexts/messageBodyObserver';

/*
 *  This component renders a message in the conversation. This includes not only the chat bubble, but the Author and
 * time sent as well
 *
 *  This component has the following properties:
 *    - author - The name of the person that composed the message
 *    - time - Time the message was sent/recieved
 *    - message - The actual content of the message
 *    - isOperator - A boolean value telling us if the author was an operator or not ,
 *     this will decide if the message is rendered on the left or right of the screen and
 *     in the appropriate
 */

const ConversationMessage = memo(() => {
  return (
    <ErrorBoundary debug={`./src/Components/ConversationMessage.tsx`}>
      <MessageContentWrapper>
        <MessageBodyObserverProvider>
          <MessageContentSkeleton />
        </MessageBodyObserverProvider>
      </MessageContentWrapper>
    </ErrorBoundary>
  );
});

export default ConversationMessage;
