/**
 * @file This file contains all text used on the UI (except
 * mnemonic-related text), for translation purposes. For
 * predefined translations see getPredefinedTranslation()
 * in MorphWalletUtils.ts.
 * @author Timur Bickbau
 */

/**
 * IMPORTANT: Use "digital trust" for fungibles and "digital art" for non-fungibles.
 * */

enum UiText {
  // general
  AngelWallet = 'Angel Wallet',
  Show = 'Show',
  Back = 'Back',
  Refresh = 'Refresh',
  TypeHere = 'Type here...',
  // native assets
  AngelCoin = 'Angel Coin',
  AngelPoints = 'Angel Points',
  AngelPoint = 'Angel Point',
  AngelMinutes = 'Angel Minutes',
  AngelMinute = 'Angel Minute',
  // NFT types
  nftTRUAPP = 'TRU Approval',
  nftTRUBID = 'Purchase Bid',
  nftTICKET = 'Event Ticket',
  // NFT user types
  nftuOwner = 'Owner',
  nftuApprovedUser = 'Approved User',
  // disclaimer
  Disclaimer1 = 'Your personal and wallet information is only visible to you.',
  Disclaimer2 = 'We respect your privacy. We have no ability to see what\'s in your wallet.',
  Disclaimer3 = 'Never send your security information or secret phrase in a chat message.',
  // Page.ViewRecoveryPhrase
  LogIn = 'Log In',
  YourPhrase = 'Your secret phrase',
  StorePhrase = 'Store your secret phrase in a secure physical location. Without it, you will lose access to your wallet and assets.',
  HelpBubblePhraseContinue = 'After storing your phrase, type "Continue".',
  SafetyAlert = 'Safety alert',
  PhraseScreenshot = 'Screenshots aren\'t a safe way to keep track of your secret phrase. Store it somewhere that isn\'t backed up online to keep your account safe.',
  Understand = 'I understand',
  // Page.RecoverAccount
  CreateWallet = 'Create Wallet',
  EnterPhrase = 'Enter your secret phrase',
  HelpBubbleTypePhrase = 'Enter your 12-word secret phrase above to access your account. Then, type "Continue" below.',
  // Page.AccessPin
  EnterOldPin = 'Enter your old PIN',
  EnterNewPin = 'Enter a new PIN',
  EnterYourPin = 'Enter your PIN',
  ClearPin = 'Clear PIN',
  PleaseWait = 'Please wait...',
  ForgotPin = 'Forgot PIN',
  NewAccessPin = 'You will enter this PIN to open your wallet and perform certain actions.',
  HelpBubbleBurnNft = 'IMPORTANT: If you burn your art, you will not be able to access it again. This cannot be undone.',
  HelpBubbleMakeBid = 'Once your bid is minted, you\'ll be able to find it in your wallet as a digital art.',
  // Page.WalletCreationCongratulations
  CongratulationsCreation1 = 'Congratulations, your new Angel Wallet is set up and ready to go!',
  CongratulationsCreation2 = 'Press Continue to unlock the world of your Angel digital assets.',
  CongratulationsContinue = 'Continue',
  // Page.HomeMenu
  Home = 'Home',
  HomeFungibles = 'Digital Trusts',
  HomeMarketplace = 'Marketplace',
  HomeNfts = 'Digital Art',
  HomeAngelPoints = 'Angel Points',
  HomeAngelCoin = 'Angel Coin',
  HomeAngelMinutes = 'Angel Minutes',
  FooterTryPrompt = 'Try this prompt.',
  FooterTryPrompts = 'Try some of these prompts.',
  HomeViewPublicAddress = 'See my public address',
  HomeViewRecoveryPhrase = 'See my secret phrase',
  HomeLinkDevice = 'Link a device',
  HomeUpdatePin = 'Update my PIN',
  ComingSoon = 'Coming soon',
  // Page.PublicAddress
  YourPublicAddress = 'Your public address',
  PublicAddressInfo = 'If somebody wants to send you a digital asset, you will need to give them this public address.',
  // Page.LinkDeviceMobile
  LinkDeviceInfo = 'Enter the code shown in your web browser to temporarily access your account on that device.',
  LinkDeviceSameAccount = 'Make sure you are signed into the same AngelAi account on both devices.',
  // Page.LinkDeviceMobileSuccess
  LinkSuccess = 'Your wallet has been linked.',
  ReturnToDevice = 'Please return to your other device.',
  // Page.LinkDeviceDesktop
  OpenMobileLinkDevice = 'Please open your wallet in the',
  OpenMobileCreateWallet = 'Please create a wallet in the',
  OpenMobile2 = 'AngelAi mobile app',
  OpenMobile3 = 'and type "Link a Device".',
  EnterCode = 'Then enter this code:',
  Waiting = 'Waiting...',
  // Page.AngelPoints
  TotalPoints = 'Total Angel Points:',
  RedeemPointsMobile = 'Tap the asset below to redeem points.',
  RedeemPointsDesktop = 'Click the asset below to redeem points.',
  History = 'History',
  BuyMinutes = 'Purchase',
  EnterMinutesAmount = 'Enter amount...',
  // Page.AngelPointsFungibleExchange
  // Page.Exchange
  EnterAmount = 'Enter the amount',
  // Page.ExchangeConfirmation
  ConfirmSpent = 'Spent:',
  ConfirmReceived = 'Received:',
  TermsAndConditions = 'By continuing, you accept the AngelAi terms and conditions.',
  Confirm = 'Confirm',
  // Page.ExchangeCongratulations
  CongratulationsReceived = 'Congratulations, you have received',
  // Page.UserFungibleListView
  TotalBalance = 'Total Balance',
  Asset = 'Asset',
  Assets = 'Assets',
  // Page.UserFungibleSingleView
  Balance = 'Balance',
  Available = 'Available:',
  FungibleReceive = 'Receive',
  FungibleSend = 'Send',
  YourTransactions = 'Your Transactions',
  ViewAll = 'View All',
  // Page.SendFungible
  YouAreAboutToSend = 'You are about to send',
  EnterReceivingAddress = 'Enter the receiving address',
  AmountToSend = 'Amount to send',
  Send = 'Send',
  // Page.SendFungibleConfirmation
  ConfirmTo = 'To:',
  ConfirmAmount = 'Amount:',
  ConfirmNetwork = 'Network:',
  ConfirmFee = 'Fee:',
  ConfirmTotal = 'Total:',
  TimerRanOut = 'You ran out of time. Please refresh and try again.',
  // Page.SendFungibleCongratulations
  CongratulationsSent = 'Congratulations, you have sent',
  // NFT list view
  FilterAllNfts = 'All Art',
  Refreshing = 'Refreshing...',
  HeaderNfts = 'My Digital Art',
  HeaderNoNfts = 'You have no digital art.',
  HeaderBids = 'Bids',
  HeaderNoBids = 'There are no bids on this art.',
  HeaderPublicTokens = 'Public Digital Art',
  HeaderNoPublicTokens = 'No public art to view.',
  HelpBubbleNoNfts = '', // include steps for the user to get a token; blank to not show
  // NFT single view
  Type = 'Type:',
  Event = 'Event:',
  UserType = 'User:',
  ApprovedAccounts = 'Approved Users',
  Date = 'Date:',
  Data = 'Data',
  ShowQrCode = 'Show QR Code',
  NoQrCode = 'No QR code for this event.',
  MakeNftPublic = 'Make Public',
  MakeNftPrivate = 'Make Private',
  BurnNft = 'Burn',
  HelpBubbleApprovedAccounts = 'To see the users approved to view this art, type "View Approved Users".',
  HelpBubbleViewBids = 'To see the bids that have been made on this art, type "View Bids".',
  HelpBubbleViewAcceptedBid = 'To see the bid that has been accepted on this art, type "View Accepted Bid".',
  HelpBubbleViewAttachedToken = 'To view the art attached to this bid, type "View Attached Art".',
  HelpBubbleAcceptBid = 'To accept this bid, type "Accept Bid".',
  HelpBubblePurchaseOffer = 'Enter your purchase offer below.',
  // Page.ApprovedAccounts
  NoApprovedAccounts = 'No approved users.',
  AddressEndingIn = 'Address ending in',
  HelpBubbleApproveAccount = 'To approve a user on this art, enter their public address below.',
  // input prompts, reduced to simplest form; all prompts should start with '_p', and only prompts should start with '_p'
  p_Continue = 'continue',
  p_Back = 'back',
  p_Access = 'access',
  p_Login = 'login',
  p_Create = 'create',
  p_DontHave = 'don\'t have',
  p_CantFind = 'can\'t find',
  p_Public = 'public',
  p_Address = 'address',
  p_Secret = 'secret',
  p_Recover = 'recover',
  p_Phrase = 'phrase',
  p_Words = 'words',
  p_Mnemonic = 'mnemonic',
  p_Pin = 'pin',
  p_Change = 'change',
  p_Update = 'update',
  p_Link = 'link',
  p_Connect = 'connect',
  p_Device = 'device',
  p_Desktop = 'desktop',
  p_Confirm = 'confirm',
  p_Receive = 'receive',
  p_Send = 'send',
  p_Refresh = 'refresh',
  p_Approved = 'approved',
  p_Users = 'users',
  p_Accepted = 'accepted',
  p_Bid = 'bid',
  p_Attached = 'attached',
  p_Accept = 'accept',
  p_Home = 'home',
  p_Trust = 'trust',
  p_Market = 'market',
  p_Art = 'art',
  p_Point = 'point',
  p_Angel = 'angel',
  p_Coin = 'coin',
  p_ANGL = 'angl',
  p_Minute = 'minute',
  // errors
  errUnrecognizedPrompt = 'Sorry, I didn\'t get that. Could you rephrase?',
  errCreateWallet = 'There was an issue creating the wallet. Please try again later.',
  errIllegalWord = 'Invalid word.',
  errMissingWord = 'Missing word.',
  errIncorrectPhrase = 'Incorrect access phrase.',
  errPastePhrase = 'There was an issue pasting your mnemonic phrase.',
  errInvalidPastePhrase = 'The phrase you are trying to paste is invalid.',
  errLostPhrase = 'You can find your secret phrase in your Angel Wallet. If you don\'t have access to your wallet and you have lost your secret phrase, then we cannot recover your account.',
  errTypePhrase = 'Your secret phrase is for your eyes only! Don\'t type it here!',
  errSetAccessPin = 'There was an issue setting the PIN. Please try again later.',
  errSubmitAccessPin = 'There was an issue submitting your PIN. Please try again later.',
  errIllegalAccessPin = 'The submitted code is not valid.',
  errIncorrectAccessPin = 'Incorrect PIN.',
  errLockedWallet = 'To protect your assets, your wallet has been locked for 30 minutes due to repeated incorrect attempts to enter your access PIN.',
  errLinkDevice = 'There was an issue linking your wallet. Please try again later.',
  errLinkDeviceCode = 'The code you entered was incorrect.',
  errGoToAngelPoints = 'There was an error accessing your points. Please try again later.',
  errGetFungibles = 'There was an issue getting your digital trusts. Please try again later.',
  errGetNfts = 'There was an issue getting your digital art. Please try again later.',
  errAccessFungible = 'There was an issue accessing the trust. Please try again later.',
  errAccessNft = 'There was an issue accessing the art. Please try again later.',
  errSendFungible = 'There was an issue sending the trust. Please try again later.',
  errMissingAddress = 'Please enter an address.',
  errOwnAddress = 'You cannot send trusts to your own address.',
  errInvalidAmount = 'Please enter a valid amount.',
  errAmountExceedsBalance = 'The amount provided exceeds your balance.',
  errPartialPoints = 'You cannot spend partial points.',
  errInputTooLarge = 'Please enter a smaller amount.',
  errCannotInputZero = 'Please enter more than zero.',
  errExchange = 'There is an issue with the exchange. Please try again later.',
  errDecryptQrCode = 'There was an error decrypting the QR code. Please try again later.',
  errGetApprovedAccounts = 'There was an issue getting the approved users. Please try again later.',
  errApproveAccount = 'There was an issue adding the user. Please try again later.',
  errRemoveApprovedAccount = 'There was an issue removing the user. Please try again later.',
  errBurnNft = 'There was an issue burning the art. Please try again later.',
  errAccessBids = 'There was an issue getting the bids. Please try again later.',
  errAcceptBid = 'There was an issue accepting the bid. Please try again later.',
  errAccessMarketplace = 'There was an issue accessing the marketplace. Please try again later.',
  errMintBid = 'There was an issue making the bid. Please try again later.',
  errTransferEventToken = 'There was an issue transferring your ticket. Please try again later.',
  errNoTicketsRemaining = 'I\'m sorry, there are no tickets remaining for this event.',
  errRequest = 'There was an issue with your request. Please try again later.',
  errMakeNftPublic = 'There was an issue making the art public. Please try again later.',
  errMakeNftPrivate = 'There was an issue making the art private. Please try again later.',
  errNoPurchasePrice = 'No purchase price provided.',
  errInvalidPurchasePrice = 'Invalid purchase price.',
  errUserAlreadyOwnsToken = 'You already own a token for this event.',
  errRetrievingAngelMinutes = 'There was an issue retrieving your Angel Minutes balance. Please try again later.',
  // general metadata
  mdSmartContractAddress = 'Smart Contract Address',
  // TICKET metadata
  mdEventName = 'Event',
  mdScanned = 'Attended',
  mdAdditionalGuests = 'Additional Guests',
  mdHashString = 'Confirmation Code',
  // TRUBID metadata
  mdBidStatus = 'Bid Status',
  mdTruTokenName = 'TRU Approval Token',
  // TRUAPP metadata
  mdTruApprovalDate = 'TRU Approval Date',
  mdBorrowerName = 'Borrower Name',
  mdPropCode = 'Property Code',
  mdPropDesc = 'Property Description',
  mdLoanAmount = 'Loan Amount',
  mdPurchasePrice = 'Purchase Price',
  mdInterestRate = 'Interest Rate',
  mdApr = 'APR',
  mdLtv = 'LTV',
  mdCltv = 'CLTV',
  mdProductCodeDesc = 'Product Code Description',
  mdRetail = 'Is Retail',
  mdTerm = 'Term',
  mdNewHazIns = 'New Hazard Insurance',
  mdNewTaxes = 'New Taxes',
  mdNewMi = 'New MI',
  mdNewHoa = 'New HOA Fees',
  mdNewFirstMortPi = 'New P&I on First Mortgage',
  mdNewOtherMortPi = 'New P&I on Other Mortgages',
  mdInterviewerName = 'Interviewer Name',
  mdInterviewerTitle = 'Interviewer Title',
  mdInterviewerEmail = 'Interviewer Email',
  mdInterviewerPhone = 'Interviewer Phone',
  mdInterviewerNmlsId = 'Interviewer NMLS ID',
  mdInterviewerCompCode = 'Interviewer Company Code',
  mdInterviewerCompAddress = 'Interviewer Company Address',
  mdInterviewerCompCityStateZip = 'Interviewer Company City/State/Zip',
  mdLenderCompCode = 'Lender Company Code',
  mdMolCompUrl = 'MOL Company URL'
}

export { UiText };