import { useErrandContext } from '@contexts/ErrandContext';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import tStyles from '@styles/Typography.module.css';
import { CreatedAtToWholeDay, CurrentTimeToWholeDay } from '@common/userMessagesUtils';
import { useMessageSingleContext } from '@contexts/message';

const MessageDate = () => {
  const { i18n, t } = useTranslation();
  const { searchRefs } = useErrandContext();

  const { messageCreatedAt, messageId, messageSearchWords } = useMessageSingleContext();

  const messageDateRef = useRef(null);

  const machineDate = useMemo(() => {
    if (!messageCreatedAt) return '';
    try {
      return new Date(messageCreatedAt).toISOString();
    } catch {
      return '';
    }
  }, [messageCreatedAt]);

  const messageDate = useMemo(() => {
    if (!messageCreatedAt) return '';

    try {
      const currentDay = CurrentTimeToWholeDay();
      const stampDay = CreatedAtToWholeDay(messageCreatedAt);

      if (currentDay === stampDay) {
        return t('today');
      }

      if (currentDay === stampDay + 1) {
        return t('yesterday');
      }

      return new Date(messageCreatedAt).toLocaleDateString(i18n.language, {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    } catch {
      return '';
    }
  }, [messageCreatedAt, i18n.language, t]);

  // add ref to timeline as needed
  useEffect(() => {
    if (messageSearchWords && messageDateRef.current !== null) {
      const array = searchRefs.current || [];
      array.push({ _id: messageId, htmlElement: messageDateRef.current as HTMLDivElement });
      array.filter((v, i, a) => a.findIndex((r) => r._id === v._id) === i);
      searchRefs.current = array;
    }
  }, [messageSearchWords, messageId, searchRefs]);

  return (
    <time
      ref={messageDateRef}
      dateTime={machineDate}
      className={[
        tStyles.base,
        tStyles.taCenter,
        tStyles.mbSmall,
        tStyles.mtLarge,
        tStyles.mAuto,
        tStyles.fsSmall,
        tStyles.lhNarrow,
      ].join(' ')}
    >
      {messageDate}
    </time>
  );
};

export default MessageDate;
