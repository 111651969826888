import React from 'react';
import Styles from '@styles/MessageContent/BlockchainMessageMessageContent.module.css';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import MessageTextStyle from '@styles/MessageTextStyle';
import { Color as WalletColor } from '@components/MorphWallet/MorphWalletType';

const BlockchainMessageMessageContent = ({ message }) => {
  return (
    <ChatBubbleStyle className={Styles.chatBubble}
                     style={{ borderColor: WalletColor.DarkBlue, backgroundColor: WalletColor.DarkBlue }}>
      <div className={Styles.lockContainer}
           style={{ backgroundColor: WalletColor.DarkBlue }}>
        <img src={`${process.env.REACT_APP_MORGAN_CDN}/Images/Blockchain/WalletLockWhite.png`} className={Styles.lockIcon} alt='Lock Icon' />
      </div>
      <MessageTextStyle className={Styles.messageContainer}
                        style={{ backgroundColor: WalletColor.Orange }}>
        <p>
          {message?.message}
        </p>
      </MessageTextStyle>
    </ChatBubbleStyle>
  );
};

export default BlockchainMessageMessageContent;