import React, { ReactNode, memo, useMemo, useCallback } from 'react';

import MessageDate from './MessageDate';
import MessageSender from './MessageSender';
import { useErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';
import SentimentEmoji from '@components/SentimentEmoji';
import { Sentiments } from '@mTypes/TSentiment';
import { MorphType } from '@common/MorphType';
import Styles from '@styles/MessageContentWrapperStyles.module.css';
import { useTranslation } from 'react-i18next';
import MessageReply from '@components/MessageReply/MessageReply';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { Tooltip } from '@mui/material';
import { ResetFooterUserAction } from '@common/common';
import { useUserContext } from '@contexts/user';
import { useMessageSingleContext } from '@contexts/message';

// hide message sender at this level for these message types.
const UNWRAPPED_MESSAGE_TYPES = ['Disclaimer'];
const CHILDREN_ONLY_MESSAGE_TYPES = ['Notification', 'Video'];
const UNKNOWN_SENDER_MESSAGE_TYPES = ['Errand', 'Referrer', 'Notification', 'Invite', 'HelpTip', 'HelpTipGroup'];
const INCOMPATIBLE_MESSAGE_TYPES = [
  'Action', 
  'Errand', 
  'Invite', 
  'Notification', 
  'SignatureConfirmation', 
  'Video', 
  'PermissionRejected', 
  'ImagingUploadStatus', 
  'CustomLink', 
  'Appointment', 
  'CreditRepairWelcome', 
  'UserPromptsMenu', 
  'VideoListMenu', 
  'CalculatorResult', 
  'RatePricingResult', 
  'RefinanceCalculatorWelcome', 
  'CalculatorsWelcome', 
  'BlockchainMessage', 
  'BlockchainWelcome', 
  'Order', 
  'LanguageSelector'
];

type TMessageContentWrapper = {
  children: ReactNode;
};

const MessageContentWrapper = memo(({ children }: TMessageContentWrapper) => {
  const { t } = useTranslation();

  const {
    errand,
    errandId,
    messageOptionsRef,
    morphType,
    isMorphedFooterCloseButtonOnLeft,
    messageOptionsIndex,
    setMessageOptionsIndex,
    setIsMorphedFooterCloseButtonOnLeft,
    setMorphType,
  } = useErrandContext();

  const { isUser, isOperator } = useUserContext();

  const { allConsentsGiven, handleShakingConsent, setErrands } = useRootContext();

  const {
    showSentiment,
    showDate,
    index,
    messageActionDescription,
    messageMessageType,
    messageVisible,
    messageMessageStatus,
    messageAlignByCurrentUser,
    messageChat,
    messageId,
    messageSenderId,
    messageReplyTo,
    messageActionFieldAttributeDescription,
    messageSentiment,
  } = useMessageSingleContext();

  const toggleMessageOptions = useCallback(() => {
    if (morphType === MorphType.PrivateChat) return;
    if (!messageVisible && isUser) return;
    if (isUser && !allConsentsGiven) {
      handleShakingConsent();
      return;
    }
    if (messageMessageStatus === 'deleted') return;
    if (INCOMPATIBLE_MESSAGE_TYPES.indexOf(messageMessageType) !== -1) return;
    setMessageOptionsIndex((prev: number) => {
      if (prev === index) {
        setIsMorphedFooterCloseButtonOnLeft(false);
        setMorphType(MorphType.None);
        return -1;
      }
      setIsMorphedFooterCloseButtonOnLeft(!messageAlignByCurrentUser);
      setMorphType(MorphType.MessageOptions);

      setErrands((elm) => {
        const chatObj = elm.find((e) => e._id === errandId);

        if (chatObj) {
          ResetFooterUserAction(chatObj);

          // force re-render
          return [...elm];
        }

        // no re-render
        return elm;
      });

      return index;
    });
  }, [
    errandId,
    handleShakingConsent,
    index,
    isUser,
    messageAlignByCurrentUser,
    messageMessageStatus,
    messageMessageType,
    messageVisible,
    morphType,
    allConsentsGiven,
    setErrands,
    setIsMorphedFooterCloseButtonOnLeft,
    setMessageOptionsIndex,
    setMorphType,
  ]);

  const renderNode = useMemo(() => {
    if (morphType !== MorphType.MessageOptions) return false;
    if (messageOptionsIndex !== index) return false;
    return true;
  }, [morphType, messageOptionsIndex, index]);

  const renderBorder = useMemo(() => {
    if (morphType !== MorphType.MessageOptions) return false;
    if (messageOptionsIndex! > index) return false;
    return true;
  }, [morphType, messageOptionsIndex, index]);

  const isLastMessage = useMemo(() => {
    return errand.messages.length === index + 1;
  }, [errand.messages?.length, index]);

  const isUnwrapped = useMemo(() => {
    return UNWRAPPED_MESSAGE_TYPES.indexOf(messageMessageType) !== -1;
  }, [messageMessageType]);

  const isChildrenOnly = useMemo(() => {
    return CHILDREN_ONLY_MESSAGE_TYPES.indexOf(messageMessageType) !== -1;
  }, [messageMessageType]);

  const isSenderShown = useMemo(() => {
    return (
      UNKNOWN_SENDER_MESSAGE_TYPES.indexOf(messageMessageType) === -1 && messageActionDescription !== 'Slot Machine'
    );
  }, [messageMessageType, messageActionDescription]);

  const showMessageOptionsToggle = useMemo(() => {
    if (INCOMPATIBLE_MESSAGE_TYPES.indexOf(messageMessageType) === -1) {
      if (messageMessageType === 'Field' && messageActionFieldAttributeDescription === 'SWIPEABLE BOOLEAN') {
        // Don't show options for outgoing swipeable boolean message
        return false;
      }
      // Otherwise, if the message type is not in INCOMPATIBLE_MESSAGE_TYPES,
      // we can show the options
      return true;
    }
    // If the message type is in INCOMPATIBLE_MESSAGE_TYPES, don't show options
    return false;
  }, [messageMessageType, messageActionFieldAttributeDescription]);

  const RenderSentiment = useMemo(
    () => (
      <Tooltip title={t(`${messageSentiment || 'Neutral'}`)} placement="top">
        <div className={Styles.sentiment}>
          <SentimentEmoji sentiment={Sentiments[messageSentiment]} regularEmoji={true} />
        </div>
      </Tooltip>
    ),
    [messageSentiment, t]
  );

  const RenderMessageOptionsToggle = useMemo(
    () => (
      <Tooltip title={t('openMessageOptions')} placement="top">
        <button
          data-chat-id={messageChat}
          data-message-id={messageId}
          data-sender-id={messageSenderId}
          onClick={toggleMessageOptions}
          className={[
            Styles.button,
            ...(isUser && (!allConsentsGiven) ? [Styles.disable] : []),
          ].join(' ')}
        >
          <MoreHoriz />
        </button>
      </Tooltip>
    ),
    [isUser, messageChat, messageId, messageSenderId, allConsentsGiven, t, toggleMessageOptions]
  );

  const RenderLeftOfMessage = useMemo(
    () => (
      <>
        {showMessageOptionsToggle && RenderMessageOptionsToggle}
        {showSentiment && RenderSentiment}
      </>
    ),
    [RenderMessageOptionsToggle, RenderSentiment, showMessageOptionsToggle, showSentiment]
  );

  const RenderRightOfMessage = useMemo(
    () => (
      <>
        {showSentiment && isOperator && RenderSentiment}
        {showMessageOptionsToggle && RenderMessageOptionsToggle}
      </>
    ),
    [RenderMessageOptionsToggle, RenderSentiment, isOperator, showMessageOptionsToggle, showSentiment]
  );

  return isUnwrapped ? (
    <>{children}</>
  ) : (
    <article
      ref={renderNode ? messageOptionsRef : null}
      className={[
        Styles.messageContentWrapper,
        ...(renderBorder ? [Styles.renderBorder] : []),
        ...(renderNode ? [Styles.renderNode] : []),
        ...(showDate ? [Styles.showDate] : []),
        ...(isLastMessage ? [Styles.isLastMessage] : []),
        ...(messageAlignByCurrentUser ? [Styles.alignByCurrentUser] : []),
        ...(isMorphedFooterCloseButtonOnLeft ? [Styles.isMorphedFooterCloseButtonOnLeft] : []),
        ...(!messageVisible ? [Styles.blur] : []),
        ...(!messageSenderId ? [Styles.hidden] : []),
      ].join(' ')}
    >
      <div className={Styles.node} aria-hidden={true} />
      {showDate && <MessageDate />}
      {isChildrenOnly ? (
        <>{children}</>
      ) : (
        <>
          {isSenderShown ? <MessageSender /> : <></>}
          {messageReplyTo ? <MessageReply /> : <></>}
          <div className={Styles.messageWrapper}>
            {messageAlignByCurrentUser && RenderLeftOfMessage}
            {children}
            {!messageAlignByCurrentUser && RenderRightOfMessage}
          </div>
        </>
      )}
    </article>
  );
});

export default MessageContentWrapper;
