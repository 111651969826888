import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';

import Sanitized from '@components/Sanitized';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import MessageTextStyle from '@styles/MessageTextStyle';

import type { MorganTheme } from '@mui/material';
import { AccessType } from '@common/AccessType';
import { useMessageSingleContext } from '@contexts/message';

const Bubble = () => {
  const { palette } = useTheme<MorganTheme>();
  const { messageAlignByCurrentUser, messageAccessType, messageSearchWords, messageReplyToOriginalText } =
    useMessageSingleContext();

  const rightIndentAlign = useMemo(
    () => (!messageAlignByCurrentUser ? { direction: 'rtl', textAlign: 'left' } : {}),
    [messageAlignByCurrentUser]
  );

  return (
    <ChatBubbleStyle
      sx={{
        width: 'calc(fit-content + 15px)',
        justifyContent: messageAlignByCurrentUser ? 'flex-end' : 'flex-start',
        borderColor: messageAccessType === AccessType.internal ? 'var(--gray400)' : 'var(--orange700)',
        background: messageAccessType === AccessType.internal ? 'var(--peach020)' : 'var(--peach600)',
        p: '6px 7px 8px 9px',
        textIndent: '15px',
        ...rightIndentAlign,
      }}
    >
      <MessageTextStyle
        sx={{
          wordWrap: 'break-word',
          fontStyle: 'italic',
          color: palette.gray['600'],
        }}
      >
        <Sanitized highlight={messageSearchWords ? messageSearchWords : undefined} html={messageReplyToOriginalText} />
      </MessageTextStyle>
    </ChatBubbleStyle>
  );
};

export default Bubble;
