// import { DoubleTick, Tick } from '@assets/Icons';
import { Fade, IconButton, Stack } from '@mui/material';
import React, { useEffect, useMemo, useCallback, useRef, useState } from 'react';

import { useSocketContext } from '@contexts/socket';
import axiosCall from '@services/axios';

import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import Sanitized from '@components/Sanitized';
import MessageTextStyle from '@styles/MessageTextStyle';
import OriginalLangStyle from '@styles/OriginalLangStyle';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TranslationInfoStyle from '@styles/TranslationInfoStyle';
import TranslationTabLeft from '@styles/TranslationTabLeft';
import TranslationTabRight from '@styles/TranslationTabRight';
import eventBus from '@common/eventBus.js';
import { getLangName } from '@common/common';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import { useRootContext } from '@contexts/RootContext';
import MessageTime from '@components/MessageTime';
import { AccessType } from '@common/AccessType';
import { useUserContext } from '@contexts/user';
import { useMessageSingleContext } from '@contexts/message';
import { useMessageBodyObserverContext } from '@contexts/messageBodyObserver';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import MessageBodyShimmeringSkeleton from '@components/MessageContent/MessageBodyShimmeringSkeleton';
import TwinAvatar from '@components/TwinAvatar';
import { IAppsMenuState } from '@contexts/FooterContext';
import { AppsMenuExternalState } from '@components/AppsMenu/AppsMenu';

const setAppsSearchWasCancelled = (isOperator, bool) => {
  if(!isOperator)
    AppsMenuExternalState.dispatchEvent(AppsMenuExternalState.EVENTS.SET_WAS_SEARCH_CANCELLED, { value: bool })
}

const getTextDialogHtmlVal = (visible, dispMessage) => {
  return visible === false
    ? dispMessage === undefined
      ? ''
      : dispMessage.replace(/./g, '*')
    : visible === true
    ? dispMessage === undefined
      ? ''
      : dispMessage
    : '';
};

// Message side icon button wrapper we have multiple Icon Button definition on react component
export const MessageSideIconButtonComponent = () => {
  const { messageIcon } = useMessageSingleContext();

  const { isOriginalStateRestored } = useMessageBodyObserverContext();

  const IMAGE_HEIGHT_WIDTH_VALUE = 24;

  const IconButtonStyle = {
    p: 0,
    overflow: 'hidden',
    borderRadius: '9px 0 0 9px',
    // borderRadius: message.alignByCurrentUser ? '9px 0 0 9px' : '0 9px 9px 0'
  };

  const ContainerStackStyle = {
    backgroundColor: 'var(--orange700)',
    backgroundImage: 'none',
    cursor: 'pointer',
    height: '100%',
    border: 'none',
    padding: '10px',
    flexGrow: 1,
    // backgroundImage: theme.system.name === 'SUNWST000' ? `url(${WoodGrain})` : 'none',
  };

  const MessageIcon = () => {
    const imgStyle = {
      height: `${IMAGE_HEIGHT_WIDTH_VALUE}px`,
      width: `${IMAGE_HEIGHT_WIDTH_VALUE}px`,
      filter: 'brightness(0) saturate(100%) invert(100%)',
    };

    return <img style={imgStyle} src={messageIcon} alt={`Action Icon`} />;
  };

  const NoIcon = () => {
    const questionMarkIconStyle = {
      color: 'var(--gray000)',
      height: `${IMAGE_HEIGHT_WIDTH_VALUE}px`,
      width: `${IMAGE_HEIGHT_WIDTH_VALUE}px`,
      filter: 'brightness(0) saturate(100%) invert(100%)',
    };

    return <QuestionMarkIcon fontSize="small" sx={questionMarkIconStyle} />;
  };

  const renderIcon = () => {
    if (typeof messageIcon === 'string' && messageIcon) {
      return <MessageIcon />;
    } else {
      return <NoIcon />;
    }
  };

  return (
    isOriginalStateRestored && (
      <IconButton sx={IconButtonStyle}>
        <Stack alignItems="stretch" justifyContent="center" flexDirection="column" sx={ContainerStackStyle}>
          {renderIcon()}
        </Stack>
      </IconButton>
    )
  );
};

const Styles = {
  getMsgBubbleStyleSx: (isTranslated, accessType, sentByCurrentUser, isClickable) => {
    return {
      minWidth: isTranslated ? '220px' : '85px',
      borderColor: accessType === AccessType.internal ? 'var(--gray400)' : 'var(--orange700)',
      background:
        accessType === AccessType.internal
          ? 'var(--peach020)'
          : sentByCurrentUser
          ? 'var(--gray000)'
          : 'var(--peach600)',
      pointerEvents: 'all',
      borderBottomRightRadius: isClickable ? '80px' : '10px',
    };
  },
  CmnChildComponentThirdContainerStack: {
    d: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    p: '6px 7px 8px 9px',
    maxWidth: '100%',
    position: 'relative',
    width: '100%',
  },
  msgTextSx: {
    wordWrap: 'break-word',
    width: 'fit-content',
    color: 'var(--red400)',
    textDecorationColor: 'var(--red400)',
    textDecorationLine: 'line-through',
  },
  arrowCircleRightRoundedIconSx: {
    color: 'var(--orange700)',
    marginRight: '5px',
  },
  defIconSx: {
    display: 'flex',
    position: 'absolute',
    background: 'var(--orange700)',
    right: '25px',
    borderRadius: '15px',
    bottom: '-15px',
    height: '30px',
    width: '30px',
  },
  defIconImgStyle: {
    height: '20px',
    width: '20px',
    filter: 'brightness(0) saturate(100%) invert(100%) drop-shadow(4px 3px 3px purple)',
  },
  questionMarkIconSx: {
    color: 'var(--gray000)',
    height: '20px',
    width: '20px',
    filter: 'brightness(0) saturate(100%) invert(100%) drop-shadow(4px 3px 3px purple)',
  },
  msgTimeContainerStyle: {
    margin: '0 0 0 auto',
    flexWrap: 'nowrap',
    backgroundColor: 'transparent',
  },
};

const ActionMessageContent = () => {
  const { isOperator, isUser, _id } = useUserContext();

  const { messagesSocket, isMessagesConnected } = useSocketContext();

  const { allConsentsGiven, setErrands, setClickedDisabledFeatures, handleShakingConsent } = useRootContext();

  const {
    errand,
    footerInputRef,
    morphType,
    errandId,
    setMorphType,
    setMainPhoto,
    setPhotoLimit,
    setPhotoSelectorIndex,
    setEditMessageId,
    setMessageOptionsIndex,
    setIsMorphedFooterCloseButtonOnLeft,
  } = useErrandContext();

  const {
    renderAvatar,
    dispMessage,
    isTranslated,
    messageId,
    messageUserId,
    messageSentByCurrentUser,
    messageAlignByCurrentUser,
    messageDetectedLanguage,
    messageLocalizedLanguage,
    messageSearchWords,
    messageVisible,
    messageIntendedAudience,
    messageIntendedAudienceLength,
    messageAccessType,
    messageMessage,
    messageMessageStatus,
    messageIcon,
    messageUserAction,
    messageAction,
    messageSenderId,
    messageUserActionId,
    messageActionFieldAttributeDescription,
  } = useMessageSingleContext();

  const { MessageBodyResizeHandler, renderSkeletonLoader, styleModifierParent, styleModifierChild } =
    useMessageBodyObserverContext();

  const { innerWidth } = useWindowDimensions();

  const messageRef = useRef(null);
  const messageBodyRef = useRef(null);
  const revealEnabledRef = useRef<boolean>(false);
  /* Set the placeholder for a message  (If it is action or field type)*/
  const [isFading, setIsFading] = useState(true);

  const [appsSearchState, setAppsSearchState] = useState({ searchMode: false, searchView: false });
  const [isAppsMenuOpen, setIsAppsMenuOpen] = useState(false);

  useEffect(() => {
      const subscriberID = `ActionMessageContent_${messageId}`;
      const _handler = (updatedState: IAppsMenuState) => {
        setAppsSearchState((prev) => {
          return {
            searchMode: updatedState.searchMode,
            searchView: updatedState.searchView
          }
        })

        setIsAppsMenuOpen(updatedState.isOpened);
      };
      AppsMenuExternalState.subscribe(subscriberID, _handler)
      return () => {
        AppsMenuExternalState.unsubscribe(subscriberID)
      }
    }, [setAppsSearchState, setIsAppsMenuOpen, messageId])

  /* On click handler for clicking an action icon */
  const handleClick = useCallback(
    async (e) => {
      e?.preventDefault();
      // Don't let the user initiate the action if it is not intended for them, or a public action.
      if (!messageVisible || (messageIntendedAudienceLength > 0 && !messageIntendedAudience?.includes(_id))) return;

      if (isUser && !allConsentsGiven) {
        setClickedDisabledFeatures(true);
        handleShakingConsent();
        return eventBus.dispatch('showConsentContent');
      }

      // Returning early for Calendar and Time if the user tries to click on the same action while they're in it already
      // Fixes a bug where the morphType would change without canceling the action, breaking the calendar/time's hide/show capabilities
      if (
        (morphType === MorphType.CalendarMonth || morphType === MorphType.DOB) &&
        (messageActionFieldAttributeDescription === 'CALENDAR' || messageActionFieldAttributeDescription === 'DOB')
      ) {
        return;
      }

      if (morphType === MorphType.Time && messageActionFieldAttributeDescription === 'TIME') {
        return;
      }
      if ([MorphType.Attachment, MorphType.PhotoMain, MorphType.PhotoPlain, MorphType.Media].includes(morphType)) {
        //reset the morph data on change.
        setMainPhoto(null);
        setPhotoLimit(null);
        setPhotoSelectorIndex(null);
      }

      if(appsSearchState.searchMode || appsSearchState.searchView || isAppsMenuOpen) {
        // cancel the search untill further scroll down
        setAppsSearchWasCancelled(isOperator, true);
      }

      if (messageAction.fieldName === 'creditCardDetails') {
        //do a check to see if we already paid for the product
        //textInputEnabled.current = false;
        const res = await axiosCall({
          url: `pay/field`,
          method: 'POST',
          data: { chat: errandId, context: errand.activeContext },
        });
        if (res.data !== 'payment already made') {
          setMorphType(MorphType.Payment);
        } else {
          setMorphType((prev) => {
            if (messageAccessType === AccessType.private) return MorphType.PrivateChat;
            return MorphType.None;
          });
          return;
        }
      } else {
        setEditMessageId('');
        setMessageOptionsIndex(-1);
        setIsMorphedFooterCloseButtonOnLeft(false);
        setMorphType((prev) => {
          if (messageAccessType === AccessType.private) return MorphType.PrivateChat;
          return MorphType.None;
        });
      }

      // updates the icon, placeholder, and action on user side when the operator clicks the icon
      if (isOperator) {
        messagesSocket.current?.emit('emit-chat-event', {
          chatId: errandId,
          data: {
            type: 'action',
            message: messageId,
          },
          recipients: [],
        });
      } else {
        // sets the corresponding userAction status to in-progress
        const payload = {
          url: `useraction/${messageUserActionId}`,
          method: 'put',
          data: {
            status: 'in-progress',
          },
        };
        await axiosCall(payload);
        setErrands((prev) => {
          if (!Array.isArray(prev)) {
            console.warn('setErrands prev is not an array');
            prev = [];
          }
          // find index of curr errand
          let index = prev.findIndex((e) => e._id === errandId);
          // update the curr errand data with new placeholder, icon and action.
          prev[index] = {
            ...prev[index],
            icon: messageIcon,
            placeholder: messageAction?.description,
            action: {
              ...messageUserAction,
              action: messageAction,
              userActionId: messageUserActionId,
              active: true,
            },
            recipients: messageIntendedAudience ? [messageSenderId, ...messageIntendedAudience].sort() : [],
          };
          return [...prev];
        });
        console.log('User triggered action, ' + messageAction?._id);
        footerInputRef?.current?.focus();
      }
    },
    [
      _id,
      errand.activeContext,
      errandId,
      footerInputRef,
      handleShakingConsent,
      isOperator,
      isUser,
      messageAccessType,
      messageAction,
      messageActionFieldAttributeDescription,
      messageIcon,
      messageId,
      messageIntendedAudience,
      messageIntendedAudienceLength,
      messageSenderId,
      messageUserAction,
      messageUserActionId,
      messageVisible,
      messagesSocket,
      morphType,
      allConsentsGiven,
      setEditMessageId,
      setErrands,
      setIsMorphedFooterCloseButtonOnLeft,
      setMainPhoto,
      setMessageOptionsIndex,
      setMorphType,
      setPhotoLimit,
      setPhotoSelectorIndex,
      appsSearchState.searchMode,
      appsSearchState.searchView
    ]
  );

  /**
   * This function is a handler callback for the Original Language component's
   * onClick event. It is responsible for temporarily revealing the origianl
   * message.
   */
  const handleClickOriginalLang = useCallback(
    async (e) => {
      e?.stopPropagation();
      if (!messageVisible) return;
      if (revealEnabledRef.current) {
        return;
      }
      const reset = messageRef.current.innerHTML;

      // Start the inital fadeout:
      revealEnabledRef.current = true;
      setIsFading(false);

      // Wait until the fadeout is done to fade in the original message:
      setTimeout(
        (reset) => {
          let editedMessage = messageMessage;

          let [firstSection, secondSection] = messageMessage
            ?.split(`<i class="messageArrow"/>`)
            .map((x) => x.replace(/(<([^>]+)>)/gi, ''));

          if (secondSection) {
            editedMessage = `<s>${firstSection}</s>&nbsp;<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1r4vxry-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowCircleRightRoundedIcon"><path d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12s4.48 10 10 10 10-4.48 10-10zm-10 2.79V13H9c-.55 0-1-.45-1-1s.45-1 1-1h3V9.21c0-.45.54-.67.85-.35l2.79 2.79c.2.2.2.51 0 .71l-2.79 2.79c-.31.31-.85.09-.85-.36z"></path></svg> <i>${secondSection}</i>`;
          }

          // Style the original message:
          editedMessage = `<div style="font-size: 0.8em; font-family: Poppins; font-weight: 400;">${editedMessage}</div>`;

          messageRef.current.innerHTML = editedMessage;

          setIsFading(true);

          // Allow the revealed message to display for some time, then begin fading
          // it out:
          setTimeout(
            (reset) => {
              setIsFading(false);

              // Finally, wait until the fadeout is done, then fade in the translated
              // message:
              setTimeout(
                (reset) => {
                  messageRef.current.innerHTML = reset;
                  setIsFading(true);
                  revealEnabledRef.current = false;
                },
                500,
                reset
              );
            },
            3000,
            reset
          );
        },
        500,
        reset
      );
    },
    [messageMessage, messageVisible]
  );

  // adds listener on user side to update the icon, placeholder, and action on user side when the operator clicks the icon
  useEffect(() => {
    if (!isMessagesConnected) return;
    if (isOperator) return;

    const chatEventHandler = (payload) => {
      if (!payload?.data) return;
      if (!payload.data?.type || !payload.data?.message) return;
      if (payload.data.type === 'action' && payload.data.message === messageId) {
        handleClick(null);
      }
    };

    const socketMessages = messagesSocket.current;

    socketMessages?.on('chat-event-emitted', chatEventHandler);
    return () => {
      socketMessages?.off('chat-event-emitted', chatEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, errandId]);

  /**
   * This Wrapper is for modifying message buble style
   * Currently it's used as a whole clickable message vs regular message
   *
   * @returns
   */
  const MessageBubleStyle = useMemo((): JSX.Element => {
    const defProps = (isClickable) => ({
      onClick: handleClick,
      onMouseDown: (e) => {
        e.preventDefault();
      },
      sx: Styles.getMsgBubbleStyleSx(isTranslated, messageAccessType, messageSentByCurrentUser, isClickable),
    });

    return (
      <ChatBubbleStyle {...defProps(false)}>
        <Stack flexDirection="column" width="100%">
          <Stack flexDirection="row">
            <MessageSideIconButtonComponent />
            <Stack alignItems="center" sx={Styles.CmnChildComponentThirdContainerStack}>
              {messageMessageStatus === 'edited' ? (
                <>
                  <Stack direction="row" justifyContent="center" alignItems="center" ref={messageRef}>
                    <Fade in={isFading} appear={false}>
                      {messageVisible ? (
                        <MessageTextStyle sx={Styles.msgTextSx}>
                          {dispMessage?.split(`<i class="messageArrow"/>`)[0]?.replace(/(<([^>]+)>)/gi, '')}
                        </MessageTextStyle>
                      ) : (
                        <MessageTextStyle>{getTextDialogHtmlVal(false, dispMessage)}</MessageTextStyle>
                      )}
                    </Fade>
                    <ArrowCircleRightRoundedIcon sx={Styles.arrowCircleRightRoundedIconSx} />
                    <Fade in={isFading} appear={false}>
                      {messageVisible ? (
                        <MessageTextStyle>
                          {dispMessage?.split(`<i class="messageArrow"/>`)[1]?.replace(/(<([^>]+)>)/gi, '')}
                        </MessageTextStyle>
                      ) : (
                        <MessageTextStyle>{getTextDialogHtmlVal(false, dispMessage)}</MessageTextStyle>
                      )}
                    </Fade>
                  </Stack>
                </>
              ) : (
                <Fade in={isFading} appear={false}>
                  <Stack direction="row" justifyContent="center" alignItems="center" ref={messageRef}>
                    <MessageTextStyle>
                      <Sanitized
                        highlight={messageSearchWords ? messageSearchWords : undefined}
                        html={getTextDialogHtmlVal(messageVisible, dispMessage)}
                      />
                    </MessageTextStyle>
                  </Stack>
                </Fade>
              )}
              &nbsp;
              <Stack sx={Styles.msgTimeContainerStyle}>
                <MessageTime />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </ChatBubbleStyle>
    );
  }, [
    dispMessage,
    handleClick,
    isFading,
    isTranslated,
    messageAccessType,
    messageMessageStatus,
    messageSearchWords,
    messageSentByCurrentUser,
    messageVisible,
  ]);

  const inAudience = useMemo(() => {
    if (isOperator) return true;
    if (!Array.isArray(messageIntendedAudience)) return true;
    if (messageIntendedAudienceLength === 0) return true;
    return messageIntendedAudience.includes(messageUserId);
  }, [messageIntendedAudience, messageIntendedAudienceLength, isOperator, messageUserId]);

  useEffect(() => {
    if (!messageBodyRef.current) return;

    const observer = new ResizeObserver(([entry]) => MessageBodyResizeHandler(entry));

    observer.observe(messageBodyRef.current);

    return () => observer.disconnect(); // Cleanup on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styleParent = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: messageAlignByCurrentUser ? 'flex-end' : 'flex-start',
      width: 'fit-content',
      maxWidth: innerWidth > 900 ? 'min(60%, calc(100vw - 50px))' : 'min(90%, calc(100vw - 50px))',
      minWidth: isTranslated ? '220px' : '85px',
      filter: inAudience ? 'unset' : 'blur(3px)',
    }),
    [inAudience, isTranslated, messageAlignByCurrentUser, innerWidth]
  );

  return (
    <>
      {renderAvatar && <TwinAvatar />}
      <Stack ref={messageBodyRef} style={{ ...styleParent, ...styleModifierParent }}>
        {renderSkeletonLoader ? (
          <MessageBodyShimmeringSkeleton />
        ) : (
          <Stack display="flex" flexDirection="column" style={styleModifierChild}>
            {MessageBubleStyle}
            {isTranslated &&
              (messageAlignByCurrentUser ? (
                <TranslationTabRight>
                  <OriginalLangStyle onClick={handleClickOriginalLang}>
                    <u>{getLangName(messageDetectedLanguage)}</u>
                  </OriginalLangStyle>
                  <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {messageLocalizedLanguage}</TranslationInfoStyle>
                </TranslationTabRight>
              ) : (
                <TranslationTabLeft>
                  <OriginalLangStyle onClick={handleClickOriginalLang}>
                    <u>{getLangName(messageDetectedLanguage)}</u>
                  </OriginalLangStyle>
                  <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {messageLocalizedLanguage}</TranslationInfoStyle>
                </TranslationTabLeft>
              ))}
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default ActionMessageContent;
