/**
 * @file This file contains enumerations and interfaces used for the blockchain wallet and UI.
 * @author Timur Bickbau
 */

// the pages in the wallet UI; any page with user input (not including chat box) should be included in the useLayoutEffect logic that prevents error reset
enum Page {
  Welcome,
  RecoverAccount,
  ViewRecoveryPhrase,
  AccessPin,
  WalletCreationCongratulations,
  QueuedAssets,
  Home,
  LinkDeviceDesktop,
  LinkDeviceMobile,
  LinkDeviceMobileSuccess,
  PublicAddress,
  AngelPoints,
  Exchange,
  ExchangeConfirmation,
  ExchangeCongratulations,
  UserFungibleListView,
  UserFungibleSingleView,
  SendFungible,
  SendFungibleConfirmation,
  SendFungibleTimeout,
  SendFungibleCongratulations,
  UserNftListView,
  UserNftSingleView,
  ApprovedAccounts,
  AttachedNftSingleView,
  BidListView,
  BidSingleView,
  AcceptedBidSingleView,
  PublicNftListView,
  PublicNftSingleView,
  AngelMinutes,
  PurchaseAngelMinutes,
  PurchaseAngelMinutesSuccess,
  ConvertPointsToAngelMinutes,
  ConvertPointsToAngelMinutesSuccess,
  PurchaseAngelPoints,
  PurchaseAngelPointsSuccess,
}

// TODO: these values should be obtained from the variables in MorphWallet.module.css
enum Color {
  White = '#FFFFFF',
  Orange = '#F96221',
  LightBlue = '#001552',
  Blue = '#010D2F',
  DarkBlue = '#01011A',
  Violet = '#5D6AFF',
  Indigo = '#1D268C',
  LightGray = '#D3D3D3',
  Red = '#FF3131',
  Green = '#39FF14'
}

// the various events dispatched and received by the wallet UI
enum Event {
  PageChange = 'walletPageChange',  // for identifying whenever the Wallet has changed pages
  ChatSend = 'walletChatSend',      // for receiving messages sent from the chat box
  ChatInput = 'walletChatInput',    // for identifying whenever the user has typed any character in the chat box (i.e. onChange)
  ChatFocus = 'walletChatFocus',    // for identifying whenever the user has focused on the input (i.e. onFocus)
  WalletScreenshot = 'walletScreenshot',  // for identifying whenever a screenshot has been taken of the wallet
  WalletExit = 'walletExit',        // for communicating to the UI that the wallet has been closed
  PageRefresh = 'walletRefresh',    // for communicating to pages that the data should be reloaded (via the header reload button)
}

// certain asset codes used by AngelAi; this is only used for certain native assets, in most cases asset codes should not be hardcoded here
enum AssetCode {
  AngelCoin = 'ANGL',
  AngelPoints = 'ANPT',
  AngelMinutes = 'TWSE',
  UsDollars = 'USD',
}

// the various NFT types
// TODO: this should be phased out wherever possible, as we pull this from DB in getSupportedTokens(), though it is needed for type-specific UI designs
enum NftType {
  TRUAPP = 'TRUAPP',  // TRU-Approval
  TRUBID = 'TRUBID',  // Purchase Bid
  TICKET = 'TICKET'   // Event Ticket
}

// the various user types that can exist on an NFT
// TODO: pull this from DB
enum NftUserType {
  OWNER = 'OWNER',      // owner
  APPROVED = 'APPROVED' // approved user
}

// the various NFT lists that are used in the UI
enum NftList {
  UserNfts,
  BidsOnNft,
  PublicNfts
}

// the data for a transaction conducted with a fungible
interface Transaction {
  date: string
  amount: number
  amountUsd: number
}

// the data for a fungible, such as ANGL
interface Fungible {
  contractNameKeyword: string // e.g., 'ANGLCN',
  chainNameKeyword: string  // e.g., 'Ethereum', 'Arbitrum'
  contractSymbol: string  // the field name in the smart contract; same as the ticker used in exchanges
  amount: number
  amountUsd: number // set using getFungibleUsdValue()
  latestContractAddress: string
  transactionHistory: Transaction[]
}

// the data for an NFT
interface Nft {
  tokenId: number
  contractAddress: string
  name: string  // set using getNftName()
  nftType: NftType
  userType: NftUserType
  owner: string
  metaData: any
  isPublic: boolean
  isBurned: boolean
}

interface Credit {
  seconds: number,
  transactionDate: number,
  userId: string,
  transactionId: string
}

export { Page, Color, Event, AssetCode, NftType, NftUserType, NftList, Fungible, Nft, Credit };
