import { NumericFormat, PatternFormat } from 'react-number-format';
import { useSocketContext } from '@contexts/socket';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useState, useEffect, useRef, PropsWithChildren, useImperativeHandle, useMemo, useCallback } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import useDebounce from '@common/hooks/useDebounce';
import { useErrandContext } from '@contexts/ErrandContext';
import { useFooterContext } from '@contexts/FooterContext';
import { useUserContext } from '@contexts/user';
import FooterInput, { InputState } from './FooterInputStorage/storage';
import { MorphType } from '@common/MorphType';
import { StyledInputBase } from '../Styles/ChatInputFieldStyle';
import axiosCall from '../Services/axios';
import { handleFile } from '../Common/handleFile';
import { useHasMounted } from '@common/hooks/useHasMounted';
import { isActionPasswordRelated } from '@common/msgUtils';
import { getLastMsg, isMessageOfTypeAction, isUserActionLastInChat } from '@common/userMessagesUtils';
import { FormBodyType } from '../Forms/commonForms';
import OTPInput from './OTPInput';
import { useMorphContext } from '@contexts/MorphContext';
import Tooltip from '@mui/material/Tooltip';
import Styles from '@styles/ChatInputField.module.css';
import { ValidatorFunctions } from '@common/Validators';
import { isMobile, isMobileOrTablet } from '@common/deviceTypeHelper';
import { Popper } from '@mui/material';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import { ANGEL_SIGN_FIELD_ATTRIBUTE } from '@common/angelSignUtils';
import { Event as WalletEvent } from '@components/MorphWallet/MorphWalletType';
import { useRootContext } from '@contexts/RootContext';
import { IMessage } from '@interfaces/Conversation';
import NewPasswordInput from '@components/NewPasswordInput';
import useSwipeableBooleanData from '@common/hooks/useSwipeableBooleanData';
import eventBus from '@common/eventBus';
import useEventBusListener from '@common/hooks/useEventBusListener';

export const ChatInputFieldEvents = {
  FORCE_FOCUS: 'force_focus_input_field'
}

const { isTypeOfString } = ValidatorFunctions;

const allowedKeys = new Set(['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End', 'Enter', 'Control', 'Meta', 'Shift', ' ', '!', '"', '#', '$', '%', '&', "'", '(', ')', '*', '+', ',', '-', '.', '/', ':', '@', '\'', '-', '_']);

const addressFields = ['addressLine1', 'addressLine2', 'city', 'state', 'country', 'postalCode'];

// User type delay for backend request
const TYPE_DEBOUNCE_MS = 200;

const ChatInputField: React.FC<PropsWithChildren<any>> = (props) => {
  const {
    operatorData,
    isPublic,
    participantId,
    honeyPot,
    fieldAttribute,
    cancelAction,
    setSelectedFiles,
    selectedAddress,
    handleSubmit,
    setFilterName,
    editMessageId,
    placeholder,
    showPassword,
  } = props;

  const { morphType, footerInputRef, formBody, errand, errandId, swipeableBooleanData } = useErrandContext();
  const { sendButtonRef, chatInputFieldRef, selectedContact, appsMenuState, captureTwinMinutesPurchaseAmount } = useFooterContext();
  const { selectedBorrowers } = useMorphContext();
  const { messagesSocket } = useSocketContext();
  const { t } = useTranslation();

  const { _id, isOperator } = useUserContext();

  const [formattedValue, setFormattedValue] = useState('');
  const [isWarningActive, setIsWarningActive] = useState(false);
  const [isPercent, setIsPercent] = useState(true); //used only for the percentage-dollar amount field attribute
  const [unformattedValue, setUnformattedValue] = useState('');
  const [value, setValue] = useState('');
  // google places auto completion option value array
  const [options, setOptions] = useState([]);
  const typing = useRef<boolean>(false);
  // For footer input storage proper work
  const hasMounted = useHasMounted();
  const { isDesktop } = useWindowDimensions();
  // const [needToConsent, setNeedToConsent] = useState(false);
  const rootContext = useRootContext();

  const fieldAttributeDescription = useMemo(() => fieldAttribute?.description, [fieldAttribute?.description]);
  const fieldAttributeFormat = useMemo(() => fieldAttribute?.format, [fieldAttribute?.format]);
  
  const errandAction = useMemo(() => errand?.action, [errand?.action]);
  const errandMessages = useMemo(() => errand?.messages, [errand?.messages]);
  const errandRecipients = useMemo(() => errand?.recipients, [errand?.recipients]);

  const ActionActive = useMemo(() => errandAction?.active, [errandAction?.active]);

  const disableForOperator = useMemo(() => {
    if (!operatorData) return false;
    if (morphType !== MorphType.PrivateChat) return false;

    // const audience = getAudienceArr(null, null, null, errand?.recipients);

    // if (audience.includes(_id) === false) {
    //   return true;
    // }

    return false;
  }, [operatorData, morphType]);

  const placeholderWrapper: string = useMemo(()=> {
    if (morphType === MorphType.DOB || morphType === MorphType.CalendarMonth) {
      return 'MM/DD/YYYY';
    }

    if (disableForOperator) {
      return t('incorrectNickname');
    }

    return placeholder;
  }, [morphType, disableForOperator, placeholder, t]);

  // variable for all the morphtypes where we want to disable typing in input
  const disableInputMorphTypes = useMemo(
    () =>
      morphType === MorphType.Time ||
      morphType === MorphType.UserPromptsMenu ||
      morphType === MorphType.CreditRepairDisputeAccountType ||
      morphType === MorphType.SelectMultiple ||
      morphType === MorphType.VideoListMenu ||
      morphType === MorphType.CreditRepairWelcome ||
      morphType === MorphType.SlotMachine ||
      morphType === MorphType.RefinanceCalculatorWelcome ||
      morphType === MorphType.SwipeableBoolean,
    [morphType]
  );

  // variable for all the field attributes where we want to disable typing in input
  const disableFieldAttributes = useMemo(
    () => ['ENVELOPE', 'CONFIRM', 'VOICE', ANGEL_SIGN_FIELD_ATTRIBUTE].includes(fieldAttributeDescription),
    [fieldAttributeDescription]
  );

  const { getInputPlaceholder } = useSwipeableBooleanData();

  useImperativeHandle(chatInputFieldRef, () => {
    return {
      formattedValue: formattedValue,
      unformattedValue: unformattedValue,
      update: (givenValue: string | InputState) => {
        // update local value states
        if(typeof givenValue === 'string') {
          setFormattedValue(givenValue);
          setUnformattedValue(givenValue);
          helperValue(givenValue);
        } 
        // InputState type
        else {
          try {
            const { 
              formattedValue,
              unformattedValue,
              value } = givenValue;
            if(isTypeOfString(formattedValue) 
              && isTypeOfString(unformattedValue) 
              && isTypeOfString(value)
            ) {
              setFormattedValue(formattedValue);
              setUnformattedValue(unformattedValue);
              helperValue(value);
            }
          } catch(err) {
            console.error("Error occured in [ChatInputField.tsx]: something wrong in chatInputFieldRef.current.update method call! Error: ", err);
            // set as empty
            setFormattedValue('');
            setUnformattedValue('');
            helperValue('');
          }
        }
      },
    };
  });

  // Handles an update operation on the FooterInput storage. Called from within handleDebounceFn whenever user stops typing and a TYPE_DEBOUNCE_MS time passed.
  const updateInputStorage = useCallback(() => {
    try {
      // Case where messages is undefined or null OR an empty array (still loading)
      if (!errandMessages || errandMessages.length === 0) return;

      // Case where component not yet mounted. (this ensures that this useEffect runs only after first render AND not during the first render.)
      if (!hasMounted) return;

      if (editMessageId) {
        // clear input for this chat in storage.
        FooterInput.clear(errandId);
        return;
      }

      // check for current message action to be relevant to password
      if (errandAction && errandAction?.action) {
        const action = errandAction?.action;
        const userActionId = errandAction?.userActionId;
        // if action is related to password
        if (isActionPasswordRelated(action)) {
          FooterInput.clear(errandId);
          // clear input in storage
          // do not save anything
          return;
        }

        if (userActionId === undefined || userActionId === null) {
          console.warn("An action is set but it doesn't have any userActionId");
          // clear input in storage
          FooterInput.clear(errandId);
          // do not save input.
          return;
        }
        // if action is not password related, then check if the last message in chat is an action
        // if so, do not save anything because if we switch to another chat while answering an action and this chat has the last
        // message to be an action as well, we don't need the input to be saved because it will always be the last action message that
        // changes the footer and puts the user in state of inputting that last action message.
        // THIS also covers an edge case where we try to answer the last action in chat.
        // This is done by checking if the current action we are answering is actually a last message in chat,
        // thus in this case we SHOULD save an input in storage.
        if (
          isMessageOfTypeAction(getLastMsg(errandMessages)) &&
          isUserActionLastInChat(userActionId, errandMessages) === false
        ) {
          FooterInput.clear(errandId);
          return;
        }
      }

      // if the menu is opened just send out the event with the value + errandId
      // so that the appsMenu component can process it
      // without saving anything into the footer input storage
      if(appsMenuState.isOpened === true) {
        eventBus.dispatch('footer_user_value_updated', { value, chat_id: errandId });
        return; // immidiately return and do not save anything into storage.
      }
      // if not editing and mounted
      // sync with footer input storage
      FooterInput.save({ value, unformattedValue, formattedValue }, errandId);
    } catch (error) {
      // clear the storage for this chat.
      FooterInput.clear(errandId);
      console.error(error);
    }
  }, [editMessageId, errandAction, errandMessages, errandId, formattedValue, hasMounted, unformattedValue, value, appsMenuState.isOpened]);

  // Sets the current participant's isTyping status to true if it is currently false
  // Starts a timer to revert the current participant's isTyping status when the timer ends
  // If the user types before the timer ends, the timer is clears and a new one is started
  const handleTyping = useCallback((errandId, participantId) => {
    if (!typing.current) {
      messagesSocket.current?.emit('emit-chat-event', {
        chatId: errandId,
        data: {
          participantId: participantId,
          type: 'typing',
          message: 'true',
          userId: _id,
          isPrivate: morphType === MorphType.PrivateChat,
        },
        recipients: errandRecipients?.length > 0 ? errandRecipients : isPublic ? [] : [`${errandId}Operators`],
      });

      typing.current = true;
    }
  }, [_id, errandRecipients, isPublic, messagesSocket, morphType]);

  const helperValue = useCallback((newValue: string) => {
    // if new value is empty don't generate typing event
    if (newValue) {
      handleTyping(errandId, participantId);
    }

    eventBus.dispatch('footer_immidiate_user_value_updated', { value: newValue || '', chat_id: errandId });

    setValue(newValue || '');
  }, [errandId, handleTyping, participantId]);

  /**
   * Focuses the userAction associated with `message` in the conversation footer
   */
  const focusUserAction = useCallback(async (message: IMessage) => {
    if (morphType) {
      return;
    }

    // Update the state of the userAction
    const payload = {
      url: `useraction/${message?.userAction?._id}`,
      method: 'put',
      data: {
        status: 'in-progress',
      },
    };
    await axiosCall(payload);

    props.setFieldAttribute(message?.action?.fieldAttribute);
    // Update the icon, placeholder, and action in the conversation footer
    rootContext.setErrands((prev) => {
      if (!Array.isArray(prev)) {
        console.warn('setErrands prev is not an array');
        prev = [];
      }
      let index = prev.findIndex((e) => e._id === errand?._id);
      prev[index] = {
        ...prev[index],
        icon: message.icon,
        placeholder: message?.action?.description,
        action: {
          ...message?.userAction,
          action: message?.action,
          userActionId: message?.userAction?._id,
          active: true,
        },
        recipients: message.intendedAudience ? [message.sender._id, ...message.intendedAudience].sort() : [],
      };
      return [...prev];
    });
    // Focus the input bar
    footerInputRef?.current?.focus();
  }, [errand?._id, footerInputRef, morphType, props, rootContext]);

  // Handles the current participant's typing activity along with the chatTimer useRef
  // sets isTyping to false in the provided chat
  const revertTyping = useCallback((errandId, participantId) => {
    // bug fix, value was undefined sometimes
    if (typing.current || value?.trim().length === 0) {
      messagesSocket.current?.emit('emit-chat-event', {
        chatId: errandId,
        data: {
          participantId: participantId,
          type: 'typing',
          message: 'false',
          userId: _id,
          isPrivate: morphType === MorphType.PrivateChat,
        },
        recipients: null,
      });

      typing.current = false;
    }
  }, [_id, messagesSocket, morphType, value]);

  const handleInputFocus = () => {
    if (morphType === MorphType.Wallet) {
      // tell the wallet that the user has pressed on the input, so we can show suggested prompts
      window.dispatchEvent(new CustomEvent(WalletEvent.ChatFocus));
    }
  }

  const handleChangeWrapper = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (honeyPot) {
        return;
      }

      // show the consent box
      if (!isOperator){
        rootContext.setClickedDisabledFeatures(true);
      }

      const targetValue = e.target?.value;

      if (!fieldAttributeDescription && targetValue === '') {
        // 1/2/2025 Ethan: if user deleted their input, we want to show the boolean yes/no buttons again
        const lastMsg = getLastMsg(errand.messages || []);
        if (lastMsg?.action?.fieldAttribute?.description === 'BOOLEAN') {
          focusUserAction(lastMsg);
        }
      } else if (fieldAttributeDescription === 'ADDRESS' && targetValue === '') {
        setOptions([]);
      } else if (fieldAttributeDescription === 'BOOLEAN' || fieldAttributeDescription === 'CONFIRM') {
        cancelAction(null);
      }

      // input validation cases that will cancel action
      switch (fieldAttributeDescription) {
        case 'EMAIL':
          // if user types anything that doesn't match this regex, cancel action
          const regex = /^[a-zA-Z0-9!\"#$%&'()*+,-./:=@'_-]*$/;
          if (!regex.test(targetValue)) {
            e.preventDefault();
            cancelAction(null);
          }
      }

      setFormattedValue(targetValue);
      setUnformattedValue(targetValue);
      helperValue(targetValue);

      if (morphType === MorphType.Contacts) return;

      sendButtonRef.current?.update(targetValue || '');
    },
    [cancelAction, fieldAttributeDescription, isOperator, rootContext.setClickedDisabledFeatures, helperValue, honeyPot, morphType, sendButtonRef]
  );

  const handleValueChangeWrapper = useCallback((e) => {
    // show the consent box
    if (!isOperator){
      rootContext.setClickedDisabledFeatures(true);
    }

    if (honeyPot) {
      return;
    }

    if (fieldAttributeDescription === 'OTP') {
      return;
    }

    setFormattedValue(e?.formattedValue);
    setUnformattedValue(e?.value);
    helperValue(e?.formattedValue);
    if (morphType === MorphType.Contacts) return;
    sendButtonRef.current?.update(e?.value || '');
  }, [fieldAttributeDescription, helperValue, honeyPot, morphType, sendButtonRef]);

  const handleFiles = useCallback((e) => {
    let files = handleFile(e.nativeEvent, errandAction?.action?.fieldName);
    if (typeof files === 'string') {
      setIsWarningActive(t(files));
    }
    // make this an ELSE if to ensure no files are added if there is an error
    else if (files.length > 0){
      e.preventDefault();
      setSelectedFiles((prev) => {
        let uniqueFiles = [...prev, ...files].filter((value: File, index: number, array: File[]) => array.findIndex((v: File) => v === value) === index);
        return uniqueFiles;
      });
    }
  }, [errandAction?.action?.fieldName, isOperator, rootContext.setClickedDisabledFeatures, setSelectedFiles, t]);

  /**
   * Event: Generated when input field text changes
   */
  const handleAutoCompletionValueChangeWrapper = useCallback((e) => {
    // show the consent box
    if (!isOperator){
      rootContext.setClickedDisabledFeatures(true);
    }
    
    if (e?.target?.value === '') {
      // if textfield is empty reset option
      setOptions([]);
    }
    setFormattedValue(e?.target?.value);
    setUnformattedValue(e?.target?.value);
    helperValue(e?.target?.value);
    if (morphType === MorphType.Contacts) return
    sendButtonRef.current?.update(e?.target?.value || '');
  }, [helperValue, morphType, isOperator, rootContext.setClickedDisabledFeatures, sendButtonRef]);

  /**
   * Event: Generated when auto completion option selected from dropdown
   */
  const handleAutoCompletionSelectedWrapper = useCallback((event, input) => {
    // show the consent box
    if (!isOperator){
      rootContext.setClickedDisabledFeatures(true);
    }

    // once recommendation is selected reset option
    setOptions([]);
    axiosCall({
      url: `google/place/details`,
      method: 'post',
      data: {
        place_id: options[event.target.dataset.optionIndex]?.id,
      },
    })
      .then((result) => {
        // Once the value exists iterate the fields in order and build the string.
        if (result) {
          // this variable should be revisited
          var addressString = [];
          for (const val of addressFields) {
            if (result[val]) {
              addressString.push(result[val]);
            }
          }
        }

        let addressSelected: string = addressString.join(', ');
        selectedAddress.current = addressSelected;
        setFormattedValue(addressSelected);
        setUnformattedValue(addressSelected);
        helperValue(addressSelected);
      })
      .catch((error) => {
        console.log("google place details API doesn't work" + error.message);
      });
  }, [helperValue, options, isOperator, rootContext.setClickedDisabledFeatures, selectedAddress]);

  const sanitizeAndFormatNumberInput = (input: string) => {
    // Ensure only numbers and decimal points remain
    input = input?.replace(/[^0-9.]/g, '');
    // Ensure there's only one decimal point
    input = input.replace(/(\..*)\./g, '$1');
    // Limit to two decimal places
    input = input.replace(/(\.\d{2})\d+/g, '$1');
    if (input === '.') {
      input = '0.';
    }
    // Add commas as thousands separators
    if (input.includes('.') && input !== '0.') {
      // Split at decimal point
      const [integerPart, decimalPart] = input.split('.');
      // Format integer part with commas
      const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // Reassemble with decimal part
      input = `${formattedInteger}.${decimalPart}`;
    } else {
      // No decimal point, just format the whole number
      input = input.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    if (Number(input) === 0 && input !== '0.') {
      input = '0';
    }
    // Remove leading zero if present
    if (input && input.length > 1 && input[0] === '0' && input[1] !== '.') {
      input = input.substring(1);
    }
    return input;
  }

  const handleWalletInput = (e: any) => {
    if (morphType === MorphType.Wallet) {
      let input = e?.target?.value;
      if (captureTwinMinutesPurchaseAmount && input !== '') {
        input = sanitizeAndFormatNumberInput(input || '');
        helperValue(input); 
      }
      // send the full chat box contents to the MorphWallet
      window.dispatchEvent(
        new CustomEvent(WalletEvent.ChatInput, {
          detail: { input }
        })
      );
    }
  }

  const handleInput = useCallback(
    (e) => {
      // If user currently has a selected contact, trying to edit the input field will
      // automatically unselect the contact and revert the input field to what it was prior
      // to the selection
      if (honeyPot) {
        return;
      }

      const targetValue = e.target?.value;

      if (targetValue && fieldAttributeDescription === 'BOOLEAN') {
        if (!e.nativeEvent.isTrusted && [...targetValue].every((char) => /[yesnoYESNO]/.test(char))) {
          setFormattedValue(targetValue);
          setUnformattedValue(targetValue);
          helperValue(targetValue);
        } else if (!/[yesnoYESNO]/.test(String.fromCharCode(e.keyCode))) {
          e.preventDefault();
          cancelAction(e.key);
        } else {
          helperValue(formattedValue);
        }
      } else if (
        fieldAttributeDescription === 'NUMBER' ||
        fieldAttributeDescription === 'PHONE' ||
        fieldAttributeDescription === 'MORPH PHONE' ||
        fieldAttributeDescription === 'LOAN NUMBER' ||
        fieldAttributeDescription === 'DOLLAR AMOUNT' ||
        fieldAttributeDescription === 'MULTI SELECT NUMBER'
      ) {
        // Autocomplete event has isTrusted property set to false
        // check for autocomplete and if autocomplete contents
        // satisfies the input requirements
        if (!e.nativeEvent.isTrusted && [...targetValue].every((char) => /[acvACV0-9]/.test(char))) {
          setFormattedValue(targetValue);
          setUnformattedValue(targetValue);
          helperValue(targetValue);
        } else if (!isFinite(e.key) && !allowedKeys.has(e.key) && !/[acvACV0-9]/.test(String.fromCharCode(e.keyCode))) {
          e.preventDefault();
          cancelAction(e.key);
        } else {
          helperValue(formattedValue);
        }
      }

      // Code removed due to incompatibility with Chrome keyboard on Android.
      // `e.key` always returned Unidentified and `e.keyCode` returned 229 for
      // all alphanumeric keys. Use of the onChange property has been opted for
      // instead. This allows for the entire input value to be validated rather
      // than validate on keyDown, which users can cirvumvent anyway. - Eric
      // if (
      //   !e.nativeEvent.isTrusted &&
      //   [...e.target.value].every(char => /[a-zA-Z0-9]/.test(char) || allowedKeys.has(char)) &&
      //   fieldAttributeDescription === 'EMAIL'
      // ) {
      //   setFormattedValue(e.target.value)
      //   setUnformattedValue(e.target.value)
      //   helperValue(e.target.value)
      // } else if (
      //   !allowedKeys.has(e.key) &&
      //   !/[a-zA-Z0-9]/.test(String.fromCharCode(e.keyCode)) &&
      //   fieldAttributeDescription === 'EMAIL'
      // ) {
      //   e.preventDefault();
      //   cancelAction(e.key);
      // }

      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        if (!isOperator && !rootContext.allConsentsGiven && !isMobileOrTablet()){
          console.log('User cannot use enter to accept consent.')
          rootContext.handleShakingConsent();
          return;
        }

        if (!isMobileOrTablet()) {
          handleSubmit();
        } else {
          const input = footerInputRef.current;
          if (input) {
            const start = input.selectionStart;
            const end = input.selectionEnd;
            const value = input.value;

            // Insert a newline character at the current cursor position
            const newValue = value.substring(0, start) + '\n' + value.substring(end);

            // Update the input value and adjust the cursor position
            input.value = newValue;
            setFormattedValue(newValue);
            setUnformattedValue(newValue);
            helperValue(newValue);

            input.selectionStart = input.selectionEnd = start + 1;

            // Check if the cursor is out of view
            const lineHeight = parseFloat(window.getComputedStyle(input).lineHeight);
            const rowsToShow = 4;
            const visibleHeight = lineHeight * rowsToShow; // Calculate the height of visible area
            const scrollTop = input.scrollTop;
            const cursorTop = lineHeight * (start + 1);

            // Calculate the position of the cursor relative to the visible area
            const cursorRelativeToVisible = cursorTop - scrollTop;

            // If the cursor position is below the visible area, scroll down
            if (cursorRelativeToVisible > visibleHeight) {
              input.scrollTop = cursorTop - visibleHeight;
              // If cursor is above the visible area, scroll up
            } else if (cursorRelativeToVisible < 0) {
              input.scrollTop = cursorTop;
            }
          }
        }
      }
    },
    [cancelAction, fieldAttributeDescription, footerInputRef, formattedValue, isOperator, rootContext.setClickedDisabledFeatures, rootContext.handleShakingConsent, handleSubmit, helperValue, honeyPot]
  );


  /**
   * debounced function that waits user inactivity, sends request to Morgan-Core
   */
  const handleDebounceFn = useCallback((errandId, participantId) => {
    updateInputStorage();
    revertTyping(errandId, participantId);
    setFilterName(chatInputFieldRef?.current?.unformattedValue)
    if (fieldAttributeDescription === 'ADDRESS' && value) {
      if (value.length === 0) {
        setOptions([]);
      } else {
        axiosCall({
          url: `google/place/autocomplete`,
          method: 'post',
          data: {
            input: value,
          },
        })
          .then((result) => {
            setOptions(result);
          })
          .catch((error) => {
            console.log("google place autocomplete API doesn't work" + error.message);
          });
      }
    }
  }, [chatInputFieldRef, fieldAttributeDescription, revertTyping, setFilterName, updateInputStorage, value]);

  const CustomPopper = useCallback((props) => {
    return (
      <Popper
        {...props}
        placement="top" // This makes the suggestions appear above the input element
      />
    );
  }, []);

  const handleBlur = useCallback((event) => {
    const newFocusElement = event?.relatedTarget;
    if (newFocusElement) {
      console.info(`Focus moved to: ${newFocusElement.tagName}`);
    } else {
      console.info('Focus moved out of the page or to a non-interactive element.');
    }
  }, []);
  
  /**
   * Following function make sure that Morgan-Core endpoint won't get spam requests, basically this debounce
   * function delays request until user stops typing and pre-defined amount of time pass.
   */
  useDebounce(() => handleDebounceFn(errandId, participantId), TYPE_DEBOUNCE_MS, [value]);

  // Revert the typing status for this participant when errandId changes
  useEffect(() => {
    return () => {
      revertTyping(errandId, participantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errandId]);

  useEffect(() => {
    const contactName = selectedContact?.displayName;
    if (contactName) {
      helperValue(contactName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact]);

  useEffect(() => {
    const selectedBorrowersArr = Array.from(selectedBorrowers);
    const borrowerNames = selectedBorrowersArr.map((borrower) => `${borrower.firstName} ${borrower.lastName}`)
    const chatInputString = borrowerNames.join(', ')
    if (!chatInputString) return;
    helperValue(chatInputString);
    setFormattedValue(chatInputString);
    setUnformattedValue(chatInputString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBorrowers]);

  // If the footer morphType gets set to ShareCustomLink, the footer is wiped to 
  // display the placeholder
  useEffect( () => {
    if(morphType === MorphType.ShareCustomLink){
      setFormattedValue('');
      setUnformattedValue('');
      helperValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [morphType])

  useEffect(() => {
    if (!footerInputRef.current) return;
    const canFocus = typeof footerInputRef.current.focus === 'function';
    const canBlur = typeof footerInputRef.current.blur === 'function';
    if (canFocus) return footerInputRef.current.focus();
    if (canBlur) return footerInputRef.current.blur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errandId]);

  const onForceInputFieldFocusEvent = (e) => {
    footerInputRef?.current?.focus();
  }
  useEventBusListener(ChatInputFieldEvents.FORCE_FOCUS, onForceInputFieldFocusEvent, []);

  return (
    <>
      <Snackbar
        open={isWarningActive ? true : false}
        onClose={() => setIsWarningActive(null)}
        message={isWarningActive}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
      {ActionActive && fieldAttributeDescription === 'OTP' ? (
        <OTPInput handleSubmit={handleSubmit} />
      ) : ActionActive &&
        fieldAttributeDescription === 'DOLLAR AMOUNT' ? (
        <NumericFormat
          autoFocus
          customInput={StyledInputBase}
          disabled={!participantId || honeyPot !== '' || disableInputMorphTypes || disableForOperator}
          disableUnderline
          ref={footerInputRef}
          onKeyDown={handleInput}
          onValueChange={handleValueChangeWrapper}
          placeholder={placeholderWrapper}
          prefix='$'
          thousandSeparator=','
          type='tel'
          value={value}
        />
        ): ActionActive &&
        fieldAttributeDescription === 'PERCENT' ? (
          <NumericFormat
            autoFocus
            customInput={StyledInputBase}
            disabled={!participantId || honeyPot !== '' || disableInputMorphTypes || disableForOperator}
            disableUnderline
            ref={footerInputRef}
            onKeyDown={handleInput}
            onValueChange={(values) => {
              setValue(values.value);
              handleValueChangeWrapper(values);
            }}
            placeholder={placeholderWrapper}
            suffix={value ? '%' : ''}
            thousandSeparator={value && value.length > 2 ? ',' : false}
            // Adjust the format or use a custom formatting function if needed
            format={value && value.length > 2 ? null : '##'}
            type='text' // 'tel' for numeric keypad on mobile. we switch to text so we can allow decimals.
            value={value}
          />
      ) : ActionActive &&
      fieldAttributeDescription === 'PERCENT OR DOLLAR AMOUNT' ? (
        /* ANYTHING UNDER 100 IN VALUE IS A PERCENTAGE, OTHERWISE DOLLAR AMOUNT. USED FOR DOWN PAYMENTS */
        <NumericFormat
          autoFocus
          customInput={StyledInputBase}
          disabled={!participantId || honeyPot !== '' || disableInputMorphTypes || disableForOperator}
          disableUnderline
          ref={footerInputRef}
          onKeyDown={handleInput}
          onValueChange={(values) => {
            const numericValue = parseFloat(values.value.replace(/[^\d.]/g, ''));
            const currentIsPercent = numericValue < 100 || values.value.includes('%');
            setIsPercent(currentIsPercent); // Update state based on current input
            setValue(values.value); // Set the raw value without formatting
            handleValueChangeWrapper(values);
          }}
          placeholder={placeholderWrapper}
          prefix={!isPercent ? '$' : ''}
          suffix={isPercent ? '%' : ''}
          thousandSeparator={!isPercent}
          format={isPercent ? '##.##' : null} // Allow decimal for percent, no format for dollar
          type='text' // 'tel' for numeric keypad on mobile. we switch to text so we can allow decimals.
          value={value}
        />
    ) : ActionActive &&
        fieldAttributeDescription === 'ADDRESS' ? (
        <Autocomplete
          disableClearable
          disabled={!participantId || honeyPot !== '' || disableInputMorphTypes || disableForOperator}
          filterOptions={(x) => x}
          freeSolo
          fullWidth
          onChange={handleAutoCompletionSelectedWrapper}
          options={options}
          size='small'
          value={value as any}
          // following field make sure that box size width is gonna be min 250
          componentsProps={{
            paper: {
              sx: {
                minWidth: 250,
              },
            },
          }}
          // following field is dynamic responsive font size implementation
          renderOption={(props, options) => (
            <Typography variant='body2' {...props}>
              {options.label}
            </Typography>
          )}
          renderInput={(params) => (
            <StyledInputBase
              autoFocus
              disabled={!participantId || honeyPot !== '' || disableInputMorphTypes || disableForOperator}
              disableUnderline
              inputProps={params.inputProps}
              onChange={handleAutoCompletionValueChangeWrapper}
              placeholder={placeholderWrapper}
              ref={params.InputProps.ref}
              sx={{ flex: 1, paddingRight: '1px' }}
            />
          )}
          PopperComponent={CustomPopper}
        />
      ) : ActionActive && fieldAttributeFormat && fieldAttributeFormat?.indexOf('S') === -1 ? (
        <PatternFormat
          autoFocus
          customInput={StyledInputBase}
          disabled={!participantId || honeyPot !== '' || disableInputMorphTypes || disableForOperator}
          disableUnderline
          format={fieldAttributeFormat}
          ref={footerInputRef}
          mask='_'
          onKeyDown={handleInput}
          onValueChange={handleValueChangeWrapper}
          placeholder={placeholderWrapper}
          sx={{ flex: 1 }}
          type={fieldAttributeDescription === 'PASSWORD' ? 'password' : 'tel'}
          value={value}
        />
      ) : props.errand?.action?.active && 
        props.fieldAttribute?.description === 'PASSWORD' &&
        [
          "newPassword", 
          "newBorrowerPassword"
        ].includes(errand.action?.action?.fieldName) ? (
        <NewPasswordInput handleSubmitProp={props.handleSubmit} showPassword={props.showPassword}/>
      ) : (
        <Tooltip
          open={fieldAttributeDescription === 'CHARACTER LIMIT' && unformattedValue.length === 48}
          title={t('characterLimitReached')}
          placement='top'
          >
        {/* <Tooltip
          open={needToConsent}
          title={t('pleaseAcceptTC')}
          placement='top'
        > */}
          <StyledInputBase
            autoComplete='off'
            autoFocus={formBody === FormBodyType.CreateSignatureDesktop ? false : true}
            disabled={!participantId || honeyPot !== '' || disableInputMorphTypes || disableForOperator || disableFieldAttributes}
            disableUnderline
            inputProps={{
              onPasteCapture: handleFiles,
              maxLength: fieldAttributeDescription === 'CHARACTER LIMIT' ? 48 : undefined
            }}
            onBlur={handleBlur}
            inputRef={footerInputRef}
            maxRows={4}
            onChange={handleChangeWrapper}
            onFocus={handleInputFocus}
            // onClick={checkConsent}
            onKeyDown={handleInput}
            onKeyUp={morphType === MorphType.Wallet ? handleWalletInput : () => {}}
            placeholder={
              morphType === MorphType.SwipeableBoolean
              ? `${getInputPlaceholder()}`
              : placeholderWrapper
            }
            morphType={morphType}
            value={morphType === MorphType.SelectMultiple ? "" : value}
            multiline={
              fieldAttributeDescription !== 'PASSWORD' &&
              fieldAttributeDescription !== 'EMAIL'
                ? true
                : undefined
            }
            type={
              fieldAttributeDescription === 'PASSWORD'
                ? showPassword
                  ? 'text'
                  : 'password'
                : fieldAttributeDescription === 'EMAIL'
                ? 'email'
                : 'search'
            }
          />
        </Tooltip>
        // </Tooltip>
      )}
    </>
  );
};

export default ChatInputField;
