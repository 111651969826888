/**
 * @file This file contains functions used for the blockchain wallet and UI.
 * @author Timur Bickbau
 */

import { ethers } from 'ethers';
import axiosCall from '@services/axios';
import { isMobile } from '@common/deviceTypeHelper';
import { UAParser } from 'ua-parser-js';
import { UiText } from '@components/MorphWallet/MorphWalletUiText';
import { NftType, NftUserType, NftList, Page, AssetCode } from  '@components/MorphWallet/MorphWalletType';

const ecies = require('ecies-geth');

window.Buffer = window.Buffer || require('buffer').Buffer;

/**
 * MorphWalletUtils
 */

class MorphWalletUtils {
  /**
   * blockchain wallet keys are deterministically derived from a "mnemonic phrase", which
   * should consist of at least 12 words (128-bit entropy) for sufficient security; 24 words
   * is technically more secure, but is generally considered excessive and risks user error
   */
  static readonly NUM_MNEMONIC_WORDS = 12;
  static readonly MNEMONIC_ENTROPY = this.NUM_MNEMONIC_WORDS === 12 ? 128 : 256;

  // error handling constants
  static readonly INVALID_WORD = 'INVALID_WORD';
  static readonly INVALID_CHECKSUM = 'INVALID_CHECKSUM';

  // passcode constants
  static readonly ACCESS_PIN_LENGTH = 6;
  static readonly LINK_DEVICE_CODE_LENGTH = 4;

  // UI constants
  static readonly smallMobileScreen = isMobile() && window.innerWidth < 410; // whether we are on a small mobile screen (less than 410px width)

  // other constants
  static readonly BLOCKCHAIN_PATH = 'blockchain';     // for calling Core endpoints
  static readonly BLOCKCHAIN_KEYS = 'blockchainKeys'; // for storage of encrypted blockchain keys

  /**
   * Logging Functions
   */

  /**
   * @description Format the message to be logged in the console.
   * @param {string} funcName The name of the function from which the log is being logged.
   * @param {string} message The message to be logged.
   * @param {number?} occurrence The order in which this log appears in the function (e.g., the second log in a function has occurrence 2).
   */
  static formatLog = (funcName: string, message: string, occurrence: number = null) => {
    if (occurrence) {
      return `${funcName}.${occurrence}: ${message}`;
    }

    return `${funcName}: ${message}`;
  }

  /**
   * Wallet Management Functions
   */

  /**
   * @description Generate a new wallet with a pseudo-random mnemonic phrase.
   */
  static generateWallet = () => {
    const log = this.generateWallet.name;

    try {
      const bip39 = require('bip39');

      // generate the mnemonic phrase; store it as an array of characters for better security in memory
      const mnemonicPhrase = bip39.generateMnemonic(this.MNEMONIC_ENTROPY)?.split('');

      /**
       * the private key is a password needed for verifying any transactions from the wallet
       * the public address is an identifier that can be used, for example, to send funds to the wallet
       */
      const { MorphWallet, error } = this.getKeysFromPhrase(mnemonicPhrase);

      if (error) {
        throw new Error(error);
      }

      return { MorphWallet, mnemonicPhrase };
    } catch (err) {
      return { error: `${log}: ${err?.message}` };
    }
  }

  /**
   * @description Deterministically derive a wallet's private key and public address from their mnemonic phrase.
   * @param {string[]} mnemonicPhrase The wallet's mnemonic phrase.
   */
  static getKeysFromPhrase = (mnemonicPhrase: string[]) => {
    const log = this.getKeysFromPhrase.name;

    try {
      // validate mnemonic phrase
      const response = this.validatePhrase(mnemonicPhrase);
      if (response?.error) {
        throw new Error(response.error);
      }
      
      // derive private key and public address from mnemonic phrase; be careful changing this as it may result in a different derivation path
      const wallet = ethers.Wallet.fromPhrase(mnemonicPhrase.join(''));

      // Store the keys as an array of characters for better security in memory.
      const privateKey = wallet.privateKey.split('');
      const publicKey = wallet.publicKey.split('');
      const publicAddress = wallet.address;
      const MorphWallet = { privateKey, publicKey, publicAddress };

      return { MorphWallet };
    } catch (err) {
      if (err?.message?.includes('invalid mnemonic word at index')) {
        return { error: `${this.INVALID_WORD}${err.message?.split(' ')[5]}` };
      } else if (err?.message?.includes('invalid mnemonic checksum')) {
        return { error: this.INVALID_CHECKSUM };
      } else {
        return { error: `${log}: ${err?.message}` };
      }
    }
  }

  /**
   * @description Wipe the user's keys from memory by zeroing them and then nulling them. ONLY wipes the mnemonic phrase and the private key from the keys structure.
   * @param {string[]} keys.mnemonicPhrase The wallet's recovery phrase.
   * @param {string[]} keys.privateKey The wallet's private key.
   * @param {string[]} keys.publicKey The wallet's public key.
   * @param {string} keys.publicAddress The wallet's public address.
   */
  static wipeKeys = async (keys: { mnemonicPhrase: string[], privateKey: string[], publicKey: string[], publicAddress: string}) => {
    const log = this.wipeKeys.name;

    try {
      // If the keys are not already null, overwrite them with zeros and then null them.
      if (!keys) {
        throw new Error('The keys structure is already null. Cannot wipe the keys.');
      }

      if (keys.mnemonicPhrase) {
        keys.mnemonicPhrase.fill('');
        keys.mnemonicPhrase = null;
      }

      if (keys.privateKey) {
        keys.privateKey.fill('');
        keys.privateKey = null;
      }
    } catch (err) {
      const error = `${log}: ${err?.message}`;
      console.error(error);
      return { error };
    }
  }

  /**
   * @description Encrypt the user's blockchain keys behind their access PIN.
   * @param {string[]} keys.mnemonicPhrase The wallet's recovery phrase.
   * @param {string[]} keys.privateKey The wallet's private key.
   * @param {string[]} keys.publicKey The wallet's public key.
   * @param {string} keys.publicAddress The wallet's public address.
   * @param {string} accessPin The user's access PIN.
   */
  static encryptKeys = async (mnemonicPhrase: string[], accessPin: string) => {
    const log = this.encryptKeys.name;

    if (!mnemonicPhrase || !accessPin) {
      return { error: `${log}.1: Missing required data.` };
    }

    // Validate the mnemonic phrase.
    const mnemonicPhraseValidation = this.validatePhrase(mnemonicPhrase);
    if (mnemonicPhraseValidation?.error) {
      return { error: `${log}.2: ${mnemonicPhraseValidation.error}` };
    }

    // Validate the access PIN.
    const accessPinValidation = this.validateCode(accessPin, this.ACCESS_PIN_LENGTH);
    if (accessPinValidation?.error) {
      return { error: `${log}.3: ${accessPinValidation.error}` };
    }

    try {
      // MRGN-1317 Vladimir Grbić 2025-02-14
      // This function was changed so that it does not call the Core endpoint
      // for encryption but instead it encrypts the keys here using Ethers.js.
      // This was done to avoid transmitting keys out of the user's device.
      // Ethers.js allows the following:
      // - Create a Wallet object using privateKey.
      //   This is faster but only has basic wallet functionality. It does NOT
      //   preserve the mnemonicPhrase and cannot be used to derive child
      //   wallets.
      // - Create an HDNodeWallet using the mnemonicPhrase.
      //   A bit slower, but more secure and more feature-rich. It preserves
      //   the mnemonic phrase and can derive multiple child wallets.
      // Here, we use the second option. This object will be encrypted and later
      // used to retrieve the keys in decryptKeys function.
      const wallet = ethers.HDNodeWallet.fromPhrase(mnemonicPhrase.join('').trim());

      // Encrypts the wallet using accessPin and returns JSON Keystore Wallet.
      const encryptedKeys = await wallet.encrypt(accessPin);

      if (!encryptedKeys) {
        throw new Error('No keys returned.');
      }

      return { encryptedKeys };
    } catch (err) {
      return { error: `${log}.4: ${err?.message}` };
    }
  }

  /**
   * @description Get the user's decrypted blockchain keys given the encrypted keys and their access PIN.
   * @param {string} encryptedKeys The user's encrypted keys.
   * @param {string} accessPin The user's access PIN.
   */
  static decryptKeys = async (encryptedKeys: string, accessPin: string) => {
    const log = this.decryptKeys.name;

    if (!encryptedKeys || !accessPin) {
      return { error: `${log}.1: Missing required data.` };
    }

    // Validate the access PIN.
    const accessPinValidation = this.validateCode(accessPin, this.ACCESS_PIN_LENGTH);
    if (accessPinValidation?.error) {
      return { error: `${log}.2: ${accessPinValidation.error}` };
    }

    try {
      // MRGN-1317 Vladimir Grbić 2025-02-14
      // Check if this is a new encryption format (using wallet from Ethers.js)
      // Before MRGN-1317, the logic for encryption/decryption was different and
      // did not use Ethers.js. We have changed this in MRGN-1317 so that user's
      // keys never leave their device. Hence, decryption is done here locally.
      // The new encryptKeys function returns JSON Keystore Wallet encrypted
      // with accessPin.
      let isNewFormat = false;

      if (ethers.isKeystoreJson(encryptedKeys)) {
        isNewFormat = true;
        console.log(`${log}.3: Using new ethers.js wallet format.`);
      } else {
        console.log(`${log}.4: Using legacy encryption format.`);
      }

      if (isNewFormat) {
        // Decrypt the wallet.
        // The decryptKeystoreJson returns KeystoreAccount object which has the following contents:
        // - address: string
        // - mnemonic?: { entropy: string , locale?: string , path?: string }
        // - privateKey: string
        const keystoreAccount = await ethers.decryptKeystoreJson(encryptedKeys, accessPin);

        // Obtain signingKey from private key so that we can have public key.
        const signingKey = new ethers.SigningKey(keystoreAccount.privateKey);
        
        // Derive all keys from the keystore account.
        const decryptedKeys = {
          mnemonicPhrase: ethers.Mnemonic.fromEntropy(keystoreAccount.mnemonic?.entropy).phrase.split(''),
          privateKey: keystoreAccount.privateKey.split(''),
          publicKey: signingKey.publicKey.split(''),
          publicAddress: keystoreAccount.address
        };

        if (!decryptedKeys || !decryptedKeys?.mnemonicPhrase || !decryptedKeys?.privateKey || !decryptedKeys?.publicKey || !decryptedKeys?.publicAddress) {
          throw new Error('no keys returned');
        }

        return { decryptedKeys };
      } else {
        // Old format before MRGN-1317.
        // Use the Core endpoint to decrypt the keys.
        // NOTE: This endpoint is deprecated and is here only to be used with
        //       wallets that were encrypted before the changes made to
        //       encryption/decryption logic in MRGN-1317.
        const { error, decryptedKeys } = await axiosCall({
          url: `${this.BLOCKCHAIN_PATH}/decryptKeys`,
          method: 'POST',
          data: {
            encryptedKeys,
            accessPin
          }
        });

        if (error) {
          throw new Error(`legacy decryption failed: ${error}`);
        }

        if (!decryptedKeys || !decryptedKeys?.mnemonicPhrase || !decryptedKeys?.privateKey || !decryptedKeys?.publicAddress) {
          throw new Error('no keys returned from legacy decryption');
        }

        // Handle keys encrypted before MRGN-1300 (when the mnemonic phrase and
        // the private key were converted from string to string[] for better
        // security).
        if (decryptedKeys?.mnemonicPhrase?.constructor !== Array) {
          decryptedKeys.mnemonicPhrase = decryptedKeys.mnemonicPhrase?.split('');
          decryptedKeys.privateKey = decryptedKeys.privateKey?.split('');
        }

        // Generate public key for wallets encrypted without one (before MRGN-1317).
        const wallet = new ethers.Wallet(decryptedKeys.privateKey.join(''));
        const publicKey = wallet.signingKey.publicKey.split('');

        decryptedKeys.publicKey = publicKey;

        return { decryptedKeys };
      }
    } catch (err) {
      return { error: `${log}.5: ${err?.message}` };
    }
  }

  /**
   * @description Sign the transaction request returned from Malcom.
   * @todo Ideally this should be a larger function which also accepts URL and request body, and handles both calls to the endpoint.
   */
  static signTransactionRequest = async (transactionRequest: any, privateKey: string[]) => {
    try {
      const walletSigner = new ethers.Wallet(privateKey?.join(''));
      const signedTransaction = await walletSigner.signTransaction(transactionRequest);
      return { signedTransaction };
    } catch (err) {
      return { error: err?.message };
    }
  }

  /**
   * Validation Functions
   */

  /**
   * @description Validate a mnemonic phrase used for recovering an account.
   * @param {string[]} mnemonicPhrase The mnemonic phrase to be validated.
   * @todo Ideally we should put the BIP39 word list in a JSON and check against it (no security risk as word list is public).
   */
  static validatePhrase = (mnemonicPhrase: string[]) => {
    const log = this.validatePhrase.name;

    if (!mnemonicPhrase) {
      return { error: `${log}.1: Mnemonic phrase not provided.` };
    }

    if (mnemonicPhrase?.join('').split(' ').length !== this.NUM_MNEMONIC_WORDS) {
      return { error: `${log}.2: Mnemonic phrase does not contain ${this.NUM_MNEMONIC_WORDS} words.` };
    }
  }

  /**
   * @description Validate a code input from the UI, such as the user's access PIN or link device code.
   * @param {string} code The code to be validated.
   * @param {number} length The expected length of the code.
   */
  static validateCode = (code: string, length: number) => {
    const log = this.validateCode.name;

    if (!code) {
      return { error: `${log}.1: No code provided.` };
    }

    if (code.length !== length) {
      return { error: `${log}.2: Code must be of length ${length}.` };
    }

    const regex = /^\d+$/;
    if (!regex.test(code)) {
      return { error: `${log}.3: Code must only contain numeric characters.` };
    }
  }

  /**
   * @description Validate the NFT data returned by the above function.
   * @param {any} nftData The NFT data to be validated.
   * @param {NftList} tokenList The type of NFT list.
   */
  static validateNftData = (nftData: any, tokenList: NftList) => {
    const log = this.validateNftData.name;

    if (!nftData) {
      return { error: `${log}.1: Missing token data.` };
    }

    if (!nftData?.tokenId && nftData?.tokenId !== 0) {
      return { error: `${log}.2: Missing token ID.` };
    }

    if (!nftData?.contractAddress || !nftData?.nftType || !nftData?.owner) {
      return { error: `${log}.3: Missing data for token ${nftData?.tokenId}.` };
    }

    // TODO.MRGN-1458: Also add NftList.QueuedNfts?
    if (tokenList === NftList.UserNfts) {
      if (!nftData?.userType || !nftData?.metaData || nftData?.isPublic === null) {
        return { error: `${log}.4: Missing data for token ${nftData?.tokenId}.` };
      }
    }
  }

  /**
   * @description Validate the amount of the input asset entered at the exchange.
   * @param {string} inputAsset The contract symbol or exchange code used by the asset being sent.
   * @param {number} inputAmount The amount of the input asset entered at the exchange.
   * @param {number} inputBalance The amount of the input asset available for the user.
   * @returns {UiText} The error to display, if one is caught.
   */
  static validateExchangeInput = (inputAsset: string, inputAmount: number, inputBalance: number): UiText => {
    const MAX_REDEEMABLE_POINTS = 999999;

    if (Number.isNaN(inputAmount) || inputAmount < 0) {
      return UiText.errInvalidAmount;
    } else if (inputAsset === AssetCode.AngelPoints && String(inputAmount).includes('.')) {
      return UiText.errPartialPoints;
    } else if (inputAmount > MAX_REDEEMABLE_POINTS) {
      return UiText.errInputTooLarge;
    } else if (inputAmount > inputBalance) {
      return UiText.errAmountExceedsBalance;
    }
  }

  /**
   * NFT Data Functions
   */

  /**
   * @description Returns the value of the tradeable in USD given the amount of that tradeable.
   */
  static getFungibleUsdValue = (amount: number) => {
    // TODO.USD
    
    return 0;
  }

  /**
   * @description Decrypt data received from Malcom using a wallet's private key.
   * @param {string} encryptedData The data to be decrypted.
   * @param {string[]} privateKey The private key behind which the data is encrypted.
   * @todo Test to confirm this function works locally on the device. Currently it's not used anywhere.
   */
  static decryptData = async (encryptedData: string, privateKey: string[]) => {
    const log = this.decryptData.name;

    if (!encryptedData || !privateKey) {
      return { error: `${log}.1: Missing required data.` };
    }

    try {
      // MRGN-1317 Vladimir Grbić 2025-02-05
      // Decrypt the data.
      const base64DecodedEncryptedData = JSON.parse(Buffer.from(encryptedData, 'base64').toString());
      const decryptedData = ecies.decrypt(privateKey, base64DecodedEncryptedData);

      if (!decryptedData) {
        throw new Error('Data was not able to be decrypted.');
      }

      return { decryptedData };
    } catch (err) {
      return { error: `${log}.2: ${err?.message}` };
    }
  }

  /**
   * @description Collect and return the data for an individual NFT from the response JSON of a Malcom list request, such as /getNfts.
   * @param {any} listResponse The response JSON from the Malcom list request.
   * @param {any} responseNftData The data of the individual NFT from the response JSON of the list request.
   * @param {NftList} tokenList The type of NFT list.
   * @param {string} publicAddress The public address of the user.
   */
  static getNftData = async (listResponse: any, responseNftData: any, tokenList: NftList, publicAddress: string) => {
    const log = this.getNftData.name;

    // TODO.MRGN-1458: Add NftList.QueuedNfts (same as NftList.UserNfts?)
    switch (tokenList) {
      // NFTs in My Wallet
      case NftList.UserNfts:
        const nftData = {
          tokenId: responseNftData?.id?.tokenId,
          contractAddress: responseNftData?.id?.contractAddress,
          nftType: responseNftData?.id?.contractNameKeyword,
          userType: responseNftData?.id?.ownership,
          owner: responseNftData?.nft?.nftOwner
        }

        switch (nftData?.nftType) {
          // Ticket NFT
          case NftType.TICKET:
            return {
              ...nftData,
              metaData: responseNftData?.nft?.nftMetaData,
              isPublic: responseNftData?.nft?.isPublicData,
            };
          // TRU-Approval NFT in My NFTs
          case NftType.TRUAPP:
            return {
              ...nftData,
              metaData: responseNftData?.nft?.nftMetaData,
              isPublic: responseNftData?.nft?.isPublicData
            };
          // Purchase Bid NFT in My NFTs
          case NftType.TRUBID:
            const nftMetaData = responseNftData?.nft?.nftMetaData?.bidData;

            // instead of showing the ID and contract address of the TRU-Approval NFT, show the simplified name
            nftMetaData.truTokenName = this.getNftName(nftMetaData?.truContractAddress, nftMetaData?.truTokenId);

            // see if another bid has been accepted on this bid's attached NFT, so we can set the bid status
            try {
              // get the data of the accepted bid
              const { bidTokenId, bidContractAddress, error } = await axiosCall({
                url: `${this.BLOCKCHAIN_PATH}/getAcceptedBid`,
                method: 'POST',
                data: {
                  tokenId: nftMetaData?.truTokenId,
                  contractAddress: nftMetaData?.truContractAddress
                }
              });

              // check if the NFT has an accepted bid
              if (!error && (bidTokenId || bidTokenId === 0) && bidContractAddress) {
                // check if the accepted bid is the same as this bid
                if (bidTokenId === nftData?.tokenId) {
                  nftMetaData.bidStatus = 'Accepted';
                } else {
                  nftMetaData.bidStatus = 'Rejected';
                }
              }
            } catch (err) {
              console.log(`${log}.1: No accepted bid found on this bid's attached NFT.`);
            }

            if (!nftMetaData?.bidStatus) {
              nftMetaData.bidStatus = 'Pending';
            }

            return {
              ...nftData,
              metaData: nftMetaData,
              isPublic: true
            };
        }
        
        break;
      // Purchase Bid NFT on a TRU-Approval NFT
      case NftList.BidsOnNft:
        const bidTokenId = responseNftData;
        const bidContractAddress = listResponse?.bidContractAddress;

        try {
          // get the rest of the data for the Bid NFT
          const { error, nftMetaData, nftOwner} = await axiosCall({
            url: `${this.BLOCKCHAIN_PATH}/getNftData`,
            method: 'POST',
            data: {
              contractAddress: bidContractAddress,
              nftType: NftType.TRUBID,
              tokenId: bidTokenId,
              publicAddress
            }
          });

          if (error) {
            throw new Error(error);
          }

          const bidMetaData = nftMetaData?.bidData;

          return {
            tokenId: bidTokenId,
            contractAddress: bidContractAddress,
            nftType: NftType.TRUBID,
            userType: null,
            owner: nftOwner,
            metaData: {
              ...bidMetaData,
              truTokenName: this.getNftName(bidMetaData?.truContractAddress, bidMetaData?.truTokenId)
            },
            isPublic: true
          };
        } catch (err) {
          console.error(`${log}.2: ${err?.message}`);
          return null;
        }
      // public NFT in Marketplace
      case NftList.PublicNfts:
        const contractAddress = responseNftData?.contractAddr;
        const tokenId = responseNftData?.nftTokenId;
        const nftType = responseNftData?.contractNameKeyword;

        // pull metadata
        try {
          const { nftMetaData, error } = await axiosCall({
            url: `${this.BLOCKCHAIN_PATH}/getNftData`,
            method: 'POST',
            data: {
              contractAddress,
              nftType,
              tokenId,
              publicAddress
            }
          });

          const metaData = nftMetaData?.truApprovalObjJson;

          if (error || !metaData) {
            throw new Error(error || `Missing metadata for token ${tokenId}`);
          }

          return {
            tokenId,
            contractAddress,
            nftType,
            userType: null,
            owner: responseNftData?.ownerAddr,
            metaData,
            isPublic: true
          };
        } catch (err) {
          console.error(`${log}.3: ${err?.message}`);
          return;
        }
    }
  }

  /**
   * @description Get the display name for an NFT, given its contract address and ID.
   * @param {string} contractAddress The contract address of the NFT.
   * @param {number} tokenId The ID of the NFT.
   */
  static getNftName = (contractAddress: string, tokenId: number) => {
    return contractAddress.slice(-6) + '-' + tokenId;
  }

  /**
   * UI Helper Functions
   */

  /**
   * @description Get the styling of the MorphWallet depending on whether we are on mobile or desktop.
   */
  static getMorphWalletStyling = () => {
    const marginSide = 20 + (isMobile() ? 0.5 : 5); // we should get this directly from the padding of the ConversationFooter
    const width = isMobile() ? window.innerWidth - marginSide * 2 - 5 : window.innerWidth < 1000 ? 700 : 900;
    const borderTopRadius = isMobile() ? 15 : 40;
    return { width, borderRadius: `${borderTopRadius}px ${borderTopRadius}px 0px 0px`, maxWidth: !isMobile() && '85%' };
  }

  /**
   * @description Check whether the chat box should be disabled for a specific page.
   * @param {Page} page The page to check.
   */
  static isChatBoxDisabled = (page: Page): boolean => {
    switch (page) {
      case Page.Welcome:
      case Page.AccessPin:
      case Page.WalletCreationCongratulations:
      case Page.LinkDeviceMobile:
      case Page.LinkDeviceMobileSuccess:
      case Page.LinkDeviceDesktop:
      case Page.PublicAddress:
      case Page.AngelPoints:
      case Page.ExchangeConfirmation:
      case Page.ExchangeCongratulations:
      case Page.SendFungible:
      case Page.SendFungibleConfirmation:
      case Page.SendFungibleCongratulations:
      case Page.SendFungibleTimeout:
      case Page.AngelMinutes:
      case Page.PurchaseAngelMinutesSuccess:
      case Page.ConvertPointsToAngelMinutes:
      case Page.ConvertPointsToAngelMinutesSuccess:
        return true;
      default:
        return false;
    }
  }

  /**
   * @description Get the link for downloading the AngelAi mobile app.
   */
  static getDownloadAppLink = () => {
    if (isMobile()) {
      const ANDROID_DOWNLOAD_URL = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.swmc.morganthinclientandroid';
      const IOS_DOWNLOAD_URL = 'https://apps.apple.com/us/app/morgan-empathetic-technology/id1667401139';

      const userDetails = UAParser();

      if (userDetails.os.name === 'Android') {
        return ANDROID_DOWNLOAD_URL;
      }
      if (userDetails.os.name === 'iOS') {
        return IOS_DOWNLOAD_URL;
      }
    }
  }

  /**
   * @description Formats number of seconds into hours, minutes, and seconds
   * @param {number} seconds The number of seconds
   * @returns {[number, number, nmber]} [hours, minutes, seconds] (seconds up to 2 decimal places)
   */
  static formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return [hours, minutes, parseFloat(remainingSeconds.toFixed(2))];
  }

  /**
   * @description Get the number as a string with a maximum number of digits after the decimal.
   * @param {number} number The number to be formatted.
   * @param {number?} digitsAfterDecimal The maximum number of digits to show after the decimal. Otherwise, default value will be used.
   */
  static getNumberFixedDisplay = (number: number, digitsAfterDecimal: number = null) => {
    return String(parseFloat(number?.toFixed(digitsAfterDecimal || 8)));
  }

  /**
   * @description Get the number as a string with the + or - sign before it.
   * @param {number | string} number The number to be formatted.
   */
  static getNumberSignDisplay = (number: number | string) => {
    return Number(number) >= 0 ? `+ ${number?.toLocaleString()}` : `\u2013 ${number?.toLocaleString()?.slice(1)}`
  }

  /**
   * @description Returns the date as a formatted string in the user's time zone. Compatible with Unix time (number) and ISO-8601 time (string).
   * @param {string | number} date The date to be returned as a formatted string.
   */
  static getFormattedDate = (date: number | string) => {
    // multiply by 1000 to convert seconds to milliseconds
    return (new Date(typeof date === 'number' ? date * 1000 : date))?.toLocaleString();
  }

  /**
   * @description Get the display name for an asset. TODO: getNftTypeDisplay should be phased out in favor of this function.
   * @param {string} assetCode The contract symbol or exchange code used by the asset.
   */
  static getAssetDisplayName = (assetCode: string): UiText => {
    switch (assetCode) {
      case AssetCode.AngelCoin:
        return UiText.AngelCoin;
      case AssetCode.AngelPoints:
        return UiText.AngelPoints;
      case AssetCode.AngelMinutes:
        return UiText.AngelMinutes;
    }
  }

  /**
   * @description Get the path of the image for an asset.
   * @param {string} assetCode The contract symbol or exchange code used by the asset.
   */
  static getAssetImagePath = (assetCode: string) => {
    switch (assetCode) {
      case AssetCode.AngelPoints:
        return 'HomeMenuButtons/AngelPoints.png';
      default:
        return `TokenIcons/${assetCode}.png`;
    }
  }

  /**
   * @description Get the exchange rate display for the exchange page, i.e., '{exchangeRate} {inputAssetDisplay} = 1 {outputAssetDisplay}'.
   * @param {string} exchangeData.inputAssetDisplay The UI display name of the input asset.
   * @param {string} exchangeData.outputAsset The contract symbol or exchange code used by the asset being received.
   * @param {string} exchangeData.outputAssetDisplay The UI display name of the output asset.
   * @param {number} exchangeData.exchangeRate The exchange rate between the input and output assets (such that [input asset amount / exchange rate = output asset amount]).
   */
  static getExchangeRateDisplay = (exchangeData: { inputAssetDisplay: string, outputAsset: string, outputAssetDisplay: string, exchangeRate: number }) => {
    // in some cases we will want to display a modified version of the exchange rate used by the back end
    const getExchangeRate = () => {
      // when the output asset is Angel Minutes, multiply the exchange rate by 60, as the back end exchange rate uses Angel Seconds
      if (exchangeData?.outputAsset === AssetCode.AngelMinutes) {
        return exchangeData?.exchangeRate * 60;
      }

      return exchangeData?.exchangeRate;
    }

    // ensure the output asset display is singular, not plural
    const getOutputAssetDisplay = () => {
      switch (exchangeData?.outputAssetDisplay) {
        case AssetCode.AngelPoints:
          return UiText.AngelPoint;
        case AssetCode.AngelMinutes:
          return UiText.AngelMinute;
        default:
          return exchangeData?.outputAssetDisplay;
      }
    }

    return `${getExchangeRate()} ${exchangeData?.inputAssetDisplay} = 1 ${getOutputAssetDisplay()}`;
  }

  /**
   * @description Get the display name for an NFT type.
   * @param {NftType} nftType The NFT type.
   */
  static getNftTypeDisplay = (nftType: NftType) => {
    switch (nftType) {
      case NftType.TRUAPP:
        return UiText.nftTRUAPP;
      case NftType.TRUBID:
        return UiText.nftTRUBID;
      case NftType.TICKET:
        return UiText.nftTICKET;
    }
  }

  /**
   * @description Get the display name for an NFT user type.
   * @param {string} userType The NFT user type.
   */
  static getNftUserTypeDisplay = (userType: NftUserType) => {
    switch (userType) {
      case NftUserType.OWNER:
        return UiText.nftuOwner;
      case NftUserType.APPROVED:
        return UiText.nftuApprovedUser;
    }
  }

  /**
   * @description Get the data for an event associated with a TICKET NFT, such as display name and date.
   * @param {string} eventName The event name as provided in the NFT's metadata.
   */
  static getEventData = async (eventName: string) => {
    const log = this.getEventData.name;

    try {
      const { data, error } = await axiosCall({
        url: `${this.BLOCKCHAIN_PATH}/getEventData/${eventName}`,
        method: 'GET'
      });

      if (error) {
        throw new Error(`Error getting data for ${eventName}.`);
      }

      return { data };
    } catch (err) {
      return { error: `${log}: ${err?.message}` };
    }
  }

  /**
   * @description Get the IDs of a group of input squares (e.g., for access PIN or recovery phrase). Defined here to avoid hardcoding IDs elsewhere.
   * @param numInputs The number of inputs.
   */
  static getInputIds = (numInputs: number) => {
    const inputIds = [];

    for (let index = 0; index < numInputs; index++) {
      inputIds.push(`input-${index}`);
    }

    return inputIds;
  }

  /**
   * @description Get the full list of tokens supported by the Angel Wallet.
   */
  static getSupportedTokens = async () => {
    const log = this.getSupportedTokens.name;

    const { error, tokens } = await axiosCall({
      url: `${this.BLOCKCHAIN_PATH}/getSupportedTokens`,
      method: 'GET'
    });

    if (error || !tokens) {
      return { error: `${log}: ${error || 'No tokens returned.'}` };
    }

    return { tokens };
  }

  /**
   * @description Get the list-view metadata preview fields for each NFT type.
   * @param {any[]} nftTypes The list of supported NFTs.
   */
  static getPreviewFields = async (nftTypes: any[]) => {
    const log = this.getPreviewFields.name;
    const previewFields = [];

    for (const nftType of nftTypes) {
      // make any manual additions to the preview fields; add continue statement to skip pulling fields from DB
      switch (nftType?.contractNameKeyword) {
        case NftType.TICKET:
          previewFields.push({ nftType: nftType?.contractNameKeyword, fields: ['eventName', 'scanned'] });
          continue;
        case NftType.TRUBID:
          previewFields.push({ nftType: nftType?.contractNameKeyword, fields: ['bidStatus', 'truTokenName'] });
          continue;
      }

      try {
        const { error, fields } = await axiosCall({
          url: `${this.BLOCKCHAIN_PATH}/getPreviewFields/${nftType?.contractNameKeyword}`,
          method: 'GET'
        });

        if (error) {
          throw new Error(`Error getting preview fields for ${nftType?.contractNameKeyword}.`);
        }

        if (fields) {
          previewFields.push({ nftType: nftType?.contractNameKeyword, fields });
        }
      } catch (err) {
        console.error(`${log}: ${err?.message}`);
        continue;
      }
    }

    return previewFields;
  }

  /**
   * @description Convert string to title case (e.g., "john doe" => "John Doe")
   * @param {string} text The string to be converted to title case.
   */
  static convertToTitleCase = (text: string) => {
    if (!text) {
      return '';
    }

    const words = text.toLowerCase().split(' ');
    words.forEach((word, index) => words[index] = word[0].toUpperCase() + word.slice(1));
    return words.join(' ');
  }

  /**
   * @description Format the number as a USD value (e.g., 1234.56 => $1,234.56)
   * @param amount The amount in USD.
   */
  static formatDollar = (amount: number | string) => {
    // audit finding F-2025-9076: use toLocaleString instead of regex, to prevent backtracking (which could lead to a DoS attack)
    return `$${amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  }

  /**
   * @description Compile the metadata of an NFT into an array for display.
   * @param {object} metaData The metadata of the NFT.
   * @param {string[]} selectedFields The fields that should be displayed; to display all fields, leave this null.
   */
  static compileMetaData = (metaData: object, selectedFields: string[]) => {
    if (!metaData) {
      return [];
    }

    /**
     * format: metaData key name = display name
     * data will appear in exact order below; rearrange as needed
     */
    enum Field {
      // general metadata
      smartContractAddress = UiText.mdSmartContractAddress,
      // TICKET metadata
      eventName = UiText.mdEventName,
      scanned = UiText.mdScanned,
      additionalGuests = UiText.mdAdditionalGuests,
      hashString = UiText.mdHashString,
      // TRUBID metadata
      bidStatus = UiText.mdBidStatus,
      truTokenName = UiText.mdTruTokenName,
      // TRUAPP metadata
      truApprovalDateAsString = UiText.mdTruApprovalDate,
      borrowerHeaderLine = UiText.mdBorrowerName,
      propCode = UiText.mdPropCode,
      propDesc = UiText.mdPropDesc,
      loanAmount = UiText.mdLoanAmount,
      purchasePrice = UiText.mdPurchasePrice,
      interestRate = UiText.mdInterestRate,
      apr = UiText.mdApr,
      ltv = UiText.mdLtv,
      cltv = UiText.mdCltv,
      productCodeDesc = UiText.mdProductCodeDesc,
      retail = UiText.mdRetail,
      term = UiText.mdTerm,
      newHazIns = UiText.mdNewHazIns,
      newTaxes = UiText.mdNewTaxes,
      newMi = UiText.mdNewMi,
      newHoa = UiText.mdNewHoa,
      newFirstMortPi = UiText.mdNewFirstMortPi,
      newOtherMortPi = UiText.mdNewOtherMortPi,
      interviewerName = UiText.mdInterviewerName,
      interviewerTitle = UiText.mdInterviewerTitle,
      interviewerEmail = UiText.mdInterviewerEmail,
      interviewerPhone = UiText.mdInterviewerPhone,
      interviewerNmlsId = UiText.mdInterviewerNmlsId,
      interviewerCompCode = UiText.mdInterviewerCompCode,
      interviewerCompAddress = UiText.mdInterviewerCompAddress,
      interviewerCompCityStateZip = UiText.mdInterviewerCompCityStateZip,
      lenderCompCode = UiText.mdLenderCompCode,
      molCompUrl = UiText.mdMolCompUrl
    }

    // perform any necessary operations on the data
    const formatData = (data: any, titleCase: boolean, formatPhone: boolean, formatDollar: boolean, formatPercent: boolean) => {
      switch (typeof data) {
        case 'string':
          if (titleCase) {
            return this.convertToTitleCase(data);
          }
    
          if (formatPhone) {
            if (data.length === 10) {
              return '(' + data.slice(0, 3) + ') ' + data.slice(3, 6) + '-' + data.slice(6);
            }
          }

          break;
        case 'number':
          if (formatDollar) {
            return this.formatDollar(data);
          }
    
          if (formatPercent) {
            return String(data) + '%';
          }

          break;
        case 'boolean':
          return data ? 'Yes' : 'No';
      }

      return data;
    }

    // get the Field enum in an array
    const fields = Object.keys(Field);
    const metaDataArr = [];

    // add field and value to metadata array
    fields.forEach((field, index) => {
      // if selectedFields provided, only return those fields for display
      if (selectedFields && !selectedFields.includes(field)) {
        return;
      }

      // if the field cannot be found in the metadata
      if (!(field in metaData)) {
        return;
      }

      const value = metaData[field];
      if (value !== null) {
        // whether to convert the value to title case, e.g., JOHN DOE to John Doe
        let titleCase = false;
        // whether to convert a 10-digit number to (123) 456-7890 format
        let formatPhone = false;
        // whether to convert a number to dollar format, with $ and commas
        let formatDollar = false;
        // whether to append % to the number
        let formatPercent = false;

        /**
         * 1. all fields will be included as long as they are in the Field enum and have a value
         * 2. only need cases below if you want to add an operation, like format phone
         * 3. to hide a field from display, add a case with a return statement
         */
        switch (Object.values(Field)[index]) {
          case Field.borrowerHeaderLine:
            titleCase = true;
            break;
          case Field.propDesc:
            titleCase = true;
            break;
          case Field.loanAmount:
            formatDollar = true;
            break;
          case Field.purchasePrice:
            formatDollar = true;
            break;
          case Field.interestRate:
            formatPercent = true;
            break;
          case Field.apr:
            formatPercent = true;
            break;
          case Field.ltv:
            formatPercent = true;
            break;
          case Field.cltv:
            formatPercent = true;
            break;
          case Field.productCodeDesc:
            titleCase = true;
            break;
          case Field.newHazIns:
            formatDollar = true;
            break;
          case Field.newTaxes:
            formatDollar = true;
            break;
          case Field.newMi:
            formatDollar = true;
            break;
          case Field.newHoa:
            formatDollar = true;
            break;
          case Field.newFirstMortPi:
            formatDollar = true;
            break;
          case Field.newOtherMortPi:
            formatDollar = true;
            break;
          case Field.interviewerName:
            titleCase = true;
            break;
          case Field.interviewerPhone:
            formatPhone = true;
            break;
          case Field.interviewerTitle:
            titleCase = true;
            break;
          case Field.interviewerCompAddress:
            titleCase = true;
            break;
          case Field.interviewerCompCityStateZip:
            titleCase = true;
            break;
          case Field.eventName:
            titleCase = true;
            break;
        }

        metaDataArr.push({
          name: Object.values(Field)[fields.indexOf(field)],
          value: formatData(value, titleCase, formatPhone, formatDollar, formatPercent)
        });
      }
    });

    return metaDataArr;
  }
}

export { MorphWalletUtils };