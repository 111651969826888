import React, { memo } from 'react';

import { QuickActionIcon } from '@assets/Icons/index';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import getImageSource from '@common/getImageSource';
import Styles from '@styles/MorphUserPromptsMenu.module.css';
import { useSwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';

const getSingleSlideElementStyle = (isLoadingElement, swiperSlide) => {
  return {
    height: '100%',
    display: 'flex',
    flexDirection: isLoadingElement ? 'row' : 'column',
    // border: props?.isLoadingElement ? 'none' : '2px solid var(--blue950)',
    boxShadow: isLoadingElement ? 'none' : `0 0 6px var(--shadow300)`,
    justifyContent: 'center',
    transition: 'all 0.3s ease',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '6px',
  } as React.CSSProperties;
};

export const SlideIcon = (props) => {
  const { t } = useTranslation();
  const imgSrc = props?.workflowIcon ? getImageSource(props.workflowIcon) : '';
  const additionalStyles = props.otherStyles ? props.otherStyles : {}
  const defStyles = { filter: 'invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)' }
  const finalStyles = props.otherStyles ? additionalStyles : defStyles;

  return imgSrc !== '' ? (
    <img
      src={imgSrc}
      style={{
        width: '1.875rem',
        height: '1.875rem',
        ...finalStyles
      }}
      alt={t('UPM_Icon_displaying_a_workflow')}
    />
  ) : (
    <QuickActionIcon style={{
      color: 'var(--gray000)', 
      width: '1.875rem',
      height: '1.875rem',
      ...finalStyles
    }} />
  );
};

// default classes for SingleSlideElement
const defaultClasses = [Styles.UserSelectNone];
const finalClasses = [Styles.SingleSlideElementHover, ...defaultClasses];

const getPStyle = (isLoadingElement) => {
  return {
    fontSize: '0.8rem',
    color: 'var(--gray000)',
    textAlign: 'center',
    lineHeight: '13px',
    overflowWrap: 'break-word',
    maxWidth: '100%',
    marginTop: '5px',
    marginLeft: isLoadingElement ? '8px' : '0px',
  } as React.CSSProperties;
};

interface Props {
  isLoadingElement?: boolean;
  workflowIcon?: string;
  name: string;
}

const SingleSlideElement = memo((props: Props) => {
  const swiperSlide = useSwiperSlide();
  // turn on hovering effect only on desktop
  let classNameStr = (props?.isLoadingElement || isMobileOrTablet() === true ? defaultClasses : finalClasses).join(' ');

  return (
    <div style={getSingleSlideElementStyle(props?.isLoadingElement, swiperSlide)} className={classNameStr}>
      {/* Icon */}
      {<SlideIcon workflowIcon={props?.workflowIcon} />}
      {/* Title */}
      <p style={getPStyle(props?.isLoadingElement)}>{props.name}</p>
    </div>
  );
});

export { SingleSlideElement };
