/**
 * @file PointsTracker.tsx
 * @description Component which displays user's points balance
 * @author Eric Velepucha
 */

import useInitialMount from '@common/hooks/useInitialMount';
import axiosCall from '@services/axios';
import { getUserId } from '@storage/userStorage';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Styles from './PointsTrackerStyles.module.css';
import { useTranslation } from 'react-i18next';
import { AngelPointsIcon } from '@assets/Icons';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import { useSocketContext } from '@contexts/socket';
import { adjustPointsBalance, selectPointsBalance, setPointsBalance } from '@slices/pointsSlice';
import { useAppDispatch, useAppSelector } from '@common/hooks/reduxHooks';
import { useRootContext } from '@contexts/RootContext';
import { useUserContext } from '@contexts/user';
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';

const soundEffect = process.env.REACT_APP_MORGAN_CDN + '/sound/CashRegister.mp3';

const PointsTracker = () => {
  // Points tracker should display the user's total points
  // It will be a permanent fixture in ConversationTitle component
  // Initial render will request Core endpoint for user's points
  // Event listeners should pick up point events
  // which will trigger the dislayed points to update
  const { t } = useTranslation();
  const { setMorphType } = useErrandContext();
  const { eventsSocket } = useSocketContext();
  const { _id } = useUserContext();
  const pointsBalance = useAppSelector(selectPointsBalance);
  const dispatch = useAppDispatch();
  const { allConsentsGiven, handleShakingConsent } = useRootContext();
  const [showPoints, setShowPoints] = useState(false);
  const [localPoints, setLocalPoints] = useState(0);
  const textRef = useRef(null);
  const [width, setWidth] = useState(0);

  // Request core (which fetches points from Sunsoft)
  const fetchPoints = useCallback(async () => {
    const req = { url: `points/${_id}` };
    return await axiosCall(req);
  }, [_id]);

  // Runs on initial component mount
  const initFunction = useCallback(async () => {
    const { balance } = await fetchPoints();
    dispatch(setPointsBalance(balance));
  }, [dispatch, fetchPoints]);

  // Opens the points table
  const handleClick = () => {
    if (!allConsentsGiven) {
      handleShakingConsent();
      return;
    }
    setMorphType(MorphType.PointsTable);
  };

  // handler function for the adjust-points-balance event
  const handleAdjustPointsBalanceEvent = useCallback(
    (payload: number) => {
      dispatch(adjustPointsBalance(payload));
      if (payload > 0) {
        new Audio(soundEffect).play();
      }
    },
    [dispatch]
  );

  // Sets the points balance on first render
  useInitialMount(initFunction);

  // On mount, show points then hide after 4s
  useEffect(() => {
    setShowPoints(true);
    const timer = setTimeout(() => setShowPoints(false), 4000);
    return () => clearTimeout(timer);
  }, []);

  // Show points when the points value changes
  useEffect(() => {
    if (textRef.current) {
      setWidth(textRef.current.offsetWidth);
      setShowPoints(true);
    }

    const timer = setTimeout(() => {
      setShowPoints(false);
      setWidth(0);
    }, 4000);
    const timer2 = setTimeout(() => setLocalPoints(pointsBalance), 350);
    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, [pointsBalance]);

  // Listen for event to update points state
  useEffect(() => {
    const socketEvent = eventsSocket.current;
    socketEvent?.on('adjust-points-balance', handleAdjustPointsBalanceEvent);

    // Clean up event listener on unmount
    return () => {
      socketEvent?.off('adjust-points-balance', handleAdjustPointsBalanceEvent);
    };
  }, [eventsSocket, handleAdjustPointsBalanceEvent]);

  // Set the points balance whenever the user._id changes
  useEffect(() => {
    initFunction();
  }, [_id, initFunction]);

  const handleMouseEnter = () => {
    if (textRef.current) {
      setWidth(textRef.current.offsetWidth);
      setShowPoints(true);
    }
  };

  const handleMouseLeave = () => {
    setWidth(0);
    setShowPoints(false);
  };

  return (
    <button
      className={Styles.wrapper}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ width: width + 35 }}
    >
      <AngelPointsIcon className={Styles.icon} />
      <Odometer
        className={`odometer odometer-auto-theme ${Styles.numberText} ${showPoints && Styles.show}`}
        value={localPoints}
        style={{ width }}
      />
      <span ref={textRef} className={Styles.hiddenText}>
        {pointsBalance.toLocaleString()}
      </span>
    </button>
  );
};

export default PointsTracker;
