import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Sanitized from './Sanitized';
import tStyles from '@styles/Typography.module.css';
import { AccessType } from '@common/AccessType';
import { getUserAudience } from '@common/msgUtils';
import Styles from '@styles/MessageSender.module.css';
import ThinClientUtils from '@common/ThinClientUtils';
import { ANGEL_SIGN_FIELD_ATTRIBUTE, parseAngelSignActionMessage } from '@common/angelSignUtils';
import { useMessageSingleContext } from '@contexts/message';
import { useErrandContext } from '@contexts/ErrandContext';
import { useParticipantContext } from '@contexts/participant';
import { useUserContext } from '@contexts/user';

const MessageSender = () => {
  const isThinClient = ThinClientUtils.isThinClient();

  const { t } = useTranslation();

  const { isUser, isOperator } = useUserContext();

  const { errand, editMessageId } = useErrandContext();

  const {
    messageSenderId,
    messageMessageType,
    messageIntendedAudience,
    messageSentByCurrentUser,
    messageActionFieldAttributeDescription,
    messageSenderNickname,
    messageSenderFirstname,
    messageSenderType,
    messageMessage,
    messageAccessType,
    messageAlignByCurrentUser,
    messageVisible,
    messageId,
  } = useMessageSingleContext();

  const { activeParticipants, userParticipants } = useParticipantContext();

  const active: boolean = useMemo(() => {
    return !!activeParticipants.find((x) => x?.userData?._id === messageSenderId);
  }, [activeParticipants, messageSenderId]);

  const audience = useMemo(() => {
    if (messageMessageType !== 'Action' || isOperator) return '';
    if (!Array.isArray(messageIntendedAudience)) return '';
    if (messageIntendedAudience.length === 0) return '';

    if (userParticipants.length === 1) return '';

    const userAudience = getUserAudience(errand.participants, messageIntendedAudience);

    if (userAudience.length === userParticipants.length) return '';

    const names = userAudience.map((p) => p.userData.firstname).filter((v, i, a) => a.indexOf(v) === i);

    if (names.length === 1) return names[0];
    if (names.length === 2) return names.join(' and ');

    const lastElement = names.pop();
    return names.join(', ') + ', and ' + lastElement;
  }, [errand.participants, messageIntendedAudience, messageMessageType, isOperator, userParticipants]);

  const baseName = useMemo(() => {
    // if there is no message sender then return an empty string so we can show Deleted User
    if (!messageSenderFirstname) return '';

    if (isUser && messageSenderType === 'Operator') {
      if (messageSenderNickname) {
        const formattedNickname = (messageSenderNickname || '').replace(/\s+/g, '').toLowerCase();
        if (formattedNickname === 'angelai') return 'angelai';
        return messageSenderNickname;
      }

      // length is only > 1 for user to ai or user to user private chats
      if (
        messageAccessType === AccessType.private &&
        Array.isArray(messageIntendedAudience) &&
        messageIntendedAudience.length > 1
      ) {
        return 'angelai';
      }
    }
    // operators will see firstnames only, users will see firstname as last resort.
    return messageSenderFirstname.toLowerCase();
  }, [
    isUser,
    messageIntendedAudience,
    messageSenderFirstname,
    messageSenderNickname,
    messageSenderType,
    messageAccessType,
  ]);

  const displayName = useMemo(() => {
    const name = baseName;

    if (!name) {
      return t('deletedUser');
    }

    if (name.replace(/\s+/g, '').toLowerCase() === 'angelai') {
      return 'AngelAi';
    }

    if (isOperator && !messageSentByCurrentUser && !active) {
      return name + ` (${t('tInactive')})`;
    }

    return name;
  }, [isOperator, baseName, active, messageSentByCurrentUser, t]);

  const isEditing = useMemo(() => {
    return editMessageId === messageId;
  }, [editMessageId, messageId]);

  // Memoize class names to avoid recomputation
  const sanitizedClassName = useMemo(() => {
    return [
      tStyles.base,
      ...(isEditing ? [tStyles.editing] : [tStyles.cMessageAuthor]),
      tStyles.fwBold,
      tStyles.mbSmallest,
      tStyles.ttCapitalize,
      ...(messageAlignByCurrentUser ? [Styles.alignByCurrentUser] : [tStyles.mrAuto]),
      ...(messageMessageType === 'Order' ? [tStyles.order] : []),
    ].join(' ');
  }, [isEditing, messageAlignByCurrentUser, messageMessageType]);

  // Memoize to avoid recalculating if conditions don't change
  const isDownloadAppMessage = useMemo(
    () => messageMessageType === 'DownloadAppMessage' && isThinClient,
    [messageMessageType, isThinClient]
  );

  const isInvalidAngelSignMessage = useMemo(
    () =>
      messageActionFieldAttributeDescription === ANGEL_SIGN_FIELD_ATTRIBUTE &&
      !parseAngelSignActionMessage(messageMessage ?? '').hashkey,
    [messageActionFieldAttributeDescription, messageMessage]
  );

  if (isDownloadAppMessage || isInvalidAngelSignMessage) {
    return <></>;
  }

  return (
    <Sanitized
      className={sanitizedClassName}
      html={`<span class="sr-only">${t('sentBy')}:</span> ${displayName}${audience ? ` → ${audience}` : ''}`}
      visible={messageVisible}
      tag="p"
    />
  );
};

export default MessageSender;
