import React from 'react';
import Styles from '../../Styles/FooterTypingAnimationWrapper.module.css';

const FooterTypingAnimationWrapper = (props) => {
  const overrideStyle = props?.overrideStyle ? { ...props?.overrideStyle } : undefined;

  if (!props.isShown) return null;
  return (
    <div className={Styles.wrapper} style={overrideStyle} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export { FooterTypingAnimationWrapper };
