/*
This component renders a prompt in the Conversation Footer to ask the user for consent for humans to monitor their chats.
In the future, this component will need to be generalized to match any other text that uses this format.
*/
import React, { useState, useCallback, useMemo, useEffect, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import Styles from '@styles/ConsentBox.module.css';
import { MorganTheme, useTheme } from '@mui/material';
import axiosCall from '@services/axios';
import { useErrandContext } from '@contexts/ErrandContext';
import { useUserContext } from '@contexts/user';
import { useRootContext } from '@contexts/RootContext';
import CheckIcon from '@mui/icons-material/Check';
import { MorphType } from '@common/MorphType';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import { SendMessage } from '@assets/Icons';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { translateToLocalLang } from '@common/common';
import Sanitized from './Sanitized';
import useControllerCallback from '@common/hooks/useControllerCallback';
import { welcomeMorphTypes } from '@common/welcomeMorphTypes';
import { getCloseWalletConsent } from '@storage/userStorage';

type TConsentContentProps = {
  fieldAttributeDescription: string;
  inputContainerRef: MutableRefObject<null | HTMLDivElement>;
};

const ConsentBox = ({ fieldAttributeDescription, inputContainerRef }: TConsentContentProps) => {
  const { setConsentChecked, returnConsentGiven, closeWalletConsentGiven, shakingConsent, clickedDisabledFeatures } = useRootContext();

  const { morphType } = useErrandContext();

  const { t, i18n } = useTranslation();
  const theme: MorganTheme = useTheme();
  const { tpConsentGiven } = useUserContext();
  const [tpConsentText, setTpConsentText] = useState(null);
  const [returnConsentText, setReturnConsentText] = useState(null);
  const [closeWalletConsentText, setCloseWalletConsentText] = useState(null);
  const [isChecked, setIsChecked] = useState(true);
  const [hasFadedIn, setHasFadedIn] = useState(false);

  const isWelcomeMorphType = useMemo(() => 
  welcomeMorphTypes.includes(morphType), [morphType]);

  // Slotmachine morphtype
  const isSlotMachine = useMemo(() => morphType === MorphType.SlotMachine, [morphType]);

  // These morphtypes have a short placeholder so that even
  // small width devices will not add an extra line. In these cases,
  // the consent does not need to be so far up
  const shortPlaceholderMorphTypes = useMemo(() => [
    MorphType.DOB,
    MorphType.CalendarMonth,
    MorphType.Time
  ].includes(morphType), [morphType])

  const inputContainerHeight = inputContainerRef.current?.getBoundingClientRect().height || 71

  // On load, load the terms from db
  const loadAndSetTerms = useCallback(
    async (abortController: AbortController) => {
      //Retrieve terms from database
      async function setTerms() {
        const loadTermsData = async () => {
          const lenderName = theme.system.name;
          try {
            const result = await axiosCall(
              {
                url: `system/?lender=${lenderName}`,
                method: 'GET',
                data: null,
              },
              {
                signal: abortController.signal,
              }
            );
            if (result) {
              console.log('ConsentBox-loadTermsData: System Settings Fetched. Results Below...', result?.tpConsent);
              let tpConsentText = result?.tpConsent;
              let returnConsentText = result?.returnConsent;
              let closeWalletConsentText = result?.closeWalletConsent;

              // Set the tpConsent text
              if (tpConsentText) {
                const { translatedMessage } = await translateToLocalLang(tpConsentText);
                if (translatedMessage) {
                  setTpConsentText(translatedMessage ? translatedMessage : result?.tpConsent);
                } else {
                  setTpConsentText(result?.tpConsent);
                }
              }

              // Set the returnConsent text
              if (returnConsentText) {
                const { translatedMessage } = await translateToLocalLang(returnConsentText);
                if (translatedMessage) {
                  setReturnConsentText(translatedMessage ? translatedMessage : result?.returnConsent);
                } else {
                  setReturnConsentText(result?.returnConsent);
                }
              }

              // Set the closeWalletConsent text
              if (closeWalletConsentText) {
                const { translatedMessage } = await translateToLocalLang(closeWalletConsentText);
                if (translatedMessage) {
                  setCloseWalletConsentText(translatedMessage ? translatedMessage : result?.closeWalletConsent);
                } else {
                  setCloseWalletConsentText(result?.closeWalletConsent);
                }
              }
            }
          } catch (error) {
            console.log('ConsentBox-loadTermsData: Error retrieving Terms Data', error);
          }
        };
        await loadTermsData();
      }
      setTerms();
    },
    [theme.system.name, i18n.language]
  );

  const checkTheBox = useCallback(() => {
    setIsChecked(true);
    setConsentChecked(true);
  }, [setConsentChecked]);

  const uncheckTheBox = useCallback(() => {
    setIsChecked(false);
    setConsentChecked(false);
  }, [setConsentChecked]);

  useControllerCallback(loadAndSetTerms);

  useEffect(() => {
    if (clickedDisabledFeatures && !hasFadedIn) {
      // Delay disabling fade to ensure animation runs
      const timer = setTimeout(() => setHasFadedIn(true), 1000); // Match animation duration
      return () => clearTimeout(timer);
    }
  }, [clickedDisabledFeatures, hasFadedIn]);

  if (tpConsentGiven && returnConsentGiven && closeWalletConsentGiven) return null;

  // Here is where we handle situations where we want the consent to appear upon loading
  // even without the user triggering it (through clicking on disabled features or typing)
  if (!clickedDisabledFeatures && fieldAttributeDescription !== 'BOOLEAN' && 
    fieldAttributeDescription !== 'DROPDOWN' && !isWelcomeMorphType) return null;
    
  return (
    <section className={[Styles.wrapper, 
    ...(shakingConsent ? [Styles.shaking] : []),
    ...(hasFadedIn ? [Styles.noFade] : [])
    ].join(' ')}>
      <div
        className={[
          Styles.content,
          ...(isWelcomeMorphType ? [Styles.welcomeContent] : []),
          ...(isWelcomeMorphType && inputContainerHeight < 90 ? [Styles.singleLine] : [Styles.doubleLine]),
          ...(isWelcomeMorphType && shakingConsent ? [Styles.welcomeShaking] : []),
          ...(isSlotMachine && shakingConsent ? [Styles.slotMachineShaking] : []),
          ...(shortPlaceholderMorphTypes ? [Styles.lowerHeight] : []),
          ...(isSlotMachine ? [Styles.slotMachineContent] : []),
        ].join(' ')}
      >
        <>
          {
            tpConsentGiven && (!closeWalletConsentGiven || getCloseWalletConsent() === 'True') ? 
            (
              <>
                {tpConsentGiven && (!closeWalletConsentGiven || getCloseWalletConsent() === 'True') && isChecked ? (
                  <span onClick={uncheckTheBox}>
                    <CheckIcon className={Styles.checkContainer} />
                  </span>
                ) : (
                  <div className={Styles.uncheck} onClick={checkTheBox}></div>
                )}
                {isMobileOrTablet() ? t('byTapping') : t('byClicking')}
                {dynamicConsentButton(fieldAttributeDescription, t)}
                <Sanitized html={closeWalletConsentText} />
              </>
            )
            : tpConsentGiven && closeWalletConsentGiven && !returnConsentGiven ? 
            (
              <>
                {tpConsentGiven && !returnConsentGiven && isChecked ? (
                  <span onClick={uncheckTheBox}>
                    <CheckIcon className={Styles.checkContainer} />
                  </span>
                ) : (
                  <div className={Styles.uncheck} onClick={checkTheBox}></div>
                )}
                {isMobileOrTablet() ? t('byTapping') : t('byClicking')}
                {dynamicConsentButton(fieldAttributeDescription, t)}
                <Sanitized html={returnConsentText} />
              </>
            ) : (
              <>
                {!tpConsentGiven && isChecked ? (
                  <span onClick={uncheckTheBox}>
                    <CheckIcon className={Styles.checkContainer} />
                  </span>
                ) : (
                  <div className={Styles.uncheck} onClick={checkTheBox}></div>
                )}
                {isMobileOrTablet() ? t('byTapping') : t('byClicking')}
                {dynamicConsentButton(fieldAttributeDescription, t)}
                <Sanitized html={tpConsentText} />
              </>
            )
          }
        </>
      </div>

      <div
        className={[
          Styles.connector,
          ...(isWelcomeMorphType ? [Styles.welcomeConnector] : []),
          ...(isSlotMachine ? [Styles.slotMachineConnector] : []),
          ...(fieldAttributeDescription === 'BOOLEAN' ? [Styles.booleanConnector] : []),
        ].join(' ')}
      />
    </section>
  );
};

export default ConsentBox;

const dynamicConsentButton = (fieldAttributeDescription, t) => {
  if (fieldAttributeDescription === 'BOOLEAN') {
    return (
      <div className={Styles.booleanIcon}>
        <span className={Styles.yesButton}> {t('yes')} </span>
        <HorizontalRuleIcon className={Styles.slash} />
        <span className={Styles.noButton}> {t('no')} </span>
      </div>
    );
  } else {
    return (
      <div className={Styles.sendArrowCircle}>
        <SendMessage height={'10px'} width={'10px'} className={isMobileOrTablet() && Styles.mobileSend} />
      </div>
    );
  }
};
