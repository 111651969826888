import React, {PropsWithChildren, useState, useCallback, useMemo } from 'react';
import { Stack} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRootContext } from '@contexts/RootContext';
import { MorphType } from '@common/MorphType';
import Styles from '@styles/ErrandsFooter.module.css';
import { Delete } from '@mui/icons-material';
import { SplitScreenIcon, SingleScreenIcon, PromptsIcon, ArchiveIcon, SettingsGearIcon } from '@assets/Icons';
import { UserWayAccessibilityMenu } from '@components/UserWayAccessibilityMenu';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import ThinClientUtils from '@common/ThinClientUtils';
import { useUserContext } from '@contexts/user';

const newErrandIcon = process.env.REACT_APP_MORGAN_CDN + '/Images/newErrandIcon.svg';

const ErrandsFooter: React.FC<PropsWithChildren<any>> = (props: any) => {
  const { checkedErrands, setCheckedErrands, setShowUserPromptList } = props;
  const { t } = useTranslation();
  const {
    rootMorphType,
    setRootMorphType,
    drawerRef,
    morphedId,
    fetchClosedChats,
    selectedIndex,
    setSelectedIndex,
    chatBulkClose,
    errands,
    allConsentsGiven,
    handleShakingConsent,
    clickedDisabledFeatures
  } = useRootContext();
  const [isHoveringAccess, setIsHoveringAccess] = useState(false);
  const [isHoveringMenu, setIsHoveringMenu] = useState(false);

  const errandsLength = useMemo(() => {return errands.length;}, [errands]);

  const isShowingSplitScreenIcon = useMemo(() => !isMobileOrTablet() && errandsLength > 1, [errandsLength]);

  const handleSplitScreen = useCallback(() => {

    // 5/4/2023 - bold: splitscreen removed
    if (selectedIndex.length === 1) {
      if (selectedIndex[0] !== 0) {
        // if the currently selected chat is not the main chat, set it to the main chat
        // set the secodnary chat to the old primary chat
        // set the selected indexes as needed
        setSelectedIndex((prev) => [0, prev[0]]);
      } else if (errandsLength > 1) {
        // set the secondary chat to the first errand
        // console.log({'props': props})
        setSelectedIndex([0, 1]);
      }
    } else if (selectedIndex.length === 2) {
      // remove splitscreen, so set the chatId to the main conversation
      // set the main conversation selector to the 0
      setSelectedIndex([0]);

      // remove previous errand messages
      // remove errand id
    } else {
      console.error("selectedIndex has more than 2 elements!");
    }
  }, [selectedIndex, setSelectedIndex, errandsLength]);;

  const handleOpenErrandNew = useCallback(() => {
    if (!allConsentsGiven){
      handleShakingConsent();
      return
    } else {
      // hide the errands drawer on mobile devices. On desktop it is always visible
      drawerRef.current?.click();
      setRootMorphType((prev): MorphType => {
        // Get the ID of the errand that is currently selected or use the id of the primary errand if split screen
        // so that we know which screen to have the footer be morphed on
        const errand = errands[selectedIndex[selectedIndex.length - 1]];
        if (!errand) return prev;
        const primaryErrand = errands[0];
        if (!primaryErrand) return prev;
        morphedId.current = selectedIndex.length === 2 ? primaryErrand._id : errand._id;
        if (prev === MorphType.ErrandNew) {
          return MorphType.None;
        }
        return MorphType.ErrandNew;
      });
    }
  }, [drawerRef, errands, handleShakingConsent, morphedId, selectedIndex, setRootMorphType, allConsentsGiven, clickedDisabledFeatures]);

  const handleOpenClosedErrands = useCallback(async () => {
    if (!allConsentsGiven){
      handleShakingConsent();
      return;
    }

    // hide the errands drawer on mobile devices. On desktop it is always visible
    drawerRef.current?.click();

    await fetchClosedChats();

    setRootMorphType((prev): MorphType => {
      // Get the ID of the errand that is currently selected or use the id of the primary errand if split screen
      // so that we know which screen to have the footer be morphed on
      const errand = errands[selectedIndex[selectedIndex.length - 1]];
      if (!errand) return prev;
      const primaryErrand = errands[0];
      if (!primaryErrand) return prev;
      morphedId.current = selectedIndex.length === 2 ? primaryErrand._id : errand._id;
      if (prev === MorphType.Errand) {
        return MorphType.None;
      }
      return MorphType.Errand;
    });
  }, [drawerRef, errands, handleShakingConsent, fetchClosedChats, morphedId, selectedIndex, setRootMorphType, allConsentsGiven, clickedDisabledFeatures]);

  const handleThinClientSettings = useCallback(async () => {
    window.dispatchEvent(
      new CustomEvent('openPreferencesEvent', {
        detail: {
          key: 'openPreferencesEvent',
        },
      })
    );
  }, []);
  
  const handleUserPrompt = useCallback(() => {
    if (!allConsentsGiven){
      handleShakingConsent();
      return;
    };

    setShowUserPromptList(true);
  }, [setShowUserPromptList, allConsentsGiven, handleShakingConsent, clickedDisabledFeatures]);

  const closeCheckedErrands = useCallback(async (selectedErrands) => {
    try {
      if (!allConsentsGiven) {
        handleShakingConsent();
        return
      }

      if (selectedErrands.length > 0) {
        chatBulkClose(selectedErrands);

        setCheckedErrands([]);
      } else {
        handleOpenErrandNew();
      }
    } catch (err) {
      console.error('ErrandsFooter.tsx: closeCheckedErrands()', err);
    }
  }, [setCheckedErrands, chatBulkClose, handleOpenErrandNew, handleShakingConsent, allConsentsGiven, clickedDisabledFeatures]);

  return (
    <Stack className={Styles.errandsFooterContainer}>
      <Stack
        className={Styles.errandsHoverBox}
        onMouseOver={() => setIsHoveringMenu(true)}
        onMouseOut={() => setIsHoveringMenu(false)}
      >
        <div className={Styles.centerContainer}>
          <div className={[Styles.circle,
          ...((!allConsentsGiven) ? [Styles.disableCircle] : []),
          ...((checkedErrands.length ? [Styles.bordered] : []))
        ].join(' ')
          
          } onClick={() => closeCheckedErrands(checkedErrands)}>
            {checkedErrands.length ? (
              <Delete
                className={
                  rootMorphType === MorphType.ErrandNew
                    ? Styles.cross
                    : Styles.plus
                }
              />
            ) : (
              <img
                src={newErrandIcon}
                className={[Styles.add,
                    ...(rootMorphType === MorphType.ErrandNew ? Styles.cross : Styles.plus),
                    ...((!allConsentsGiven) ? [Styles.disable] : [])
                  ].join(' ')
                }
              />
            )}
          </div>
        </div>
        <div className={Styles.buttonsContainer}>
          {isShowingSplitScreenIcon && (
            <label
              className={`${Styles.label} ${isHoveringMenu ? Styles.moveUp : Styles.moveDown}`}
              onClick={handleSplitScreen}
            >
              {selectedIndex.length === 2 ? <SingleScreenIcon height={26} /> : <SplitScreenIcon height={26} />}
              <p className={isHoveringMenu ? Styles.moveUp : Styles.moveDown}>
                {selectedIndex.length === 2
                  ? t('conversationErrandsFooterSingle')
                  : t('conversationErrandsFooterSplit')}
              </p>
            </label>
          )}
          <label
            className={[Styles.label,
              ...(isHoveringMenu ? [Styles.moveUp] : [Styles.moveDown]),
              ...((!allConsentsGiven) ? [Styles.disable] : [])
            ].join(' ')}            
            onClick={handleOpenClosedErrands}
          >
            <ArchiveIcon height={26} className={(!allConsentsGiven) ? Styles.disable : ''}/>
            <p className={isHoveringMenu ? Styles.moveUp : Styles.moveDown}>{t('tArchive')}</p>
          </label>
          <label
            className={[Styles.label,
              ...(isHoveringMenu ? [Styles.moveUp] : [Styles.moveDown]),
              ...((!allConsentsGiven) ? [Styles.disable] : [])
            ].join(' ')}
            onClick={handleUserPrompt}
          >
            <PromptsIcon height={26} className={(!allConsentsGiven) ? Styles.disable : ''}/>
            <p className={isHoveringMenu ? Styles.moveUp : Styles.moveDown}>{t('userPromptListTitle')}</p>
          </label>

          <label
            className={`${Styles.label} ${isHoveringMenu ? Styles.moveUp : Styles.moveDown}`}
            onMouseOver={() => setIsHoveringAccess(true)}
            onMouseOut={() => setIsHoveringAccess(false)}
          >
            {isMobileOrTablet() ? (
              <UserWayAccessibilityMenu color="inherit" height={26} />
            ) : isHoveringAccess ? (
              <UserWayAccessibilityMenu color="inherit" height={26} />
            ) : (
              <UserWayAccessibilityMenu color="inherit" height={26} />
            )}
            <p className={`${isHoveringMenu ? Styles.moveUp : Styles.moveDown}`}>
              {isMobileOrTablet() ? t('tAccess') : t('tAccessibility')}
            </p>
          </label>
          {ThinClientUtils.isThinClient() && (
            <label
              className={`${Styles.label} ${isHoveringMenu ? Styles.moveUp : Styles.moveDown}`}
              onClick={handleThinClientSettings}
            >
              <SettingsGearIcon />
              <p className={isHoveringMenu ? Styles.moveUp : Styles.moveDown}>{t('tSettings')}</p>
            </label>
          )}
        </div>
      </Stack>
    </Stack>
  );
};

export default ErrandsFooter;
