import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FooterTypingAnimationWrapper } from './FooterTypingAnimationWrapper';
import { useErrandContext } from '@contexts/ErrandContext';
import { Letters } from './TypingAnimation';
import { _localStorageTools } from '@components/MessageContent/WelcomeUserMessageContent/localStorageTools';
import eventBus from '@common/eventBus';
import { ChatInputFieldEvents } from '@components/ChatInputField';

const markAsShown = () => _localStorageTools.setInLocalStorage(_localStorageTools.KEYS.WERE_SUGGESTIONS_ALREADY_SHOWN_KEY, JSON.stringify(true));

const MultipleFooterTypingAnimations = ({
    items = [],
    speed = 60,
    delay = 1000,
    overrideStyle = null,
    customOnWrapperClickHandler = null,
    lang = null
}) => {
    const [text, setText] = useState(items[0]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTyping, setIsTyping] = useState(true);
    const [charIndex, setCharIndex] = useState(0);
    const [completed, setCompleted] = useState(false); // Track if cycle is completed
    const tmIdsRef = useRef({ delayTmID: null, typingTmID: null, eraseTmID: null });
    const errandContext = useErrandContext();

    const clearAllTimeouts = useCallback(() => {
        clearTimeout(tmIdsRef.current.delayTmID);
        clearTimeout(tmIdsRef.current.typingTmID);
        clearTimeout(tmIdsRef.current.eraseTmID);
    }, [])

    // on mount
    // mark as shown already
    useEffect(() => {
        markAsShown();
    }, []);

    // Main Typing Animation UseEffect
    useEffect(() => {
        // Stop if the cycle is complete
        if (completed || errandContext.multipleFooterTypingAnimations === null) {
            clearAllTimeouts();
            return;
        }
        // Typing phase: just manage the charIndex increment
        if (isTyping) {
            if (charIndex < items[currentIndex].length) {
                const timeoutId = setTimeout(() => {
                    setCharIndex(charIndex + 1); // Move to the next character
                }, speed);
                tmIdsRef.current.typingTmID = timeoutId;
                return () => clearTimeout(timeoutId);
            } else {
                if(delay === -1) return;
                // After typing is done, wait for delay and switch to erasing phase
                const tmID = setTimeout(() => {
                    setIsTyping(false); // Transition to erasing phase
                    setCharIndex(0); // Reset charIndex for the next text
                }, delay); // Delay after typing finishes before erasing
                tmIdsRef.current.delayTmID = tmID;
                return () => clearTimeout(tmID);
            }
        } else {
            const tmID = setTimeout(() => {
                // Erasing phase: immediately clear and move to the next text
                if (currentIndex + 1 < items.length) {
                    const newIdx = (currentIndex + 1) % items.length;
                    setText(items[newIdx]);
                    setCurrentIndex(newIdx);
                    errandContext.setMultipleFooterTypingAnimations((prev) => {
                        return {
                            ...prev,
                            currentActiveItem: newIdx,
                        }
                    });

                    setIsTyping(true); // Start typing the next text
                } else {
                    // If all texts have been animated, mark the cycle as completed
                    errandContext.setMultipleFooterTypingAnimations((prev) => {
                        return {
                            ...prev,
                            currentActiveItem: -1,
                        }
                    });
                    setCompleted(true); // Mark as complete
                }
            }, 100);
            tmIdsRef.current.eraseTmID = tmID;

            return () => clearTimeout(tmID);
        }

    }, [
        charIndex, 
        isTyping, 
        items, 
        currentIndex, 
        speed, 
        delay, 
        completed, 
        errandContext.multipleFooterTypingAnimations, 
        clearAllTimeouts
    ]);

    // On Completed: erase MultipleFooterTypingAnimations state to unmount the component & clear all timeoutes
    useEffect(() => {
        if (completed) {
            clearAllTimeouts();
            if (errandContext.multipleFooterTypingAnimations !== null)
                errandContext.setMultipleFooterTypingAnimations((prev) => null);
        }
    }, [completed, errandContext.multipleFooterTypingAnimations])

    const onWrapperClick = () => {
        setCompleted(true);
        if(customOnWrapperClickHandler) {
            setTimeout(() => {
                customOnWrapperClickHandler();
            }, 0)
        } else {    
            setTimeout(() => {
                eventBus.dispatch(ChatInputFieldEvents.FORCE_FOCUS, null);
            }, 100);
        }
    }

    if (completed) {
        return null;
    }

    return (
        <FooterTypingAnimationWrapper overrideStyle={overrideStyle}  isShown={!completed} onClick={onWrapperClick}>
            <div>
                <Letters string={text} shownCount={charIndex} lang={lang} />
            </div>
        </FooterTypingAnimationWrapper>
    );
};

export default MultipleFooterTypingAnimations;
