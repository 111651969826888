import LanguageUtils from '@common/LanguageUtils';
import { ValidatorFunctions } from '@common/Validators';
import { fetchWorkflows } from './api';
import { TWorkflowDataFetched_UPmenu, TWorkflowData_UPmenu } from './types';
import { getDisplayNameFrom, parseFetchedWorkflowsData, removeQuotesAndSpaces } from './utils';
import eventBus from '@common/eventBus';
import { translationTools } from '@components/MessageContent/WelcomeUserMessageContent/utils';

export type TLang = 'es' | 'ko';

const DEBUG = false;
const _debugLog = (...args) => DEBUG && console.log(...args);

class CTranslationModule {
  _translations: Map<string, string> = new Map();

  _set(key: string, data: string): void {
    this._translations.set(key, data);
  }

  _clear(key: string): boolean {
    return this._translations.delete(key);
  }

  get(key: string): string | undefined {
    return this._translations.get(key);
  }

  getAllTranslations() {
    return this._translations;
  }

  _prepareWfArray = (wfArr: TWorkflowData_UPmenu[]) =>
    wfArr.map((data) => ({ message: data?.name?.en ?? '', source: 'en' }));

  getTranslations = async (wfArr: TWorkflowData_UPmenu[], lang, abortController, withMapping = null): Promise<string[]> => {
    _debugLog('🌐 === getTranslations ====')
    const preparedArr = this._prepareWfArray(wfArr).map((wfArr) => wfArr.message); // [string] only! translateArrOfStr will take care of source and other attributes
    _debugLog('🌐 preparedArr', preparedArr)
    return await translationTools.translateArrOfStr(preparedArr, lang, abortController, withMapping); // make sure all the translations are cached to not to refetch the same translations all over again.
  };

  getWfKey(wf: TWorkflowDataFetched_UPmenu | TWorkflowData_UPmenu) {
    return removeQuotesAndSpaces(getDisplayNameFrom(wf));
  }

  processLanguage = async (abortController, targetLang: TLang, withMapping = null) => {
    // workflows data
    let wfDataArr;
    try {
      wfDataArr = await fetchWorkflows(abortController);
      _debugLog('🌐 Fetched Workflows', wfDataArr)
    } catch (error) {
      console.error('Error during fetchWorkflows call in processLanguage method! Error:', error);
    }

    try {
      if (
        ValidatorFunctions.isUndefinedOrNull(wfDataArr) === true ||
        ValidatorFunctions.isTypeOfArray(wfDataArr) === false ||
        wfDataArr.length === 0
      )
        return;

      const parsedWfArr = parseFetchedWorkflowsData(wfDataArr);
      _debugLog('🌐 parsedWfArr', parsedWfArr)
      const resTranslations = await this.getTranslations(parsedWfArr, targetLang, abortController, withMapping);
      _debugLog('🌐 resTranslations', resTranslations)
      // check length to be the same
      if (parsedWfArr.length !== Object.keys(resTranslations).length) {
        console.error('fetched translations and wfArray are not equal in length!');
        return;
      }
      // save to translations storage.
      for (let i = 0; i < parsedWfArr.length; i++) {
        const currWf = parsedWfArr[i];
        _debugLog('🌐 this.getWfKey(currWf), resTranslations[i], currWf', this.getWfKey(currWf), resTranslations[this.getWfKey(currWf)], currWf)
        const _wfKey = this.getWfKey(currWf);
        this._set(_wfKey, resTranslations[_wfKey]);
      }

      // emit that the lang translations are processed.
      eventBus.dispatch('UPM_translationsProcessed', {});
    } catch (error) {
      console.error(
        'Error during parseFetchedWorkflowsData or getTranslations call in processLanguage method! Error:',
        error
      );
    }
  };
}

const UPM_TranslationModule = new CTranslationModule();

export default UPM_TranslationModule;
