import { ValidatorFunctions } from '@common/Validators';
import { TTranslationsData, TWorkflowDataFetched_UPmenu, TWorkflowData_UPmenu } from './types';

type UserStatus = { accountExists: boolean; authenticated: boolean };
// user status meaning (authenticated OR accountExists)
export const getUserStatus = (userData): UserStatus => {
  // userId OR webUserID
  const accountExists = Boolean(userData?.userId || userData?.webUserId);
  const authenticated = Boolean(userData?.ssAccessToken);

  return {
    accountExists,
    authenticated,
  };
};
export const filterByStatus = (status: UserStatus, wfDataParsedArr: TWorkflowData_UPmenu[]) => {
  const resWfDataArr = wfDataParsedArr.filter((wfData, index) => {
    if (status.accountExists === true) {
      // HANDLE ACCOUNT EXISTS CASE
      // see if the exclude workflow is the curr element, if so, return false (exclude it)
      const excludeAccountBoolArr = excludeNamesByStatus.accountExists.map((excludeWfName) => {
        // Go through each exclude names from the accountExists exclude names array AND see if the curr wf is related to it.
        // If so, exclude it by returning false.
        return wfData.displayName.includes(excludeWfName) || wfData.name.en.includes(excludeWfName);
      });
      if (excludeAccountBoolArr.some((val) => val === true)) return false;
    }

    if (status.authenticated === true) {
      // HANDLE AUTHENTICATED CASE
      // see if the exclude workflow is the curr element, if so, return false (exclude it)
      const excludeAuthBoolArr = excludeNamesByStatus.authenticated.map((excludeWfName) => {
        // Go through each exclude names from the accountExists exclude names array AND see if the curr wf is related to it.
        // If so, exclude it by returning false.
        return wfData.displayName.includes(excludeWfName) || wfData.name.en.includes(excludeWfName);
      });
      if (excludeAuthBoolArr.some((val) => val === true)) return false;
    }

    return true;
  });

  return resWfDataArr;
};

// Add more workflows to exclude BASED on status (authenticated or Has account or any other new one that would emerge in the future)
const excludeNamesByStatus = {
  accountExists: ['New User'],
  authenticated: ['Sign In'],
};

// contains the names of the workflows in the desired order to be shown in user prompts menu component
const cleanNamesOrderArr = [
  'New User',
  'Sign In',
  'Language Selector',
  'Workshop Registration',
  'Get Mortgage Approval',
  'Invite Friend',
  'Free T-shirts',
  'Influencer Sign Up',
  'Get Open Conditions',
].map(cleanString);

function cleanString(inputString) {
  // Remove spaces and special characters using regex, and convert to lowercase
  const resultString = inputString
    .replace(/[^\w\s]/gi, '')
    .replace(/\s/g, '')
    .toLowerCase();

  return resultString;
}

export function isFirstDecimalDigitCompareThan(number: number, compareVal: number, compareSign: string): boolean {
  try {
    // Convert the number to a string to access the first decimal digit
    const numberStr = new Number(number).toString();
    // does not have .
    if (numberStr.includes('.') === false) {
      return false;
    }
    const numberStrRightSide = numberStr.split('.')[1];
    if (ValidatorFunctions.isUndefinedOrNull(numberStrRightSide) === true) {
      return false;
    }
    // has . in str
    let digitRightSideStr = new String(numberStrRightSide);
    // in case if no right side OR 0 or not string
    if (
      ValidatorFunctions.isUndefinedOrNull(digitRightSideStr) === true ||
      ValidatorFunctions.isTypeOfString(digitRightSideStr) === false
    ) {
      return false;
    }
    digitRightSideStr = digitRightSideStr[0];

    const firstDecimalDigitNumber = parseInt(digitRightSideStr.toString(), 10);
    if (isNaN(firstDecimalDigitNumber) === true) {
      return false;
    }
    // Check if the first decimal digit is greater than given compareVal
    if (compareSign === '>') {
      return firstDecimalDigitNumber > compareVal;
    } else {
      return firstDecimalDigitNumber < compareVal;
    }
  } catch (err) {
    console.error(
      `Error occured during isFirstDecimalDigitGreaterThan execution! Arguments: number is ${number}, compareVal is ${compareVal}`,
      err
    );
    return false;
  }
}

// returns new array with reordered parsed workflows
const reorderWorkflows = (workflows: TWorkflowData_UPmenu[]): TWorkflowData_UPmenu[] => {
  // return same if not arr OR empty OR one el
  if (Array.isArray(workflows) === false || workflows.length <= 1) return workflows;

  const orderedWorkflowList = [];
  const cleanNamesOrderArrCopy = [...cleanNamesOrderArr];
  const workflowsArrCopy = [...workflows];
  const workflowsCleanNames = workflowsArrCopy.map((_wf) => cleanString(_wf.name.en));

  while (cleanNamesOrderArrCopy.length !== 0) {
    const currOrderName = cleanNamesOrderArrCopy.shift();
    // find if there is such name present among workflows names
    const wfIdx = workflowsCleanNames.findIndex((_wfName) => _wfName.includes(currOrderName));
    // if found, push to res to the end
    if (wfIdx !== -1) {
      orderedWorkflowList.push(workflowsArrCopy[wfIdx]);
      workflowsArrCopy.splice(wfIdx, 1);
      workflowsCleanNames.splice(wfIdx, 1);
    }
  }

  // spread res and then what's left in workflowsArrCopy
  return [...orderedWorkflowList, ...workflowsArrCopy];
};

function swap(arr, index1, index2) {
  // Check if both indexes are within the bounds of the array
  if (index1 >= 0 && index1 < arr.length && index2 >= 0 && index2 < arr.length) {
    // Perform the swap
    let temp = arr[index1];
    arr[index1] = arr[index2];
    arr[index2] = temp;
  } else {
    console.log('Invalid indexes');
  }
}

// Just after all insertions, find the appraisal and last occurence of calculator AND swap
const reorderCalculators = (workflows: TWorkflowData_UPmenu[]): TWorkflowData_UPmenu[] => {
  // find all calculators indexes
  const calcIdxes = [];
  let appraisalIdx = null;
  for (let i = 0; i < workflows.length - 1; i++) {
    const workflow = workflows[i];
    if (cleanString(workflow.name.en).includes('calculator') === true) {
      calcIdxes.push(i);
    }

    if (cleanString(workflow.name.en).includes('appraisal') === true) {
      appraisalIdx = i;
    }
  }

  // calcIdxes : [4, 5, 6, 7]
  // appraisal: 4
  // swap
  swap(workflows, calcIdxes[calcIdxes.length - 1], appraisalIdx);
  // swap last calcIndexElement with appraisalIndexInArr element.
  // after swap
  // calcIdxes : [7, 5, 6, 4] <--- calcs
  // copy to another array as elements
  const calcsWorkflowsCpyArr = [];
  // go through calc indexes arr AND push wf object to copy arr AND delete from original arr.
  for (const calcIdx of calcIdxes) {
    calcsWorkflowsCpyArr.push({ ...workflows[calcIdx] });
  }
  // remove from wf arr all the extracted elements by indexes
  let corrector = 0;
  for (const calcIndex of calcIdxes) {
    workflows.splice(calcIndex - corrector, 1);
    corrector++;
  }
  // find mortgage approval element idx
  const mortgageNameId = cleanNamesOrderArr[4];
  let insertAfterIndex = null;
  // go through all wfs
  for (let i = 0; i < workflows.length - 1; i++) {
    const currWf = workflows[i];
    // get clean name id of current workflow
    const currWfCleanName = cleanString(currWf.name.en);
    // if its a morgage approval workflow
    if (currWfCleanName.includes(mortgageNameId) === true) {
      insertAfterIndex = i;
    }
  }
  // insert after that index
  // Split the array into two parts and insert the element in between
  const firstPart = workflows.slice(0, insertAfterIndex + 1);
  const lastPart = workflows.slice(insertAfterIndex + 1);
  const middlePart = calcsWorkflowsCpyArr;

  return [...firstPart, ...middlePart, ...lastPart];
};

// extracts proper name string from workflow data.
// if there is displayName ---> then use it
// else if there is name ---> use it if its string
//                           if not string but an object, get en translation of it and use as a final name
// else empty string will be returned
export const getDisplayNameFrom = (workflowData: TWorkflowDataFetched_UPmenu | TWorkflowData_UPmenu) => {
  let finalNameStr = '';

  if (ValidatorFunctions.isNotUndefinedNorNull(workflowData?.displayName) && workflowData?.displayName !== '')
    finalNameStr = workflowData.displayName;
  else if (ValidatorFunctions.isNotUndefinedNorNull(workflowData?.name)) {
    if (typeof workflowData?.name === 'string' && workflowData?.name !== '') {
      finalNameStr = workflowData.name;
    } else {
      const name = workflowData.name as TTranslationsData;
      finalNameStr = name.en;
    }
  }

  return finalNameStr;
};

const WF_NO_NAME = 'Untitled';
export const parseFetchedWorkflowsData = (fetchedWorkflows): TWorkflowData_UPmenu[] => {
  const parsedWorkflowsArr = fetchedWorkflows.map((wfData: TWorkflowDataFetched_UPmenu) => {
    let parsedName = getDisplayNameFrom(wfData);

    // if no parsed name ('' empty string)
    if (parsedName === '') {
      return {
        ...wfData,
        idName: wfData.name,
        name: {
          en: WF_NO_NAME,
          es: WF_NO_NAME,
          ko: WF_NO_NAME,
        } as TTranslationsData,
      };
    }

    return {
      ...wfData,
      idName: wfData.name,
      name: {
        en: parsedName,
        es: parsedName,
        ko: parsedName,
      } as TTranslationsData,
    };
  });

  const orderedParsedWorkflowArr = reorderWorkflows(parsedWorkflowsArr);
  const calculatorsReorderedWfs = reorderCalculators(orderedParsedWorkflowArr);
  return calculatorsReorderedWfs;
};

export const removeQuotesAndSpaces = (inputString) => {
  // Remove quotes (single and double) and empty spaces
  return inputString.replace(/['"\s]/g, '');
};

// filter cb fn
export const notNull = (el) => el !== null;

export const _customDebounce = (fn, wait) => {
  let tm;

  return (e) => {
    const later = () => {
      clearTimeout(tm);
      fn(e);
    };

    clearTimeout(tm);
    tm = setTimeout(later, wait);
  };
};
