import { DoubleTick, Tick } from '@assets/Icons';

import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import tStyles from '@styles/Typography.module.css';
import { useMessageSingleContext } from '@contexts/message';

type TMessageTimeProps = {
  options?: Partial<Intl.DateTimeFormatOptions>;
};

const MessageTime = ({ options = { hour: '2-digit', minute: '2-digit' } }: TMessageTimeProps) => {
  const { i18n } = useTranslation();
  const { message, messageCreatedAt, messageNotifications, messageSentByCurrentUser } = useMessageSingleContext();

  const machineTime = useMemo(() => {
    return new Date(messageCreatedAt)?.toISOString() || '';
  }, [messageCreatedAt]);

  const visibleTime = useMemo(() => {
    return new Date(messageCreatedAt).toLocaleTimeString(i18n.language, options) || '';
  }, [messageCreatedAt, options, i18n.language]);

  const showDoubleTick = useMemo(() => {
    return Boolean(messageNotifications?.findIndex((x) => x?.status === 'read') !== -1);
  }, [messageNotifications]);

  return (
    message && (
      <time
        dateTime={machineTime}
        className={[tStyles.base, tStyles.fsSmallest, tStyles.dFlex, ...(!options.month ? [tStyles.mlAuto] : [])].join(
          ' '
        )}
      >
        {visibleTime} {messageSentByCurrentUser ? <>{showDoubleTick ? <DoubleTick /> : <Tick />}</> : <></>}
      </time>
    )
  );
};

export default MessageTime;
