import { Chip, Divider, IconButton, Paper, Stack, Typography, useTheme, MorganTheme } from '@mui/material';
import React, { Dispatch, SetStateAction, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Styles from '@styles/ThemeShift.module.css';

import ScrollHandler from './ScrollHandler';
import ConversationMessage from './ConversationMessage';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import loadMessages from '@common/loadMessages';
import { useTranslation } from 'react-i18next';
import axiosCall from '@services/axios';
import { useSocketContext } from '@contexts/socket';
import { datesAreOnSameDay, shouldCurrUserReplyTo } from '@common/userMessagesUtils';
import { IErrand, IMessage, IUserData, IUserChatAction } from '@interfaces/Conversation';
import { useRootContext } from '@contexts/RootContext';
import { useErrandContext } from '@contexts/ErrandContext';
import MessagesSkeletonLoader from './MessagesSkeletonLoader';
import MessagesSkeletonLoaderSmall from './MessagesSkeletonLoaderSmall';
import { getCurrentParticipant, getPrimaryParticipant, getMessageUserAction } from '@common/errandUtils';
import { FormBodySizeType } from '../Forms/commonForms';
import { ChatType } from '@common/ChatType';
import { getUserPlayedLast24Hours } from '@storage/userStorage';
import { MorphType } from '@common/MorphType';
import { MessageContext } from '@contexts/MessageContext';
import { AccessType } from '@common/AccessType';
import { useUserContext } from '@contexts/user';
import { useParticipantContext } from '@contexts/participant';
import AnalyzingAngel from './AnalyzingAngel';
import { ResetFooterUserAction, GetMessageIndexOnMessagesArray } from '@common/common';
import {
  MessageFetchStateManager,
  MessageFetchDefaultState,
  MessageFetchEvent,
  MessageFetchRequestCallback,
  MessageFetchCountLoaded,
  MessageFetchParamType,
  MessageFetchHasMore,
  MessageFetchState,
  MessageFetchAdd,
  ScrollStateType,
  ScrollDirectionType,
  ScrollDefaultState,
  ResetScrollState,
} from './MessageFetchStateManager';
import { ValidatorFunctions } from '@common/Validators';
import { getAudienceArr, compareAudience } from '@common/msgUtils';
import { MessageSingleProvider } from '@contexts/message';

/* Limit of how many messages to fetch, convention dictates that top level consts are UPPER_CASE */
export const LIMIT = 15; // encountered issues when we tried to use the env variable it was saying NaN.

export type TConversationBodyProps = {
  dispFilterMode: string;
  editMessageId: string;
  errand: IErrand;
  isPrivate?: boolean;
  isTyping?: string[]; // private chat only
  operatorData?: IUserData;
  setEditMessageId: Dispatch<SetStateAction<string>>;
  setIsTyping?: Dispatch<SetStateAction<string[]>>; // private chat only
  setPreviewUrl: Dispatch<SetStateAction<string>>;
  setValue: Dispatch<SetStateAction<string>>;
  showBouncyRope?: boolean;
  showSentiment: boolean;
};

const getTrueIndex = (messageId, isPrivate, errand) => {
  if (isPrivate) {
    return errand?.privateMessages?.findIndex((msgData) => msgData?._id === messageId);
  }
  return errand?.messages?.findIndex((msgData) => msgData?._id === messageId);
};

const ConversationBody = memo(({
  dispFilterMode, editMessageId, errand, isPrivate, operatorData, setEditMessageId, setPreviewUrl, setValue, showBouncyRope, showSentiment, 
}: TConversationBodyProps) => {
  const rootContext = useRootContext();
  const errandContext = useErrandContext();
  const { messagesSocket, isMessagesConnected } = useSocketContext();
  const theme: MorganTheme = useTheme();
  const { t, i18n } = useTranslation();
  const { _id, isOperator } = useUserContext();
  const { activeAIOperatorsExcludingAngelAI } = useParticipantContext();
  const [isDisclaimerSeparate, setIsDisclaimerSeparate] = useState(false);
  const [skeletonLoaderVisible, setSkeletonLoaderVisible] = useState(false);
  const scrollBoxContainerRef = useRef(null);
  const bodyRef = useRef(null);
  const scrollStateRef = useRef<ScrollStateType>({...ScrollDefaultState});
  const messageFetchDataRef = useRef<MessageFetchParamType>({...MessageFetchDefaultState});

  // for disclaimer message height proper value
  const [disclaimerHeight, setDisclaimerHeight] = useState(0);

  // This will be updated for each iteration of messages
  let previousDate = null;

  /**
   * Conversation Body hide condition, we can hide/show conversation body with this logic
   * @returns true/false
   * true - hide Conversation Body
   * false - show Conversation Body
   */
  const conversationBodyHideCondition = useMemo((): boolean => {
    if (errand.type === ChatType.Form && errandContext.formBodySize !== FormBodySizeType.Small) {
      return true;
    }
    return false;
  }, [errand.type, errandContext.formBodySize]);

  const showAnalyzingAngel = useMemo(() => {
    if (ValidatorFunctions.isNotEmptyArray(activeAIOperatorsExcludingAngelAI)) {
      return false;
    };

    return (errandContext.isMorganTyping || errandContext.isAnalyzing) && !isOperator;
  }, [errandContext.isAnalyzing, errandContext.isMorganTyping, isOperator, activeAIOperatorsExcludingAngelAI]);

  const filteredMessages = useMemo(() => {
    const middleButtonIndex = errand.messages?.findIndex(msg => msg?.middleButton === true);
    const filters = (msg: IMessage, index: number, array: IMessage[]) => msg !== undefined && 
      ( dispFilterMode.toUpperCase() === 'NONE' || msg?.messageType === dispFilterMode ) &&
      ( errandContext.messageFilter.length === 0 || msg?.searchWords === errandContext.messageFilter || 
        // So the unblur button and new messages still render when errandContext.messageFilter is set and the convo history is blurred
        ( middleButtonIndex >= 0 && index >= middleButtonIndex )
      ) &&
      array.findIndex((m: IMessage) => m?._id === msg?._id) === index;
    if (isPrivate) {
      // useMemo prevents isDisclaimerSeparate useEffect from running every render
      if (!errand.privateMessages || errand.privateMessages?.length === 0) {
        // guard statement to prevent issues with filter
        return [];
      }
      return errand.privateMessages.filter(filters);
    }
    // useMemo prevents isDisclaimerSeparate useEffect from running every render
    if (!errand.messages || errand.messages?.length === 0) {
      // guard statement to prevent issues with filter
      return [];
    }
    return errand.messages.filter(filters);
  }, [isPrivate, errand.messages, errand.privateMessages, dispFilterMode, errandContext.messageFilter]);

  const participant = useMemo(() => {
    return getCurrentParticipant(errand, _id);
  }, [errand, _id]);

  const messageHistoryAllowedBoolean: boolean = useMemo(()=> {
    const result = participant?.messageHistoryAllowed;

    if (typeof result === 'boolean') {
      return result;
    }

    // default value
    return true;
  }, [participant?.messageHistoryAllowed]);

  const TryToHideSkeletionLoader = useCallback(() => {
    if (messageFetchDataRef.current.state === MessageFetchState.NothingToFetch) {
      setSkeletonLoaderVisible(false);
    }
  }, []);

  const loadMoreMessages = useCallback(
    async (abortController) => {
      try {
        const data = messageFetchDataRef.current;

        const nextAction = MessageFetchStateManager(data, MessageFetchEvent.RequestMore);

        if (MessageFetchHasMore(nextAction) === false) {
          return;
        }

        errandContext.setIsMorphedFooterCloseButtonOnLeft(false);

        errandContext.setMorphType((prev) => {
          if (prev === MorphType.MessageOptions) {
            return MorphType.None;
          }
          return prev;
        });

        errandContext.setMessageOptionsIndex(-1);

        // Lock the loading and update offset
        rootContext.setMessagesAreLoading(true);
        setSkeletonLoaderVisible(true);

        // how many messages currently loaded to UI
        const offset = MessageFetchCountLoaded(data);

        // Get the next chunk
        const fetchedMessages: IMessage[] = await loadMessages(
          {
            url:
              `chat/${errand?._id}/message` +
              `${errandContext.messageFilter ? '/search' : ''}` +
              `?order=desc&orderBy=createdAt&offset=${offset}` +
              `&limit=${data.limit}&batchOrder=asc` +
              `&internal=${isOperator ? true : false}` +
              `${
                isPrivate && errand.recipients?.length > 0
                  ? `&intendedAudience=${errand.recipients.sort().join(',')}`
                  : ''
              }`,
            method: errandContext.messageFilter ? 'post' : 'get',
            data: { keywords: errandContext.messageFilter ? [errandContext.messageFilter] : null },
          },
          _id,
          errand?._id,
          errand?.type,
          isOperator || isPrivate || participant?.messageHistoryAllowed,
          isOperator,
          undefined, // messageIn
          abortController
        );

        const more = MessageFetchRequestCallback(data, fetchedMessages);

        TryToHideSkeletionLoader();

        if (errandContext.messageFilter) {
          more.reverse();
        }

        if (!more) return;

        // If there are no more messages then we don't need to update state
        if (more?.length > 0) {
          rootContext.setErrands((prev) => {
            const chatObj = prev.find((e) => e._id === errand?._id);

            if (chatObj) {
              if (isPrivate) {
                chatObj.privateMessages = [...more, ...chatObj.privateMessages];
              } else {
                chatObj.messages = [...more, ...chatObj.messages];
              }

              const messages = isPrivate ? chatObj.privateMessages : chatObj.messages;
              chatObj.lastMessageData = messages[messages.length - 1];
            }

            // position of this set is really important for render cycle!
            rootContext.setMessagesAreLoading(false);

            return [...prev];
          });
        }
      } catch (err) {
        // trigger the skeleton loader to disappear
        rootContext.setMessagesAreLoading(false);

        TryToHideSkeletionLoader();

        if (!abortController?.signal?.aborted) {
          console.error(err);
        }
      }
    },
    [
      TryToHideSkeletionLoader,
      _id,
      errand?._id,
      errand.recipients,
      errandContext,
      rootContext,
      errand?.type,
      isOperator,
      isPrivate,
      participant,
    ]
  );

  const handleClickRequestHistory = useCallback(async () => {
    const abortController = new AbortController();

    // const primary = errand.participants?.find((x) => x?.primary === true)?.userData?._id;
    const primary = getPrimaryParticipant(errand)?.userData?._id;
    let wf, id;

    if (participant?.primary) {
      // The user is primary so we need to send workflows for primary
      if (participant.userData?.userId?.length > 0 || participant.userData?.webUserId?.length > 0) {
        // The user has authenticated through SunSoft before so we should use the authenticate workflow
        wf = await axiosCall({
          url: `workflow/db/search?active=true&fields=_id,active`,
          method: 'post',
          data: { search: 'Unblur History' },
        }, { signal: abortController?.signal });
        id = wf.filter((w) => w.active)[0]?._id;
        if (id?.length === 24) {
          await axiosCall({
            url: `chat/${errand?._id}/workflow/${id}`,
            method: 'POST',
            data: {
              userId: _id,
              userType: 'User',
              owner: primary,
            },
          }, { signal: abortController?.signal });
        }
      } else {
        // This user has not authenthenicated through SunSoft yet so we should use the OTP unblur workflow
        wf = await axiosCall({
          url: `workflow/db/search?active=true&fields=_id,name,active`,
          method: 'post',
          data: { search: 'Allow History' },
        }, { signal: abortController?.signal });
        id = wf.filter((w) => w.active && w.name === 'Allow History')[0]?._id;
        await axiosCall({
          url: `chat/${errand?._id}/workflow/${id}`,
          method: 'POST',
          data: {
            userId: _id,
            userType: 'User',
            owner: primary,
          },
        }, { signal: abortController?.signal });
      }
    } else {
      wf = await axiosCall({
        url: `workflow/db/search?active=true&fields=_id,name,active`,
        method: 'post',
        data: { search: 'Approve Unblur History' }
      }, { signal: abortController?.signal });
      id = wf.filter((w) => w.active && w.name === 'Approve Unblur History')[0]?._id;
      await axiosCall({
        url: `chat/${errand?._id}/workflow/${id}`,
        method: 'POST',
        data: {
          owner: primary,
          userId: _id,
          userType: (isOperator ? 'Operator' : 'User'),
          intendedAudience: [primary],
        }
      }, { signal: abortController?.signal });
    }
    console.log('request history sent', wf);
  }, [_id, errand, isOperator, participant]);

  /**
   * Sets the current scroll position to the bottom of the container without any conditions.
   *
   * @note As of 2024/11/01: Implementing smooth scrolling is currently challenging due to
   * race conditions that affect scroll position. Directly overwriting the scroll position
   * interrupts the smooth scroll mechanism, causing conflicts when the scroll position
   * is changing.
   *
   * @returns {void}
   */
  const moveScrollToBottom = useCallback(() => {
    const parameters = bodyRef.current;

    if (ValidatorFunctions.isUndefinedOrNull(parameters)) {
      return;
    }

    const scrollHeight = parameters.scrollHeight;
    const clientHeight = parameters.clientHeight;
    const scrollState = scrollStateRef.current;

    // Update cache parameters
    scrollState.lockedToBottom = true;
    scrollState.direction = ScrollDirectionType.Down;

    // Set scroll position to the bottom
    parameters.scrollTop = scrollHeight - clientHeight;
  }, []);

  const setUpNewChat = useCallback(async (controller) => {
    const BlurMessages = (loadedMesages) => {
      // guard statement to prevent unneccesary processing
      const currentParticipant = participant;
  
      if (
        messageHistoryAllowedBoolean ||
        currentParticipant === undefined ||
        isPrivate ||
        operatorData !== undefined ||
        !Array.isArray(errand.participants) ||
        errand.participants.length === 0 ||
        !Array.isArray(loadedMesages) ||
        loadedMesages.length === 0
      ) {
        return loadedMesages;
      }
  
      const messageArr = [];
      const middleButton = {
        middleButton: true,
        timestamp: Date.now(),
        visible: currentParticipant?.messageHistoryAllowed || false,
      };
  
      for (const message of loadedMesages) {
        messageArr.push({ ...message, visible: currentParticipant?.messageHistoryAllowed || false });
      }
  
      // added middleButton
      messageArr.push(middleButton);
  
      return messageArr;
    };

    try {
      const data = messageFetchDataRef.current;

      const nextAction = MessageFetchStateManager(data, MessageFetchEvent.RequestRestart);

      if (MessageFetchHasMore(nextAction) === false) {
        return;
      }

      ResetScrollState(scrollStateRef.current);
      setSkeletonLoaderVisible(true);
      rootContext.setMessagesAreLoading(true);

      let loadedMessages: IMessage[] = await loadMessages(
        {
          url: 
            `chat/${errand._id}/message${errandContext.messageFilter ? '/search' : ''}` + 
            `?order=desc&orderBy=createdAt&limit=${data.limit}&batchOrder=asc` +
            `&internal=${isOperator ? true : false}` +
            `${isPrivate && errand.recipients?.length > 0 
              ? `&intendedAudience=${errand.recipients.sort().join(',')}`
              : ''}`,
          method: errandContext.messageFilter ? 'post' : 'get',
          data: { keywords: errandContext.messageFilter ? [errandContext.messageFilter] : null },
        },
        _id,
        errand._id,
        errand.type,
        isOperator || isPrivate || participant?.messageHistoryAllowed,
        isOperator,
        undefined,
        controller
      );

      // Do not check for duplicates here.
      // 2024/11/05 - Note: When initial messages are fetched and the message socket event fires simultaneously, 
      // a race condition occurs. To avoid this, we should bypass duplicate checking, so results from 
      // MessageFetchRequestCallback are ignored.
      MessageFetchRequestCallback(data, loadedMessages);

      TryToHideSkeletionLoader();

      if (errandContext.messageFilter) {
        loadedMessages.reverse();
      }

      if (!loadedMessages) return;

      // Check to see if messages has content and loadedMessages is empty, if so implies race condition 
      if ((isPrivate ? errand?.privateMessages?.length > 0 : errand?.messages?.length > 0) && (loadedMessages?.length === 0 || loadedMessages === undefined || loadedMessages === null)) {
        // Dont do anything to messages 
        console.warn('A race condition between the chat-message-update socket event and the loadMessages call was detected. Using the previously stored data.');
      } else {
        rootContext.setErrands((prev) => {
          // blur if needed
          loadedMessages = BlurMessages(loadedMessages);

          const chatObj = prev.find((e) => e._id === errand._id);

          if (chatObj) {
            if (isPrivate) {
              chatObj.privateMessages = loadedMessages;
            } else {
              chatObj.messages = loadedMessages;
            }

            chatObj.lastMessageData = loadedMessages[loadedMessages.length - 1];
          }

          // position of this set is really important for render cycle!
          rootContext.setMessagesAreLoading(false);

          return [...prev];
        });
      }

      let offset = isOperator || participant?.messageHistoryAllowed ? 1 : 2;
      let messagesWithoutNotifications = loadedMessages?.filter(m => m?.messageType !== 'Notification');
      let lastMessage = messagesWithoutNotifications?.[messagesWithoutNotifications?.length - offset];
      // Check for message history allowed to not to change the footer of such user that has last msg as action and is NOT allowed to view messageHistory.
      const messageHistoryAllowed = participant?.messageHistoryAllowed;
      // If the last loaded message was an action type && it is intended for current user, then we should automatically set the footer
      if (!operatorData 
          && loadedMessages?.length > 0 
          && lastMessage?.messageType === 'Action' 
          && lastMessage?.action?.description !== 'TCPA' 
          && (shouldCurrUserReplyTo(lastMessage, _id) === true)
          // This will prevent the user from replying to the last
          // action IF user is not allowed to see msgs history. 
          // (thus prevent replying to that action)
          && messageHistoryAllowed === true) {
        if (lastMessage?.action?.chatTypeInitiator === ChatType.Form 
          || lastMessage?.action?.chatTypeInitiator === ChatType.Activity
          || lastMessage?.action?.chatTypeInitiator === ChatType.Page) {return;}
        else {
          rootContext.setErrands((prev) => {
            const chatObj = prev.find((e) => e._id === errand._id);

            if (chatObj) {
              // If the last message is a Slot Machine actions and the user has played, we ignore the last message action
              if (lastMessage?.action?.description === 'Slot Machine' && getUserPlayedLast24Hours()) {
                ResetFooterUserAction(chatObj);
              } else if (
                (isPrivate && lastMessage?.accessType === AccessType.private) ||
                (!isPrivate && lastMessage?.accessType !== AccessType.private)
              ) {
                chatObj.icon = lastMessage?.icon;
                chatObj.placeholder = lastMessage?.action?.description;
                chatObj.action = {
                  ...lastMessage?.userAction,
                  action: lastMessage?.action,
                  userActionId: lastMessage?.userAction?._id,
                  active: true,
                };
                chatObj.recipients = lastMessage.intendedAudience
                  ? [lastMessage.sender._id, ...lastMessage.intendedAudience].sort()
                  : [];
              }
            }

            // position of this set is really important for render cycle!
            rootContext.setMessagesAreLoading(false);

            return [...prev];
          });
        }
      }
    }
    catch (err) {
      rootContext.setMessagesAreLoading(false);
      TryToHideSkeletionLoader()

      if (controller.signal.aborted) {
        console.log('The user aborted the request');
      } else {
        console.error('The request failed:', err);
      }
    }
  }, [
    _id,
    errand._id,
    errand.messages?.length,
    errand.participants,
    errand.privateMessages?.length,
    errand.recipients,
    errand.type,
    errandContext.messageFilter,
    isOperator,
    operatorData,
    isPrivate,
    messageHistoryAllowedBoolean,
    participant,
    rootContext,
    TryToHideSkeletionLoader
  ]);

  useEffect(() => {
    if (!errand?._id || !isMessagesConnected) return;

    const abortController = new AbortController();

    setUpNewChat(abortController);

    return () => {
      abortController?.abort();

      // clear messages to prevent the errands object from growing too large.
      rootContext.setErrands((prev) => {
        const chatObj = prev.find((e) => e._id === errand?._id);

        if (chatObj) {
          if (isPrivate) {
            chatObj.privateMessages = [];
          } else {
            chatObj.messages = [];
          }
        }

        // noneed to re-render
        return prev;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errand._id, isMessagesConnected, rootContext.viewReload, messageHistoryAllowedBoolean, errandContext.messageFilter, i18n.language]);

  useEffect(() => {
    if (!isMessagesConnected) return;

    const onInternalMesageUpdate = (payload) => {
      console.log(`Messages Socket - ConversationBody - (internal-message-update)`, payload);
      rootContext.setErrands((prev) => {
        // logic changed as find returns null rather than undefined and this keeps the logic congruent with other states.
        const chatObj = prev.find((e) => e._id === payload.data.chat);

        if (chatObj) {
          const messageIndex = (chatObj.messages || []).findIndex((m) => m._id === payload.data._id);
          if (messageIndex) {
            console.error(`internal-message-update at messageIndex ${messageIndex}`);
            if (isOperator) {
              chatObj.messages[messageIndex].accessType = AccessType.internal;
              return [...prev];
            }
            // const messages = chatObj.messages;
            chatObj.messages = (chatObj.messages || []).filter((m) => m._id !== payload.data._id);
            return [...prev];
          }
          const privateMessageIndex = (chatObj.privateMessages || []).findIndex((m) => m._id === payload.data._id);
          if (privateMessageIndex !== -1) {
            console.error(`internal-message-update at privateMessageIndex ${privateMessageIndex}`);
            if (isOperator) {
              chatObj.privateMessages[privateMessageIndex].accessType = AccessType.internal;
              return [...prev];
            }
            chatObj.privateMessages = (chatObj.privateMessages || []).filter((m) => m._id !== payload.data._id);
            return [...prev];
          }
        }

        console.error(`internal-message-update index not found`);
        return prev;
      });
    };

    // Register to receive real time read status on this chat
    const onMessageStatusUpdate = (payload) => {
      console.log(`Messages Socket - ConversationBody - (message-status-update)`, payload);
      rootContext.setErrands((prev) => {
        // logic changed as find returns null rather than undefined and this keeps the logic congruent with other states.
        const chatObj = prev.find((e) => e._id === payload.data.chat);

        if (chatObj) {
          const messages = isPrivate ? chatObj.privateMessages : chatObj.messages;

          if (messages) {
            const messageObj = messages.find((m) => m._id === payload.data.messageId);

            if (messageObj) {
              // update notifications for specified message
              messageObj.notifications = [payload.data];
            }
          }
        }

        return [...prev];
      });
    };

    const onUserNameUpdate = (payload) => {
      console.log(`Messages Socket - ConversationBody - (user-name-update)`, payload);

      const data = payload?.data;
      const userId = data?._id;
      const chatId = data?.chat;

      rootContext.setErrands((prev) => {
        const chatObj = prev.find((e) => e._id === errand?._id);

        if (chatObj) {
          if (chatObj._id === chatId) {
            const messages = isPrivate ? chatObj.privateMessages : chatObj.messages;

            // for each message update the name if needed
            for (const msg of messages || []) {
              if (msg?.sender?._id === userId) {
                msg.sender = { ...msg.sender, ...data };
              }
            }
          }
        }

        return [...prev];
      });
    };

    const onChatMessageUpdate = async (payload) => {
      if (!errand || !errand._id || !errand.type) return;
      if (!payload || !payload.data || !payload.data.accessType) return;
      // don't process regulard messages in private chat
      if (isPrivate && payload.data.accessType !== AccessType.private) return;
      if (payload.data.chat !== errand._id) return;

      console.log(`Messages Socket - ConversationBody - (chat-message-update)`, payload);

      const message: IMessage = await loadMessages(
        null,
        _id,
        errand._id,
        errand.type,
        true,
        isOperator,
        payload?.data
      );

      const data = messageFetchDataRef.current;

      MessageFetchAdd(data, message);

      const messageId = message?._id;
      const intendedAudience = message?.intendedAudience;
      const operatorView = message?.operatorView;
      const notifications = message?.notifications;
      const accessType = message?.accessType;
      const sentByCurrentUser = message?.sentByCurrentUser;
      const icon = message?.icon;
      const action = message?.action;
      const senderId = message?.sender?._id;
      const senderType = message?.senderType;

      const description = action?.description;

      const userAction: IUserChatAction = await getMessageUserAction(message, isPrivate);

      const HandleCommonPostfix = (chatObj) => {
        const shouldReply = shouldCurrUserReplyTo(message, _id);

        if (userAction && !operatorView && shouldReply) {
          chatObj.icon = icon;
          chatObj.placeholder = description;
          chatObj.action = userAction;

          const participanObj = chatObj.participants.find((p) => p.userData?._id === senderId);
          if (ValidatorFunctions.isNotUndefinedNorNull(participanObj)) {
            participanObj.userActions = [userAction];
          }

          chatObj.action.userActionId = userAction._id;
          chatObj.action.active = true;
        }

        chatObj.lastMessageData = message;

        // if sender is current user then scroll to bottom
        if (sentByCurrentUser) {
          moveScrollToBottom();
        }
      };

      const HandlePrivateMessageOnPrivateChat = () => {
        rootContext.setErrands((prev) => {
          const chatObj = prev.find((e) => e._id === errand._id);

          if (ValidatorFunctions.isUndefinedOrNull(chatObj)) return prev;

          const messagesArray = chatObj.privateMessages || [];

          // Save copy of messages state before inserting new message
          const messageIndex = messagesArray.findIndex((m) => m._id === messageId);

          // validate
          const audienceString = getAudienceArr(null, senderId, senderType, intendedAudience);

          if (compareAudience(audienceString, chatObj.recipients) === false) {
            return prev;
          }

          chatObj.previewAllowed =
            isOperator || chatObj.participants.find((p) => p.userData?._id === senderId)?.messageHistoryAllowed;

          const mergedNotifications = messagesArray[messageIndex]?.notifications || notifications || [];

          if (messageIndex === -1) {
            // inject message at specific index based on createdAt field
            const index = GetMessageIndexOnMessagesArray(messagesArray, message);

            messagesArray.splice(index, 0, message);
          } else {
            messagesArray[messageIndex] = {
              ...messagesArray[messageIndex],
              ...message,
              notifications: [...mergedNotifications],
            };
          }

          chatObj.privateMessages = [...messagesArray];

          HandleCommonPostfix(chatObj);

          // Deep clone single errand
          return prev.map((x) => {
            if (x === chatObj) {
              return JSON.parse(JSON.stringify(x));
            } else {
              return x;
            }
          });
        });
      };

      const HandlePrivateMessageOnPublicChat = () => {
        rootContext.setErrands((prev) => {
          const chatObj = prev.find((e) => e._id === errand._id);

          if (ValidatorFunctions.isUndefinedOrNull(chatObj)) return prev;

          let messagesArray = chatObj.messages || [];

          // Save copy of messages state before inserting new message
          const messageIndex = messagesArray.findIndex((m) => m._id === messageId);

          // validate
          const audienceString = getAudienceArr(null, senderId, senderType, intendedAudience);

          if (messageIndex === -1) {
            // slice out previous private message preview from this chat
            messagesArray = messagesArray.filter((x) => {
              if (x?.accessType === AccessType.private) {
                const aud = getAudienceArr(null, x?.sender?._id, x?.senderType, x?.intendedAudience);

                if (compareAudience(audienceString, aud)) {
                  return false;
                }
              }

              return true;
            });
          }

          chatObj.previewAllowed =
            isOperator || chatObj.participants.find((p) => p.userData?._id === senderId)?.messageHistoryAllowed;

          const mergedNotifications = messagesArray[messageIndex]?.notifications || notifications || [];

          if (messageIndex === -1) {
            if (sentByCurrentUser === false) {
              // inject message at specific index based on createdAt field
              const index = GetMessageIndexOnMessagesArray(messagesArray, message);

              messagesArray.splice(index, 0, message);
            }
          } else {
            messagesArray[messageIndex] = {
              ...messagesArray[messageIndex],
              ...message,
              notifications: [...mergedNotifications],
            };
          }

          chatObj.messages = [...messagesArray];

          HandleCommonPostfix(chatObj);

          // Deep clone single errand
          return prev.map((x) => {
            if (x === chatObj) {
              return JSON.parse(JSON.stringify(x));
            } else {
              return x;
            }
          });
        });
      };

      const HandlePublicMessageOnPublicChat = () => {
        rootContext.setErrands((prev) => {
          const chatObj = prev.find((e) => e._id === errand._id);

          if (ValidatorFunctions.isUndefinedOrNull(chatObj)) return prev;

          const messagesArray = chatObj.messages || [];

          // Save copy of messages state before inserting new message
          const messageIndex = messagesArray.findIndex((m) => m._id === messageId);

          chatObj.previewAllowed =
            isOperator || chatObj.participants.find((p) => p.userData?._id === senderId)?.messageHistoryAllowed;

          const mergedNotifications = messagesArray[messageIndex]?.notifications || notifications || [];

          if (messageIndex === -1) {
            // inject message at specific index based on createdAt field
            const index = GetMessageIndexOnMessagesArray(messagesArray, message);

            messagesArray.splice(index, 0, message);
          } else {
            messagesArray[messageIndex] = {
              ...messagesArray[messageIndex],
              ...message,
              notifications: [...mergedNotifications],
            };
          }

          chatObj.messages = [...messagesArray];

          HandleCommonPostfix(chatObj);

          // Deep clone single errand
          return prev.map((x) => {
            if (x === chatObj) {
              return JSON.parse(JSON.stringify(x));
            } else {
              return x;
            }
          });
        });
      };

      if (accessType === AccessType.private) {
        if (isPrivate) {
          HandlePrivateMessageOnPrivateChat();
        } else {
          HandlePrivateMessageOnPublicChat();
        }
      } else if (isPrivate === false) {
        HandlePublicMessageOnPublicChat();
      }
    };

    console.log('Messages Socket - ConversationBody - (on)');
    messagesSocket.current?.on('internal-message-update', onInternalMesageUpdate);
    messagesSocket.current?.on('message-status-update', onMessageStatusUpdate);
    messagesSocket.current?.on('user-name-update', onUserNameUpdate);
    messagesSocket.current?.on('chat-message-update', onChatMessageUpdate);
    return () => {
      console.log('Messages Socket - ConversationBody - (off)');
      messagesSocket.current?.off('internal-message-update', onInternalMesageUpdate);
      messagesSocket.current?.off('message-status-update', onMessageStatusUpdate);
      messagesSocket.current?.off('user-name-update', onUserNameUpdate);
      messagesSocket.current?.off('chat-message-update', onChatMessageUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, errand._id]);

  /* End the edit message process when clicking anywhere else on the screen */
  useEffect(() => {
    if (editMessageId) {
      const onClick = (event) => {
        if (!errandContext?.footerRef?.current?.contains(event.target) && errandContext?.boundaryRef?.current?.contains(event.target)) {
          errandContext.setMorphType(MorphType.None);
          setEditMessageId('');
          setValue('');
          // changes: icon, placeholder and action.
          rootContext.setErrands((prev) => {
            const chatObj = prev.find((e) => e._id === errand?._id);

            if (chatObj) {
              // Reset the footer input state in currErrand state obj by found index.
              ResetFooterUserAction(chatObj);
            }

            // return copy.
            return [...prev];
          });
        }
      };

      document.addEventListener('mousedown', onClick);
      return () => {
        document.removeEventListener('mousedown', onClick);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMessageId]);

  return (
    <MessageContext.Provider
      value={{
        bodyRef,
        isPrivate: isPrivate,
        setPreviewUrl: setPreviewUrl,
        isDisclaimerSeparate,
        editMessageId,
        setDisclaimerHeight,
        disclaimerHeight,
        scrollStateRef,
        loadMoreMessages,
        messageFetchDataRef,
        moveScrollToBottom,
      }}
    >
      <Stack
        className={isPrivate ? Styles.isPrivate : ''}
        ref={scrollBoxContainerRef}
        sx={{
          display: conversationBodyHideCondition ? 'none' : 'flex',
          position: 'relative',
          height: '100%',
          width: '100%',
          overscrollBehavior: 'none',
        }}
      >
        {filteredMessages.length > 0 ? (
          <ScrollHandler action={errand.action} isPrivate={isPrivate}>
            <div className={Styles.infiniteScroll}>
              {skeletonLoaderVisible && <MessagesSkeletonLoaderSmall />}
              {filteredMessages?.map((message: IMessage, index, array) => {
                // 2024/10/07 - bold: Index mismatches if there are null _id messages, so we must do true index all the time
                index = getTrueIndex(message._id, isPrivate, errand);

                const hideFieldAttribute = message?.action?.fieldAttribute?.description === 'ENVELOPE' ||
                  (message?.action?.fieldAttribute?.description === 'MULTI SELECT NUMBER' && message?.messageType === 'Action');

                if (!message || message.accessType === AccessType.system || hideFieldAttribute) {
                  return <div key={index} />;
                }

                if (message.messageType === 'Errand' && message.operatorView) {
                  return <div key={index} />;
                }

                // ON DISCLAIMER MESSAGE return empty div if disclaimer needs to be shown separately else just render it.
                if (isDisclaimerSeparate && message.messageType === 'Disclaimer') {
                  return <div key={index} />;
                }

                // Check if the pevious date is the same as today
                const showDate =
                  !datesAreOnSameDay(previousDate, message?.createdAt) &&
                  !(index === 1 && array[0].messageType === 'Disclaimer');

                // update the previous  date to this message's createdAt date
                previousDate = message?.createdAt;

                if (!message?.middleButton) {
                  // Check to see if this message should be blurred. If any
                  // message is to be blurred, we should avoid rendering
                  // the searchbar until the entire converstaion history
                  // is allowed.

                  return (
                    <MessageSingleProvider
                      index={index}
                      showDate={showDate}
                      showSentiment={showSentiment}
                    >
                      <ConversationMessage key={message?._id} />
                    </MessageSingleProvider>
                  );
                } else {
                  return !message.visible && !isPrivate ? (
                    <Divider
                      key={index}
                      sx={{
                        '&::before, &::after': {
                          borderColor: theme.palette.orange['600'],
                          borderBottomWidth: 5,
                        },
                      }}
                    >
                      <IconButton onClick={handleClickRequestHistory}>
                        <Stack alignItems="center">
                          <QuestionAnswerIcon sx={{ color: theme.palette.orange['600'] }} fontSize="large" />
                          <Chip
                            label={participant?.primary ? t('requestUnblur') : t('requestUnblurPermission')}
                            sx={{
                              backgroundColor: theme.palette.orange['600'],
                              color: 'var(--gray000)',
                            }}
                          />
                        </Stack>
                      </IconButton>
                    </Divider>
                  ) : (
                    <span key={index}></span>
                  );
                }
              })}
              {showAnalyzingAngel && <AnalyzingAngel />}
            </div>
          </ScrollHandler>
        ) : (
          <Stack alignItems="center" justifyContent="center" style={{ height: '100%', overflow: 'hidden' }}>
            {rootContext.messagesAreLoading || !isMessagesConnected ? (
              <MessagesSkeletonLoader />
            ) : (
              <Paper sx={{ p: 1.5 }}>
                <Typography> {t('noMessages')} </Typography>
              </Paper>
            )}
          </Stack>
        )}
      </Stack>
    </MessageContext.Provider>
  );
});

export default ConversationBody;